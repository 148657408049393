import { GET } from "../../../../API/get";

const fetchData = async (URL) => {
    try {
        const response = await GET(URL);
        return response;
    } catch (error) {
        console.error("Error fetching data:", error);
        return null;
    }
}; // end fetchData

export const fetchFtmDocuments = async (page) => {
    const URL = `all/documents/expired-and-expiring/${page}`;
    const response = await fetchData(URL);

    return response.status === "Success" ? response : null;
};

export const fetchFtmCertificates = async (page) => {
    const URL = `all/certificates/expired-and-expiring/${page}`;
    const response = await fetchData(URL);

    return response.status === "Success" ? response : null;
};