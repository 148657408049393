import { useContext, useEffect } from "react";
import { Pagination, Stack } from "@mui/material";

import { theme } from "../../../theme";
import { Context } from "../provider";
import Search from "../../../components/searchField";
import DivSpinner from "../../../components/divSpinner";

export const JobsList = () => {
    const { state, getAllJobsStatusLogs, getJobsStatusLogs, dispatch } = useContext(Context);
    const { jobs_current_page, jobs_list_serach_value, is_loading_job_list, job_list, selected_job } = state;

    useEffect(() => {
        getAllJobsStatusLogs();
    }, [jobs_current_page, jobs_list_serach_value]); 

    return (
        <div className="card mb-4">
            <div className="card-header pb-0 p-3">
                <div className="d-flex justify-content-between">
                    <h5 className="mb-1 font-weight-bolder">Jobs list</h5>
                    <Search
                        onSearch={(search_text) => {
                            dispatch({
                                type: "SET_JOBS_LIST_SERACH_VALUE",
                                jobs_list_serach_value: search_text
                            });
                        }}
                        width={'200px'}
                    />
                </div>
            </div>
            <div className="card-body p-3 pb-0" style={{height: '400px', overflowY: 'auto'}}>
                {is_loading_job_list ? (
                    <div style={{height : `${window.innerHeight-611}px`}}>
                        <DivSpinner/>
                    </div>
                ) : (
                    <ul className="list-group">
                        {job_list?.jobs?.map((job, index) => (
                            <li 
                                className="list-group-item border-0 d-flex justify-content-between  mb-2 border-radius-lg" 
                                key={index}
                                style={{cursor: "pointer", backgroundColor: selected_job?.job_id === job.job_id ? '#e2e3e7' : null}}
                                onClick={() => getJobsStatusLogs(job.job_id)}
                            >
                                <div className="d-flex flex-column">
                                    <h6 className="mb-1 text-dark font-weight-bold text-md">{job.sub_skill.sub_skill_name}</h6>
                                    <span className="text-sm">{job.sub_skill.skill.skill_name}</span>
                                </div>
                                <div className="d-flex align-items-center text-sm">
                                    # {job.job_id} 
                                </div>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
            <div className="card-footer">
                {!is_loading_job_list && (
                    <Stack
                        spacing={2}
                        className="mb-3"
                        style={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        <Pagination
                            count={job_list?.total_pages}
                            hidePrevButton 
                            hideNextButton
                            page={jobs_current_page}
                            onChange={(event, newPage) => dispatch({
                                type: "UPDATE_JOBS_CURRENT_PAGE",
                                jobs_current_page: newPage
                            })}
                            color={theme.main}
                        />
                    </Stack>
                )}
            </div>
        </div>
    )
}