import React, { useContext } from "react";
import GradeIcon from '@mui/icons-material/Grade';
import { Card, CardContent, Typography, Divider, Box } from "@mui/material";

import { Context } from "../Provider";
import ListFileUpload from "./ListFileUpload";

const SkillsFileUpload = () => {
    const context = useContext(Context);
    const { state } = context;
    const { selectedSkills } = state;

    const RequiredIcons = () => {
        return <GradeIcon style={{marginBottom : '5px', color: 'red', fontSize: '15px'}}/>
    }

    return (
        <Box sx={{ height: '650px', overflowY: 'auto',  }}>
            {selectedSkills.map((skill, sIndex) => (
                <Card sx={{ mt: 2, p: 2, backgroundColor: '#fff' }} key={sIndex}>
                    <CardContent>
                        <Typography variant="h6" className="text-success" gutterBottom>
                            {skill.skill_name}
                        </Typography>
                        
                        {skill.documents_types.map((type, dtIndex) => (
                            <Box key={dtIndex} sx={{ mb: 2 }}>
                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                    {type.documents_types_name} 
                                    {type.documents_types_is_required === 1 && <RequiredIcons/>}
                                </Typography>
                                <ListFileUpload skill_id={skill.skill_id} documents_types_id={type.documents_types_id}/>
                            </Box>
                        ))}

                        {skill.subSkills.map((subSkill, ssIndex) => (
                            <Box key={ssIndex} sx={{ mt: 2 }}>
                                <Divider />
                                <Typography variant="subtitle1" className="text-warning" sx={{ mt: 1 }}>
                                    {subSkill.sub_skill_name}
                                </Typography>
                                
                                {subSkill.documents_types.map((type, sdtIndex) => (
                                    <Box key={sdtIndex} sx={{ mb: 1 }}>
                                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                            {type.documents_types_name}
                                            {type.documents_types_is_required === 1 && <RequiredIcons/>}
                                        </Typography>
                                        <ListFileUpload 
                                            skill_id={skill.skill_id} 
                                            documents_types_id={type.documents_types_id}
                                            sub_skill_id={subSkill.sub_skill_id}
                                        />
                                    </Box>
                                ))}
                            </Box>
                        ))}
                    </CardContent>
                </Card>
            ))}
        </Box>
    );
}

export default SkillsFileUpload;