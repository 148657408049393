import React, { createContext, useEffect, useReducer } from "react";
import Swal from 'sweetalert2';

import Reducer from "./reducer";
import { initialState } from "./initialState";
import { fetchDisputes, updateAll } from "./service";
import { isShowChatNotifications } from "../../utils";
import { getUserInfo } from "../../storage";
import { disputesRef } from "../../firebase";
import { theme } from '../../theme';
import { commonSwal } from "../../components/swalAlert/swalAlert";

const Context = createContext();

const Provider = ({ children }) => {
    const [state, dispatch] = useReducer(Reducer,initialState);

    useEffect(() => {
        const getDisputes = async () => {
            try {
                dispatch({type: 'IS_LOADING_DISPUTES'});
                const disputes = await fetchDisputes({
                    is_get_archive_dispute: state.is_get_archive_dispute,
                    disputes_list_search_text: state.disputes_list_search_text
                });
    
                console.log("🚀 ~ getDisputes ~ disputes:", disputes)
    
                dispatch({
                    type: 'IS_LOADED_DISPUTES',
                    disputes: disputes
                }); 
            } catch (error) {
                console.error('Error fetching disputes:', error);
            }
        };
    
        const unsubscribe = disputesRef.onSnapshot(() => {
            getDisputes();
        });
    
        return () => unsubscribe();
    }, [state.is_get_archive_dispute, state.disputes_list_search_text]);
    
    const handleChatLoading = (dispute) => {
        console.log("🚀 ~ handleChatLoading ~ dispute:", dispute)
        isShowChatNotifications(dispute?.dispute_job?.job_id, "NEW_DISPUTE_MESSAGE");
    
        // Get dispute info
        let user_info = getUserInfo();
        let ref = disputesRef.doc(dispute.doc_id);
    
        ref.update({
            dispute_last_message_seen_user: user_info.admin_firebase_document_id,
            dispute_last_message_seen_user_timestamp: new Date().getTime()
        });
    
        dispatch({ type: "SELECTED_DISPUTE", selected_dispute: dispute })
    }
    
    const handleDocumentArchive = (dispute) => {
        if (!dispute.dispute_archive) {
            commonSwal({
                type: 'HANDLE_DOCUMENT_ARCHIVE',
                showCancelButton: true,
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                    let ref = disputesRef.doc(dispute.doc_id);
    
                    ref.update({
                        dispute_archive: !dispute.dispute_archive
                    });
    
                    return ref;
                },
                allowOutsideClick: () => !Swal.isLoading()
            }).then(async (result) => {
                if (result.isConfirmed) {
                    console.log('result :', result);
                    dispute.dispute_archive = true;
                    dispatch({ type: "SELECTED_DISPUTE", selected_dispute: dispute })
                }
            })
        } else {
            let ref = disputesRef.doc(dispute.doc_id);
    
            ref.update({
                dispute_archive: !dispute.dispute_archive
            });

            dispute.dispute_archive = false;
            dispatch({ type: "SELECTED_DISPUTE", selected_dispute: dispute })
        }
    }
    
    const filteredDisputes = state.disputes.filter(dispute => {
        const disputeIdMatch = dispute.dispute_id && dispute.dispute_id.toString().toLowerCase().includes(state.disputes_list_search_text.toLowerCase());
        const subSkillNameMatch = dispute.dispute_details?.service_category?.sub_skill?.name?.toLowerCase().includes(state.disputes_list_search_text.toLowerCase());
        return disputeIdMatch || subSkillNameMatch;
    });
    
    return (
        <Context.Provider value={{state, filteredDisputes, dispatch, handleChatLoading, handleDocumentArchive}}>
            {children}
        </Context.Provider>
    );
    
}

export { Context, Provider };