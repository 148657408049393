import React, { useContext } from "react";
import { Context } from "../../Provider";

const OtherContactInformation = () => {
    const { state } = useContext(Context);
    const { selected_ftm } = state;

    const {
        ftm_next_of_kin = "N/A",
        ftm_next_of_kin_contact_number = "N/A",
    } = selected_ftm || {};

    const contactDetails = [
        { label: "Next of Kin", value: ftm_next_of_kin || "N/A" },
        { label: "Next of Kin Contact Number", value: ftm_next_of_kin_contact_number || "N/A" },
    ];

    return (
        <div className="col-lg-12">
            <div className="card h-100">
                <div className="card-header pb-0 p-3">
                    <div className="row">
                        <div className="col-12 d-flex align-items-center">
                            <h6 className="mb-0">OTHER CONTACT INFORMATION</h6>
                        </div>
                    </div>
                </div>
                <div className="card-body p-3 pb-0">
                    <ul className="list-group">
                        {contactDetails.map((item, index) => (
                            <li
                                key={index}
                                className="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
                            >
                                <div className="d-flex flex-column">
                                    <h6 className="mb-1 text-dark font-weight-bold text-sm">
                                        {item.label}
                                    </h6>
                                </div>
                                <div className="d-flex align-items-center text-sm">
                                    {item.value}
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default OtherContactInformation;