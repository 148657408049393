import React, { createContext, useEffect, useReducer } from "react";
import { useLocation } from 'react-router-dom';
import { initialState } from "./initialState";
import Reducer from "./reducer";
import { fetchData } from "./service";

const Context = createContext();

const Provider = ({ children }) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const type = searchParams.get('type');
    const text = searchParams.get('text');
    const column = searchParams.get('column');
    const option = searchParams.get('option');

    const [state, dispatch] = useReducer(Reducer,initialState);

    useEffect(() => {
        const getData = async () => {
            try{
                dispatch({ type: "IS_LOADING" });
                let response = await fetchData({
                    type: type,
                    text: text,
                    column: column,
                    option: option,
                    sort_key: state.sort_key,
                    sort_direction: state.sort_direction,
                });

                dispatch({ 
                    type: "IS_LOADED", 
                    jobs_list: response.list
                });
            }catch(error){
                console.log(error);
            }
        };

        getData();
    }, [state.current_page, state.search_value, state.sort_key, state.sort_direction]); 

    return (
        <Context.Provider value={{state, dispatch}}>
            {children}
        </Context.Provider>
    );
}

export { Context, Provider };