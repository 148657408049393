import React, { useContext, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Checkbox, FormControlLabel, Card } from '@mui/material';
import { Context } from '../Provider';
import { theme } from '../../../../theme';

const CheckboxesList = ({ submitRef, ftmSkills }) => {
    const context = useContext(Context);
    const { state, handelSelectedSkills } = context;
    const { skills } = state;

    const [checkedSkills, setCheckedSkills] = useState({});
    const { handleSubmit } = useForm();

    // Effect to initialize checkedSkills based on ftmSkills
    useEffect(() => {
        const initialCheckedSkills = {};
        ftmSkills.forEach(skill => {
            const skillId = skill.skill_id;
            initialCheckedSkills[skillId] = { checked: true, subSkills: {} };
            skill.subSkills.forEach(subSkill => {
                initialCheckedSkills[skillId].subSkills[subSkill.sub_skill_id] = true;
            });
        });
        setCheckedSkills(initialCheckedSkills);
    }, [ftmSkills]);

    // Function to handle skill checkbox change
    const handleSkillChange = (skillId, subSkills) => {
        const skillChecked = !checkedSkills[skillId]?.checked;
        setCheckedSkills((prev) => {
            const updatedSubSkills = subSkills.reduce((acc, subSkill) => {
                acc[subSkill.sub_skill_id] = skillChecked;
                return acc;
            }, {});
            return {
                ...prev,
                [skillId]: { checked: skillChecked, subSkills: updatedSubSkills }
            };
        });
    };

    // Function to handle sub-skill checkbox change
    const handleSubSkillChange = (skillId, subSkillId) => {
        setCheckedSkills((prev) => {
            const currentSubSkills = prev[skillId]?.subSkills || {};
            const updatedSubSkillChecked = !currentSubSkills[subSkillId];

            // Update the specific sub-skill's state
            const updatedSubSkills = {
                ...currentSubSkills,
                [subSkillId]: updatedSubSkillChecked
            };

            // Check if all, some, or none of the sub-skills are checked
            const subSkillValues = Object.values(updatedSubSkills);
            const allChecked = subSkillValues.every(Boolean);
            const noneChecked = subSkillValues.every((val) => !val);

            return {
                ...prev,
                [skillId]: {
                    checked: allChecked,
                    indeterminate: !allChecked && !noneChecked,
                    subSkills: updatedSubSkills
                }
            };
        });
    };

    // Function to handle form submission
    const onSubmit = () => {
        const selectedSkills = Object.keys(checkedSkills)
            .filter((skillId) => checkedSkills[skillId].checked || Object.values(checkedSkills[skillId].subSkills).some(Boolean)) // Check if skill or any sub-skill is checked
            .map((skillId) => {
                const skill = skills.find((s) => s.skill_id === parseInt(skillId));
                const subSkills = Object.keys(checkedSkills[skillId].subSkills || {})
                    .filter((subSkillId) => checkedSkills[skillId].subSkills[subSkillId]) // Only include checked sub-skills
                    .map((subSkillId) => {
                        const subSkill = skill.sub_skills.find((s) => s.sub_skill_id === parseInt(subSkillId));
                        return {
                            sub_skill_id: subSkill.sub_skill_id,
                            sub_skill_name: subSkill.sub_skill_name,
                            documents_types: subSkill?.documents_types
                        };
                    });
                
                return {
                    skill_id: skill.skill_id,
                    skill_name: skill.skill_name,
                    documents_types: skill?.documents_types,
                    subSkills
                };
            });
    
        console.log('Selected Skills:', selectedSkills);
        handelSelectedSkills(selectedSkills);
    };

    // const 
    

    const renderSubSkills = (skillId, subSkills) => (
        <Box sx={{ display: 'flex', flexDirection: 'column', ml: 4 }}>
            {subSkills.map((subSkill) => (
                <FormControlLabel
                    key={subSkill.sub_skill_id}
                    label={subSkill.sub_skill_name}
                    control={
                        <Checkbox
                            color={theme.main}
                            checked={!!checkedSkills[skillId]?.subSkills?.[subSkill.sub_skill_id]}
                            onChange={() => handleSubSkillChange(skillId, subSkill.sub_skill_id)}
                        />
                    }
                />
            ))}
        </Box>
    );

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div style={{ height: '600px', overflow: 'scroll' }}>
                {skills.map((skill) => (
                    <div key={skill.skill_id}>
                        {skill.sub_skills.length > 0 && (
                            <Card sx={{ mt: 2, p: 2 }}>
                                <FormControlLabel
                                    label={<strong style={{ fontSize: '18px' }}>{skill.skill_name.toUpperCase()}</strong>}
                                    control={
                                        <Checkbox
                                            color={theme.main}
                                            checked={!!checkedSkills[skill.skill_id]?.checked}
                                            indeterminate={!!checkedSkills[skill.skill_id]?.indeterminate}
                                            onChange={() => handleSkillChange(skill.skill_id, skill.sub_skills)}
                                        />
                                    }
                                />
                                {renderSubSkills(skill.skill_id, skill.sub_skills)}
                            </Card>
                        )}
                    </div>
                ))}
            </div>
            <button type="submit" sx={{ mt: 2 }} ref={submitRef} style={{ display: 'none' }} />
        </form>
    );
};

export default CheckboxesList;
