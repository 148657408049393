import React from 'react';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { theme } from '../../../../theme';

const TabPanel = ({ tab, setTab }) => {
    return (
        <Stack direction="row" spacing={1} sx={{ alignItems: 'center', pb: 2 }} justifyContent="right">
            <Typography>CERTIFICATES</Typography>
            <Switch defaultChecked color={theme.main} onClick={() => setTab(tab === "documents" ? "certificates" : "documents")}/>
            <Typography>DOCUMENTS</Typography>
        </Stack>
    );
}

export default TabPanel;
