import React, { useContext } from "react";

import CardWithHeaderTemplate from "../../../components/cardWithHeaderTemplate";
import RenderDocuments from "./components/RenderDocuments";
import { Context } from "./Provider";
import DivSpinner from "../../../components/divSpinner";

const Html = () => {
    const context = useContext(Context);
    const { state, document_type } = context;
    const { loading, data } = state;

    if(loading){
        return <DivSpinner/>
    }

    return (
        <CardWithHeaderTemplate
            header={<HeaderTitle title={document_type === "personal" ? "DOCUMENTS" : "CERTIFICATES"} />}
            body={
                <DocumentBody 
                    renderDocuments={
                        <RenderDocuments documents={data.documents} isPersonal={document_type === "personal"}/>
                    } 
                />
            }
            footer={null}
            extraCardClassName="blur shadow-blur max-height-vh-70 h-100"
            extraBodyClassName="overflow-auto overflow-x-hidden"
        />
    );
};

const HeaderTitle = ({ title }) => (
    <div className="row">
        <div className="col-8 my-auto">
            <div className="numbers">
                <h6 className="text-white font-weight-bolder mb-0 ms-3">{title}</h6>
            </div>
        </div>
    </div>
);

const DocumentBody = ({ renderDocuments }) => (
    <div className="row">
        <div className="row ps-5">
            <ul className="list-group">
                {renderDocuments}
            </ul>
        </div>
    </div>
);

export default Html;