import React, { useContext } from "react";
import { Box, CardActions, Grid, IconButton } from "@mui/material";

import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
// import DeleteIcon from '@mui/icons-material/Delete';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';

import SetExpiryDate from "./SetExpiryDate";
import { Context } from "../Provider";
import DeletedDocuments from "../../deleted/DeletedDocuments";
import History from "../../history/History";

const ActionList = () => {
    const context = useContext(Context);
    const { ftm_id, state, document_type, approveFtmDocument, refresh } = context;
    const { selectedDocument } = state;

    const isPersonal = document_type === "personal";

    const documentId = isPersonal ? selectedDocument?.document?.ftm_personal_document_id : selectedDocument?.ftm_document_id;
    const documentTypeId = isPersonal ? selectedDocument?.document?.ftm_personal_document_type_id : selectedDocument?.ftm_documents_types_id;
    const expiryDate = isPersonal ? selectedDocument?.document?.ftm_personal_document_expiry_date : selectedDocument?.ftm_document_expiry_date;
    const comment = isPersonal ? selectedDocument?.document?.ftm_personal_document_comment : selectedDocument?.ftm_document_comment;

    const actions = [
        {
            name: "EXPIRED",
            icon: <ScheduleSendIcon />,
            component: ({ action, index }) => (
                <SetExpiryDate
                    listItem={actions[index]}
                    selectedDocument={{
                        document_id: documentId,
                        expiry_date: expiryDate,
                        comment: comment,
                        document_type: document_type,
                    }}
                    document_type={document_type}
                    refresh={refresh}
                />
            ),
        },
        {
            name: "APPROVE",
            component: ({ action }) => (
                <IconButton onClick={action}>
                    <ThumbUpAltIcon />
                </IconButton>
            ),
            action: () => approveFtmDocument({
                document_id: documentId
            }),
        },
        // {
        //     name: "DELETE",
        //     component: ({ action }) => (
        //         <IconButton onClick={action}>
        //             <DeleteIcon />
        //         </IconButton>
        //     ),
        //     action: () => { },  // Define delete action if needed
        // },
    ];

    return (
        <Box >
            <Grid container justifyContent="space-between" direction="row">
                <CardActions disableSpacing>
                    {actions.map(({ component, action, ...rest }, index) =>
                        React.createElement(component, { key: index, action, index, ...rest })
                    )}
                </CardActions>
                <CardActions disableSpacing>
                    <DeletedDocuments ftm_id={ftm_id} document_id={documentId} document_type={document_type}/>
                    <History ftm_id={ftm_id} document_type_id={documentTypeId} document_type={document_type}/>
                </CardActions>
            </Grid>
        </Box>

    );
}

export default ActionList;