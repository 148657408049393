import React, { useContext, useEffect, useRef, useState, useCallback } from "react";
import { io } from "socket.io-client";

import { createMarkerContent, loadScript, map_settings } from "../../map";
import { Context } from "../provider";

const FtmLiveMap = () => {
    const [mapLoaded, setMapLoaded] = useState(false);
    const mapRef = useRef(null);
    const mapInstance = useRef(null); // Store the map instance
    const boundsRef = useRef(null); // Store the bounds instance
    const markersRef = useRef([]); // Store markers to avoid re-creating markers

    const context = useContext(Context);
    const { state } = context;
    const { details } = state;
    const { token, ftm_details } = details;

    // Load the Google Maps script
    useEffect(() => {
        loadScript(
            map_settings.map_script_url,
            () => setMapLoaded(true),
            () => console.error("Failed to load Google Maps script.")
        );
    }, []);

    // Initialize the map
    const initializeMap = useCallback(() => {
        if (mapInstance.current || !window.google || !window.google.maps) {
            return;
        }

        mapInstance.current = new window.google.maps.Map(mapRef.current, {
            center: { lat: 51.5286416, lng: -0.1015987 },
            zoom: 8,
            mapId: "FTM_LIVE_MAP"
        });

        boundsRef.current = new window.google.maps.LatLngBounds();
    }, []);

    // Add markers to the map
    const addMarkers = useCallback(() => {
        if (!mapInstance.current || !ftm_details) return;

        ftm_details.forEach(ftm => {
            const ftmLatLng = new window.google.maps.LatLng(parseFloat(ftm.ftm_latitude), parseFloat(ftm.ftm_longitude));
            boundsRef.current.extend(ftmLatLng);

            const ftmMarker = new window.google.maps.marker.AdvancedMarkerElement({
                position: ftmLatLng,
                content: createMarkerContent(ftm.ftm_is_activate === 1 ? map_settings.ftm_map_icon : map_settings.ftm_deactivate_map_icon),
                map: mapInstance.current,
                title: `${ftm.ftm_forename} ${ftm.ftm_surname}`,
            });

            markersRef.current.push({
                ftm_id: ftm.ftm_id,
                ftm_marker: ftmMarker
            });
        });

        mapInstance.current.fitBounds(boundsRef.current);
        mapInstance.current.setZoom(8); // Set zoom once after bounds are set
    }, [ftm_details]);

    // Handle live updates via socket
    const handleSocketUpdates = useCallback(() => {
        const socket = io.connect(`${process.env.REACT_APP_SOCKET_URL}${token}`);

        socket.on("locations", (data) => {
            console.log("ftm update: ", data);
            const index = markersRef.current.findIndex(marker => marker.ftm_id === data.ftm_id);

            const newMarker = new window.google.maps.marker.AdvancedMarkerElement({
                position: { lat: parseFloat(data.ftm_latitude), lng: parseFloat(data.ftm_longitude) },
                content: createMarkerContent(map_settings.ftm_map_icon),
                map: mapInstance.current,
                title: `${data.ftm_forename} ${data.ftm_surname}`,
            });

            if (index > -1) {
                markersRef.current[index].ftm_marker.setMap(null); // Remove old marker
                markersRef.current[index].ftm_marker = newMarker; // Update marker
            } else {
                markersRef.current.push({ ftm_id: data.ftm_id, ftm_marker: newMarker }); // Add new marker
            }
        });

        return () => {
            socket.disconnect(); // Clean up socket connection on unmount
        };
    }, [token]);

    // Effect for initializing the map and adding markers
    useEffect(() => {
        if (mapLoaded) {
            initializeMap();
            addMarkers();
            const cleanupSocket = handleSocketUpdates();

            return () => cleanupSocket(); // Cleanup on component unmount
        }
    }, [mapLoaded, initializeMap, addMarkers, handleSocketUpdates]);

    return <div ref={mapRef} style={{ width: "100%", height: 420 }} />;
};

export default FtmLiveMap;