import { GET } from "../../API/get";

export const fetchData = async ({page_number, search_value, sort_key, sort_direction}) => {
    let response = await GET(`get-not-attended-jobs/${page_number}?search=${search_value}&sort_key=${sort_key}&sort_direction=${sort_direction}`);
    if(response.status === "Success"){
        return response;
    }

    return null;
}

export const loadData = async ({page_number, search_value, dispatch}) => {
    dispatch({ type: "IS_LOADING" });

    let response = await GET(`get-not-attended-jobs/${page_number}?search=${search_value}`);
    if(response.status === "Success"){
        dispatch({ 
            type: "IS_LOADED", 
            total_jobs: response.total_jobs, 
            total_pages: response.total_pages, 
            jobs_list: response.list, 
            current_page: page_number 
        });
    }
}