import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuIcon from '@mui/icons-material/Menu';
import GroupIcon from '@mui/icons-material/Group';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Tooltip from '@mui/material/Tooltip';
import WorkIcon from '@mui/icons-material/Work';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import Context from "../context";

export default function ActionsList() {
    const context = React.useContext(Context);

    const handleClick = (event) => {
        context.dispatch({type: 'SET_ANCHOR_EL', anchor_el: event.currentTarget})
    };

    const handleSelector = (type) => {
        context.dispatch({type: 'SET_SELECTOR', selector: type});
    };

    return (
        <React.Fragment>
            <Tooltip title={context.state.selector} arrow placement="right">
                {context.state.selector === "ALL" ? <MenuIcon onClick={handleClick} style={{marginTop: '-10px'}}/> : null}
                {context.state.selector === "FTM" ? <GroupIcon onClick={handleClick} style={{marginTop: '-10px'}}/> : null}
                {context.state.selector === "CUSTOMER" ? <AccountCircleIcon onClick={handleClick} style={{marginTop: '-10px'}}/> : null}
                {context.state.selector === "POSTCODE" ? <LocationOnIcon onClick={handleClick} style={{marginTop: '-10px'}}/> : null}
                {context.state.selector === "JOBS" ? <WorkIcon onClick={handleClick} style={{marginTop: '-10px'}}/> : null}
                {context.state.selector === "JOBS_CREATEDAT" ? <CalendarMonthIcon onClick={handleClick} style={{marginTop: '-10px'}}/> : null}
            </Tooltip>
            
            <Menu
                anchorEl={context.state.anchor_el}
                open={Boolean(context.state.anchor_el)}
                onClose={() => context.dispatch({type: 'SET_ANCHOR_EL', anchor_el: null})}
                onClick={() => context.dispatch({type: 'SET_ANCHOR_EL', anchor_el: null})}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {/* <Divider /> */}
                <MenuItem 
                    onClick={() => handleSelector("ALL")}
                >
                    <ListItemIcon>
                        <MenuIcon fontSize="small" />
                    </ListItemIcon>
                    ALL
                </MenuItem>
                <MenuItem 
                    onClick={() => handleSelector("FTM")}
                >
                    <ListItemIcon>
                        <GroupIcon fontSize="small" />
                    </ListItemIcon>
                    TRADESPERSON
                </MenuItem>
                <MenuItem 
                    onClick={() => handleSelector("CUSTOMER")}
                >
                    <ListItemIcon>
                        <AccountCircleIcon fontSize="small" />
                    </ListItemIcon>
                    CUSTOMER
                </MenuItem>
                <MenuItem 
                    onClick={() => handleSelector("POSTCODE")}
                >
                    <ListItemIcon>
                        <LocationOnIcon fontSize="small" />
                    </ListItemIcon>
                    POSTCODE
                </MenuItem>
                <MenuItem 
                    onClick={() => handleSelector("JOBS")}
                >
                    <ListItemIcon>
                        <WorkIcon fontSize="small" />
                    </ListItemIcon>
                    JOBS
                </MenuItem>
                <MenuItem 
                    onClick={() => handleSelector("JOBS_CREATEDAT")}
                >
                    <ListItemIcon>
                        <CalendarMonthIcon fontSize="small" />
                    </ListItemIcon>
                    JOBS CREATEDAT
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
}