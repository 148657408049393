import React, { useContext, useState, useCallback } from "react";
import { Container, Form, Button } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";

import { theme } from "../../../../theme";
import ModalTemplateWithForm from "../../../../components/modal/modalTemplateWithForm";
import BtnSpinner from "../../../../components/btnSpinner";
import { IconButton } from "@mui/material";
import { updateExpiryDocument } from "../service";

const SetExpiryDate = ({ listItem, selectedDocument, document_type, refresh = () => {} }) => {
    const { register, handleSubmit, reset } = useForm();
    // const { document_type } = useContext(Context);

    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);

    const toggleModal = useCallback(() => setShow(prevShow => !prevShow), []);

    const onSubmit = async (data) => {
        setLoading(true);
        // await setupExpiryDocument({
        //     document_id: selectedDocument?.document_id,
        //     expiry_date: data.expiry_date,
        //     comment: data.comment
        // });

        const document_id = selectedDocument?.document_id;
        const expiry_date = data.expiry_date;
        const comment = data.comment;

        if(document_id === undefined){
            toast.error("Don't have document");
        }else{
            const response = await updateExpiryDocument({
                document_id, 
                expiry_date: new Date(expiry_date).getTime(), 
                comment, 
                document_type
            });
    
            response.status === "Success" ? toast.success(response.message) : toast.error(response.message);
            refresh();
        }

        reset();
        toggleModal();
        setLoading(false);
    };

    const formControlStyle = {
        backgroundColor: '#fff',
        paddingLeft: '10px',
        paddingRight: '10px',
        fontSize: '15px'
    };

    return (
        <>
            <IconButton 
                variant={theme.set_expired_color} 
                onClick={toggleModal}
            >
                {listItem.icon} 
            </IconButton>

            <ModalTemplateWithForm
                show={show}
                closeFun={toggleModal}
                title="Set Expiry Date"
                body={
                    <Container>
                        <Form.Group className="mb-3">
                            <Form.Label>EXPIRY DATE</Form.Label>
                            <Form.Control
                                type="date"
                                style={formControlStyle}
                                {...register("expiry_date", { required: true })}
                                defaultValue={selectedDocument?.expiry_date ? 
                                    new Date(selectedDocument.expiry_date).toISOString().split('T')[0] : ""}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>COMMENT</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={4}
                                style={formControlStyle}
                                {...register("comment")}
                                defaultValue={selectedDocument?.comment}
                            />
                        </Form.Group>
                    </Container>
                }
                footer={
                    <>
                        <Button 
                            variant={theme.close_btn} 
                            size="sm" 
                            disabled={loading} 
                            onClick={toggleModal}
                        >
                            {loading ? <BtnSpinner /> : "CLOSE"}
                        </Button>
                        <Button 
                            variant={theme.action_btn} 
                            type="submit" 
                            size="sm" 
                            disabled={loading}
                        >
                            {loading ? <BtnSpinner /> : "SAVE"}
                        </Button>
                    </>
                }
                onSubmit={handleSubmit(onSubmit)}
            />
        </>
    );
};

export default SetExpiryDate;