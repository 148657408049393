import React, { useReducer, useEffect } from "react";
import Reducer from "./reducer";
import { initialState } from "./initialState";
import Html from "./html";
import Context from "./context";
import { loadData } from "./service";
import DateFilter from "./components/dateFilter";
import { useParams } from "react-router-dom";
import FtmPay from "./components/ftmPay";

const FtmPayments = () => {
    let { ftm_id } = useParams();
    const [state, dispatch] = useReducer(Reducer,initialState);

    useEffect(() => {
        loadData({
            ftm_id: ftm_id,
            page_number: state.current_page,
            sort_key: state.sort_key,
            sort_direction: state.sort_direction,
            dispatch: dispatch
        });
    }, [state.current_page, state.sort_key, state.sort_direction]); 

    return (
        <Context.Provider value={{ftm_id, state, dispatch}}>
            <Html/>
            <DateFilter/>
            <FtmPay/>
        </Context.Provider>
    );
}

export default FtmPayments;