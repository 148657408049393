import toast from 'react-hot-toast';
import { GET } from "../../API/get";

export const loadData = async (dispatch, page_number, search_value, sort_key, sort_direction) => {
    dispatch({ type: "IS_LOADING" });
    let response = await GET(`all/payments/${page_number}?search=${search_value}&sort_key=${sort_key}&sort_direction=${sort_direction}`);

    if(response.status === "Success"){
        dispatch({
            type: "IS_LOADED",
            pre_auth_amount: response.details.pre_auth_amount[0],
            charge_amount: response.details.charge_amount[0],
            release_amount: response.details.release_amount[0],
            refund_amount: response.details.refund_amount[0],
            payments: response.details.payments,
            current_page: page_number,
            total_pages: response.total_pages
        });
    }
}