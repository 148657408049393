import React, { useContext } from "react";
import PersonIcon from '@mui/icons-material/Person';
import LockClockIcon from '@mui/icons-material/LockClock';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

import Info from "./components/info";
import Context from "./context";
import JobsheetImages from "./components/jobsheetImages";
import { Button } from "@mui/material";
import { theme } from "../../../theme";
import BtnSpinner from "../../../components/btnSpinner";
import { getJobsheetPDF } from "./service";

const Html = () => {
    const context = useContext(Context);

    return(
        <>
            <Info
                icon = {<PersonIcon/>}
                primary = {<b>TRADESPERSON NAME</b>}
                secondary = {context.state.job_sheet.ftm.ftm_forename+" "+context.state.job_sheet.ftm.ftm_surname}
            />
            <hr/>
            <Info
                icon = {<LockClockIcon/>}
                primary = {<b>TRADESPERSON CHECK IN TIME</b>}
                secondary = {context.state.job_sheet.job_check_in_time}
            />
            <hr/>
            <Info
                icon = {<AccessTimeFilledIcon/>}
                primary = {<b>TRADESPERSON CHECK OUT TIME</b>}
                secondary = {context.state.job_sheet.job_check_out_time}
            />
            <hr/>
            <Info
                icon = {<CalendarMonthIcon/>}
                primary = {<b>CREATED DATE</b>}
                secondary = {context.state.job_sheet.job_createdAt}
            />
            <hr/>
            <Info
                icon = {<LocalOfferIcon/>}
                primary = {<b>DESCRIPTION</b>}
                secondary = {context.state.job_sheet.job_sheet_description}
            />
            <hr/>
            <JobsheetImages
                images = {context.state.job_sheet.job_sheet_images}
            />

            <div className="offcanvas-footer">
                <div className="d-grid gap-2" style={{marginTop: '20%'}}>
                    <Button 
                        variant="contained" 
                        color={theme.action_btn} 
                        disabled={context.state.is_btn_loading}
                        onClick={()=> getJobsheetPDF(context.job_id, context.dispatch)}
                    >
                        {context.state.is_btn_loading ? (
                            <BtnSpinner/>
                        ): "GENERATE PDF"}
                    </Button>
                </div> 
            </div>
        </>
    );
}

export default Html;