import React, { useContext } from "react";
import DivSpinner from "../../../../components/divSpinner";
import { Context } from "../components/Provider";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { certificatesTableHeader } from "../components/certificates/tableHeader";
import { documentsTableHeader } from "../components/documents/tableHeader";
import DocumentsRender from "../components/documents/Render";
import CertificatesRender from "../components/certificates/Render";
import { NoDataMain } from "./NoData";

const DocumentsTable = () => {
    const { state, isCertificates } = useContext(Context);
    const {loading, documents} = state;

    const header = isCertificates ? certificatesTableHeader : documentsTableHeader;
    const render = isCertificates ? <CertificatesRender documents={documents} isCertificates={isCertificates}/> : <DocumentsRender documents={documents} isCertificates={isCertificates}/>;

    return (
        <>
            {loading ? (
                <div style={{height : `${window.innerHeight-600}px`}}>
                    <DivSpinner/>
                </div>
            ) : (
                <div className="table-responsive p-0 m-3">
                    <div className="table-responsive p-0 m-3">
                        <TableContainer component={Paper}>
                            <Table aria-label="collapsible table" size={'small'}>
                                <TableHead>
                                    <TableRow className='remedial-table-row'>
                                        <TableCell />
                                            {header.map((item, index) => (
                                                <TableCell align={item.align} key={index}>{item.name}</TableCell>
                                            ))}
                                        <TableCell />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {documents?.length > 0 ? render : (
                                        <NoDataMain />
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            )}
        </>
    )
}

export default DocumentsTable;