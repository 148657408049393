import { GET } from "../../../API/get";
import { FORMPOST } from "../../../API/post";
import { PUT } from "../../../API/put";

const fetchApiData = async (url) => {
    const response = await GET(url);
    return response?.status === "Success" ? response : null;
}

const updateDocument = async (url, data) => {
    const response = await PUT(url, data);
    return response?.status === "Success" ? response : null;
}

export const fetchPersonalDocumentsList = (document_id) => fetchApiData(`ftm/personal/documents/list/${document_id}`);
export const fetchCertificatesList = (document_id) => fetchApiData(`ftm/certificates/list/${document_id}`);

export const uploadMoveDocuments = async (documentId, documentType, file) => {
    const response = await FORMPOST("upload/move/documents", {
        ftm_document_id: documentId,
        ftm_document_type: documentType,
        ftm_document: file
    });

    return response.status === "Success" ? response : null; 
}

export const updateApproveDocument = (documentData) => updateDocument("ftm/approve/document", documentData);