import React, { useState } from "react";
import IconButton from '@mui/material/IconButton';
import UploadIcon from '@mui/icons-material/Upload';
import { styled } from "@mui/material";
import Spinner from 'react-bootstrap/Spinner';

import BtnSpinner from "../../../components/btnSpinner";
import { uploadPackageImage } from "../service";
import toast from "react-hot-toast";

const UploadPageImage = ({ftm_id}) => {
    const [uploading, setUploading] = useState(false);

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    const handleImageUpload = async (files) => {
        setUploading(true);
        const response = await uploadPackageImage({ftm_id, package_image: files[0]});
        response.status === "Success" ? toast.success(response.message) : toast.error(response.message);

        setUploading(false);
    }

    return (
        <div className="col-md-12 mb-lg-0 mb-4">
            <div className="card card-plain">
                <div className="card-body p-3">
                    <div className="row">
                        <div className="col-md-12 mb-md-0 mb-4">
                            <div 
                                className="card card-body border card-plain border-radius-lg d-flex align-items-center flex-row"
                                style={{
                                    paddingTop: '6px',
                                    paddingBottom: '6px',
                                    paddingLeft: '24px',
                                    paddingRight: '20px'
                                }}
                            >
                                <img className="w-8 me-3 mb-0" src="/assets/img/package-icon.png" alt="logo"/>
                                <h5 className="mb-0">Upload package image</h5>
                                <div className="d-flex flex-column ms-auto">
                                    <IconButton 
                                        component="label"
                                        role={undefined}
                                        variant="contained"
                                        tabIndex={-1}
                                    >
                                        {uploading ? <Spinner animation="border" size="sm" /> : (
                                            <>
                                                <UploadIcon />
                                                <VisuallyHiddenInput
                                                    type="file"
                                                    onChange={(event) => handleImageUpload(event.target.files)}
                                                />
                                            </>
                                        )}
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UploadPageImage;