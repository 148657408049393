import { GET } from "../../../API/get";
import { FORMPOST, POST } from "../../../API/post";

export const fetchSkills = async () => {
    const response = await GET(`skills`);
    return response.status === "Success" ? response : null;
}

export const fetchDocumentTypes = async (selectedSkills) => {
    const response = await POST(`selected/skills/document/types`, {selectedSkills: selectedSkills});
    return response.status === "Success" ? response : null;
}

export const updateFtmSkills = async ({ftm_id, skills}) => {
    const response = await POST(`set-ftm-skills`, {
        ftm_id: ftm_id,
        skills: skills
    });
    return response.status === "Success" ? response : null;
}

export const updateFtmDocuments = async ({ftm_id, document_type_id, document_type, document_file, document_type_type}) => {
    const response = await FORMPOST("ftm/upload/documents", {
        ftm_id: ftm_id,
        ftm_document_type_id: document_type_id,
        ftm_is_registering: 1,
        ftm_document_type: document_type,
        ftm_document: document_file,
        document_type: document_type_type,
        ftm_expired_document_id: 0
    });

    return response.status === "Success" ? response : null;
}