import React, { useEffect, useContext, useState, useRef, useCallback } from "react";
import Context from "../context";
import { createMarkerContent, loadScript, map_settings } from "../../../map";

const JobMap = () => {
    const [mapLoaded, setMapLoaded] = useState(false);
    const mapRef = useRef(null);
    const mapInstance = useRef(null); // Store the map instance
    const boundsRef = useRef(null); // Store the bounds instance

    const {state} = useContext(Context);
    const {job_details} = state;
    const {job_latitude, job_longitude, job_address} = job_details;

    // Load the Google Maps script
    useEffect(() => {
        loadScript(
            map_settings.map_script_url,
            () => setMapLoaded(true),
            () => console.error("Failed to load Google Maps script.")
        );
    }, []);

    // Initialize the map
    const initializeMap = useCallback(() => {
        if (mapInstance.current || !window.google || !window.google.maps) {
            return;
        }

        mapInstance.current = new window.google.maps.Map(mapRef.current, {
            center: { lat: parseFloat(job_latitude), lng: parseFloat(job_longitude) },
            zoom: 8,
            mapId: "JOB_MAP"
        });

        boundsRef.current = new window.google.maps.LatLngBounds();
    }, []);

    // Add markers to the map
    const addMarkers = useCallback(() => {
        if (!mapInstance.current || (!job_latitude && !job_longitude)) return;

        const jobLatLng = new window.google.maps.LatLng(parseFloat(job_latitude), parseFloat(job_longitude));
        boundsRef.current.extend(jobLatLng);

        new window.google.maps.marker.AdvancedMarkerElement({
            position: jobLatLng,
            content: createMarkerContent(map_settings.ftm_map_icon),
            map: mapInstance.current,
            title: `${job_address}`,
        });

        // mapInstance.current.fitBounds(boundsRef.current);
        mapInstance.current.setZoom(12); // Set zoom once after bounds are set
    }, [job_latitude, job_longitude]);

    // Effect for initializing the map and adding markers
    useEffect(() => {
        if (mapLoaded) {
            initializeMap();
            addMarkers();
        }
    }, [mapLoaded, initializeMap, addMarkers]);

    return(
        <div 
            className="page-header min-height-300 border-radius-xl mt-4" 
            style={{cursor: 'pointer'}}
            onClick={() => window.open(`http://maps.google.com/maps?q=${job_latitude},${job_longitude}`, '_blank')}
        >
            <div 
                ref={mapRef} 
                style={{ width: '100%', height: 350 }} 
            />
            {/* <span className="mask    opacity-6"></span> */}
        </div>
    );
}

export default JobMap;