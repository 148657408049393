/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Tooltip from '@mui/material/Tooltip';
import AddBoxIcon from '@mui/icons-material/AddBox';
import IconButton from '@mui/material/IconButton';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import DeleteIcon from '@mui/icons-material/Delete';
import toast from "react-hot-toast";

import { theme } from "../../../theme";
import CardWithHeaderTemplate from "../../../components/cardWithHeaderTemplate";
import { Context } from "../Provider";
import { deleteFtmSkill, updateFtmSkillApprove } from "../service";
import { commonSwal } from "../../../components/swalAlert/swalAlert";

const Skills = () => {
    const context = useContext(Context);
    const { ftm_id, state, openSkillModal, updateRefreshCount } = context;
    const { ftm } = state;
    const { ftm_skills } = ftm;

    console.log('ftm_skills :', ftm_skills);

    const handleApproveSkill = (ftm_skill_id) => {
        commonSwal({
            type: 'APPROVE_SKILL',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                const response = await updateFtmSkillApprove(ftm_skill_id, ftm_id);
                return response;
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                console.log('result :', result);
    
                if(result?.value?.status === "Success"){
                    updateRefreshCount();
                    toast.success(result.value.message);
                }
            }
        })
    }

    const handleDeleteSkill = (sub_skill_id) => {
        commonSwal({
            type: 'DELETE_SKILL',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                const response = await deleteFtmSkill(sub_skill_id);
                return response;
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                console.log('result :', result);
    
                if(result.value.status === "Success"){
                    toast.success(result.value.message)
                    updateRefreshCount();
                }
            }
        })
    }

    return(
        <CardWithHeaderTemplate
            header={
                <div className="row">
                    <div className="col-8 my-auto">
                        <div className="numbers">
                            <h6 className="text-white font-weight-bolder mb-0 ms-3">
                                SERVICE CATEGORIES & TYPES
                            </h6>
                        </div>
                    </div>
                    <div className="col-4 text-end">
                        <IconButton 
                            sx={{color: '#fff'}}
                            style={{marginRight: '10px'}}
                            onClick={() => openSkillModal()}
                            size="small"
                        >
                            <AddBoxIcon style={{fontSize: '20px'}}/>
                        </IconButton>
                    </div>
                </div>
            }
            body={
                <ul 
                    className="list-group"
                    style={{
                        height: '500px',
                        overflow: 'auto'
                    }}
                >
                    {ftm_skills.map((skill, index) => (
                        <li className="list-group-item border-0 d-flex align-items-center px-0 mb-2 pt-0" key={index}>
                            <div className="me-3"></div>
                            <div className="d-flex align-items-start flex-column justify-content-center">
                                <h6 className="mb-0 text-sm">{skill.skill_name}</h6>
                                {skill?.subSkills?.map((sub_skill, sub_index) => (
                                    <Box display="flex" justifyContent="space-between" width="100%" key={sub_index}>
                                        <Typography variant="body2" component="p" marginRight={2} sx={{width: '250px'}}>
                                            {sub_skill.sub_skill_name}
                                            {sub_skill.skill_is_approve === 1 && (
                                                <Tooltip title="Approved">
                                                    <CheckCircleIcon fontSize="small" sx={{color: theme.action_btn_code}} />
                                                </Tooltip>
                                            )}
                                        </Typography>
                                        
                                        <IconButton 
                                            sx={{
                                                visibility: sub_skill.skill_is_approve === 1 ? 'hidden' : 'visible'
                                            }}
                                            color={theme.main} 
                                            style={{marginRight: '10px'}}
                                            onClick={() => handleApproveSkill(sub_skill.ftm_skill_id)}
                                            size="small"
                                        >
                                            <ThumbUpIcon style={{fontSize: '20px'}}/>
                                        </IconButton>

                                        <IconButton 
                                            color={theme.action_btn_code} 
                                            style={{marginRight: '10px'}}
                                            onClick={() => handleDeleteSkill(sub_skill.sub_skill_id)}
                                            size="small"
                                        >
                                            <DeleteIcon style={{fontSize: '20px'}}/>
                                        </IconButton>
                        
                                    </Box>
                                ))}
                            </div>
                            
                            <hr/>
                        </li>
                    ))}
                </ul>
            }
            footer={null}
            extraCardClassName={`blur shadow-blur h-100`}
            extraBodyClassName={`overflow-auto overflow-x-hidden`}
        />
    );
}

export default Skills