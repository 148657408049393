import React, { createContext, useEffect, useReducer } from "react";
import { initialState } from "./initialState";
import Reducer from "./reducer";
import { fetchData } from "./service";

const Context = createContext();

const Provider = ({ children }) => {
    const [state, dispatch] = useReducer(Reducer,initialState);

    useEffect(() => {
        const getData = async () => {
            try{
                dispatch({ type: "IS_LOADING" });
                let response = await fetchData({
                    page_number : state.current_page, 
                    search_value : state.search_value,
                    sort_key : state.sort_key,
                    sort_direction : state.sort_direction
                });

                dispatch({ 
                    type: "IS_LOADED", 
                    total_jobs: response.total_jobs, 
                    total_pages: response.total_pages, 
                    jobs_list: response.running_jobs
                });
            }catch(error){
                console.log(error);
            }
        };

        getData();
    }, [state.current_page, state.search_value, state.sort_key, state.sort_direction]); 

    return (
        <Context.Provider value={{state, dispatch}}>
            {children}
        </Context.Provider>
    );
}

export { Context, Provider };