import Swal from 'sweetalert2';

import { GET } from "../../API/get";
import { DELETE } from '../../API/delete';
import { theme } from '../../theme';
import { commonSwal } from '../../components/swalAlert/swalAlert';

export const loadData = async (page_number, search_type, serach_text, sort_key, sort_direction, dispatch) => {
    console.log("🚀 ~ loadData ~ search_type:", search_type)
    dispatch({ type: "IS_LOADING" });
    
    let url = getUrl(search_type, serach_text, sort_key, sort_direction, page_number);
    let response = await GET(url);

    if(response.status === "Success"){
        dispatch({ 
            type: "IS_LOADED",
            total_ftms: response.total_ftms,
            total_pages: response.total_pages,
            list: response.list,
            // page_number: page_number,
            // search_type: search_type
        });
    }
}

const getUrl = (search_type, serach_text, sort_key, sort_direction, page_number) => {
    let url = `get-all-ftms/${page_number}?search=${serach_text.trim()}&sort_key=${sort_key}&sort_direction=${sort_direction}`;
    switch(search_type) {
        case 'Rejected':
           return  url+"&ftm_is_verify=-1";
        case 'New FTM - Rejected':
           return  url+"&ftm_is_verify=0";
        case 'Verified':
           return  url+"&ftm_is_verify=1";
        case 'Waiting Approval':
           return  url+"&ftm_is_verify=2";
        case 'Online':
           return  url+"&ftm_is_online=1";
        case 'Offline':
           return  url+"&ftm_is_online=0";
        default:
           return  url;
    }
}

export const getStatusColor = (ftm_is_verify) => {
    switch(ftm_is_verify){
        case 1:
            return 'success';
        case -1:
            return 'danger';
        case 0:
            return 'warning';
        case 2:
            return 'info';
        default:
            return '';
    }
}

export const getStatusText = (ftm_is_verify) => {
    switch(ftm_is_verify){
        case 1:
            return 'Verified';
        case -1:
            return 'Rejected';
        case 0:
            return 'Pending';
        case 2:
            return 'Waiting Approval';
        default:
            return '';
    }
}

export const deleteFTM = (ftm_id, page_number, search_type, dispatch) => {
    commonSwal({
        type: 'DELETE_FTM',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: async () => {
            let response = await DELETE(`delete-ftm/${ftm_id}`);
            return response;
        },
        allowOutsideClick: () => !Swal.isLoading()
    }).then(async (result) => {
        console.log('result :', result);
        if (result.isConfirmed) {
            console.log('result :', result);

            if(result.value.status === "Success"){
                loadData(page_number, search_type, dispatch);
            }
        }
    })
}