import React, { useContext } from "react";
import { Context } from "../../Provider";

const BasicInformation = () => {
    const { state } = useContext(Context);
    const { selected_ftm } = state;

    const {
        ftm_email = "N/A",
        ftm_contact_number = "N/A",
        ftm_birth_of_date = "N/A",
        ftm_gender = null,
        ftm = null
    } = selected_ftm || {};

    const genderText = 
        ftm_gender === 0 ? "Male" :
        ftm_gender === 1 ? "Female" :
        ftm_gender === 2 ? "Non-binary" : 
        "N/A";

    const handleGetAddress = (ftm) => {
        if (!ftm) return "";
    
        const { ftm_street_address, ftm_place, ftm_postcode } = ftm;
    
        return [
            ftm_street_address || "",
            ftm_place || "",
            ftm_postcode || ""
        ].filter(Boolean).join(" ");
    };

    const infoItems = [
        { label: "Address", value: handleGetAddress(ftm) || "N/A" },
        { label: "Email", value: ftm_email },
        { label: "Phone Number", value: ftm_contact_number },
        { label: "Date of Birth", value: ftm_birth_of_date },
        { label: "Gender", value: genderText },
    ];

    return (
        <div className="col-lg-12">
            <div className="card h-100">
                <div className="card-header pb-0 p-3">
                    <div className="row">
                        <div className="col-12 d-flex align-items-center">
                            <h6 className="mb-0">PERSONAL INFORMATION</h6>
                        </div>
                    </div>
                </div>
                <div className="card-body p-3 pb-0">
                    <ul className="list-group">
                        {infoItems.map((item, index) => (
                            <li
                                key={index}
                                className="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
                            >
                                <div className="d-flex flex-column">
                                    <h6 className="mb-1 text-dark font-weight-bold text-sm">
                                        {item.label}
                                    </h6>
                                </div>
                                <div className="d-flex align-items-center text-sm">
                                    {item.value}
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default BasicInformation;