/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import WorkIcon from '@mui/icons-material/Work';
import MoodBadIcon from '@mui/icons-material/MoodBad';
import Chip from '@mui/material/Chip';
import MessageIcon from '@mui/icons-material/Message';
import Badge from '@mui/material/Badge';
import Tooltip from '@mui/material/Tooltip';
import { Link } from "react-router-dom";
import LogoutIcon from '@mui/icons-material/Logout';

import SearchModal from "./search/searchModal";
import ProfileDropDown from "./profileDropDown";
import { theme } from "../theme";
import { getAdminDetailsInFirebase } from "./service";
import useCurrentLocation from "./hooks/useCurrentLocation";
import { findMatchingRoute } from "../utils";

import ShopSvg from "./svg/shopSvg";
import useCustomNavigate from "./hooks/useCustomNavigate";
import { removeUserInfo } from "../storage";
import useWindowDimensions from "./hooks/useWindowDimensions";

const Navbar = ({ isFullSidebar, setIsFullSidebar }) => {
    const { pathname } = useCurrentLocation();
    const [isOpen, setIsOpen] = useState(false);
    const [adminDetails, setAdminDetails] = useState(null);
    const [pageName, setPageName] = useState("");
    const { navigateToHome } = useCustomNavigate();
    const { width } = useWindowDimensions();

    const handleKeyPress = (event) => {
        if (event.ctrlKey && event.key === 'k') {
            event.preventDefault();
            setIsOpen(true);
        }
    };

    useEffect(() => {
        let page_name = findMatchingRoute(pathname);
        setPageName(page_name);

        getAdminDetailsInFirebase(setAdminDetails);
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [pathname]);

    return (
        <React.Fragment>
            {/* <nav className="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl" id="navbarBlur" data-scroll="true"> */}
            <nav className="navbar navbar-main navbar-expand-lg px-0 mx-4 border-radius-xl shadow-none position-sticky blur shadow-blur mt-4 left-auto top-1 z-index-sticky" id="navbarBlur" data-scroll="true">
                <div className="container-fluid py-1 px-3">

                    <nav aria-label="breadcrumb" className={isFullSidebar ? "mw-150" : null}>
                        <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
                            <li className="breadcrumb-item text-sm">
                                <Link className="opacity-3 text-dark" to="/dashboard">
                                    <ShopSvg />
                                </Link>
                            </li>
                            <li className="breadcrumb-item text-sm"><a className="opacity-5 text-dark" href="#">Pages</a></li>
                            <li className="breadcrumb-item text-sm text-dark active" aria-current="page">{pageName}</li>
                        </ol>
                        <h6 className="font-weight-bolder mb-0">{pageName}</h6>
                    </nav>

                    {width > 1320 && (
                        <div className="sidenav-toggler sidenav-toggler-inner d-xl-block d-none ">
                            <a href="#" className="nav-link text-body p-0" onClick={() => setIsFullSidebar(!isFullSidebar)}>
                                <i className="material-icons opacity-10">
                                    {isFullSidebar ? "menu_open" : "menu"}
                                </i>
                            </a>
                        </div>
                    )}

                    <div className="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4" id="navbar">
                        <div className="ms-md-auto pe-md-3 d-flex align-items-center">
                            <div className="input-group input-group-outline">
                                <TextField
                                    size="small"
                                    placeholder="Search..."
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Chip label="Ctrl+K" size="small" />
                                            </InputAdornment>
                                        ),
                                        style: {
                                            borderRadius: '10px'
                                        }
                                    }}
                                    style={{
                                        width: '400px',
                                    }}
                                    onClick={() => setIsOpen(true)}
                                    autoComplete="off"
                                    readOnly
                                />
                            </div>
                        </div>

                        <ul className="navbar-nav  justify-content-end">
                            {pathname !== "/notifications" && (
                                <React.Fragment>
                                    {[
                                        {
                                            title: "Direct",
                                            type: "DIRECT",
                                            to: '/messages/jobs/inbox',
                                            count: adminDetails?.user_total_direct_unread_message_count > 0 ? adminDetails?.user_total_direct_unread_message_count : 0
                                        },
                                        {
                                            title: "Dispute",
                                            type: "DISPUTE",
                                            to: '/messages/disputes/inbox',
                                            count: adminDetails?.user_total_dispute_unread_message_count > 0 ? adminDetails?.user_total_dispute_unread_message_count : 0
                                        },
                                        {
                                            title: "Office",
                                            type: "OFFICE",
                                            to: '/messages/office/inbox',
                                            count: adminDetails?.user_total_office_unread_message_count > 0 ? adminDetails?.user_total_office_unread_message_count : 0
                                        }
                                    ].map((item, index) => (
                                        <li className="nav-item d-flex align-items-center" key={index}>
                                            <Tooltip title={item.title} arrow>
                                                <Link to={item.to} className={`${index === 0 && 'ms-4'} mb-0 me-4`}>
                                                    <Badge
                                                        badgeContent={item.count}
                                                        color={theme.sidebar_notification_count_color}
                                                    >
                                                        {item.type === "DIRECT" && <WorkIcon />}
                                                        {item.type === "DISPUTE" && <MoodBadIcon />}
                                                        {item.type === "OFFICE" && <MessageIcon />}
                                                    </Badge>
                                                </Link>
                                            </Tooltip>
                                        </li>
                                    ))}
                                </React.Fragment>
                            )}

                            <li className="nav-item d-flex align-items-center">
                                <ProfileDropDown />
                            </li>
                            &nbsp; &nbsp; 
                            <li className="ms-3 nav-item d-flex align-items-center">
                                <Tooltip title={"Logout"} arrow>
                                    <a 
                                        href="#" 
                                        className="nav-link text-body font-weight-bold px-0" 
                                        onClick={() => {
                                            removeUserInfo();
                                            navigateToHome();
                                        }}
                                    >
                                        <LogoutIcon />
                                        <span className="d-sm-inline d-none">LOGOUT</span>
                                    </a>
                                </Tooltip>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>

            <SearchModal
                showModal={isOpen}
                handleClose={() => setIsOpen(false)}
            />
        </React.Fragment>
    );
}

export default Navbar;