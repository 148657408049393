export default function Reducer(prevState, action){
    switch (action.type) {
        case "IS_LOADING":
            return {
                ...prevState,
                is_loading: true,
                is_show_date_filter: false,
            };
        case "IS_LOADED": 
            return {
                ...prevState,
                is_loading: false,
            }; 
        case "IS_SHOW_DATE_FILTER":
            return {
                ...prevState,
                is_show_date_filter: action.is_show_date_filter,
            };
        case "SET_PAYMENTS": 
            return {
                ...prevState,
                payments: action.payments,
                total_jobs: action.total_jobs, 
                total_pages: action.total_pages,
                current_page: action.current_page,
                ftm: action.ftm,
                is_loading: false,
            }; 
        case "SET_DATE_RANGE": 
            return {
                ...prevState,
                start_date: action.start_date,
                end_date: action.end_date
            }; 
        case "SET_ERROR": 
            return {
                ...prevState,
                error: action.error
            };
        case "IS_SHOW_FTM_PAY_MODAL": 
            return {
                ...prevState,
                is_show_ftm_pay_modal: action.is_show_ftm_pay_modal,
                is_filtering: action.is_filtering,
                is_show_date_filter: false
            };
        case "IS_FILTERING": 
            return {
                ...prevState,
                is_filtering: true,
            };
        case "SET_FILTER_DATA": 
            return {
                ...prevState,
                filter_data: action.filter_data,
                is_filtering: false,
                total_ftm_payment: action.total_ftm_payment
            };
        case "TOTAL_FTM_PAYMENT": 
            return {
                ...prevState,
                total_ftm_payment: parseFloat(prevState.total_ftm_payment)+parseFloat(action.total_ftm_payment),
            };
        case "SET_SELECT_ALL_CHECKBOX": 
            return {
                ...prevState,
                select_all_checked: action.select_all_checked,
                total_ftm_payment: parseFloat(prevState.total_ftm_payment)+parseFloat(action.total_ftm_payment),
            };
        case "IS_SHOW_INVOICE_MODAL":
            return {
                ...prevState,
                is_show_invoice_modal: action.is_show_invoice_modal
            };
        case "IS_LOADING_INVOICE_INFO": 
            return {
                ...prevState,
                is_loading_invoice_info: true,
                invoice_type: action.invoice_type
            };
        case "SET_INVOICE_INFO":
            return {
                ...prevState,
                invoice_info: action.invoice_info,
                is_loading_invoice_info: false
            };
        case "UPDATE_CURRENT_PAGE":
            return {
                ...prevState,
                current_page: action.current_page,
            };
        case "SET_SORT_CONFIG":
            return {
                ...prevState,
                sort_key: action.sort_key,
                sort_direction: action.sort_direction,
            };
        default:
            console.log("Reducer : ", action.type);
            throw new Error();
    }
}