import React, { Fragment, useContext } from "react";
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import {useNavigate} from "react-router-dom";

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CachedIcon from '@mui/icons-material/Cached';
import VisibilityIcon from '@mui/icons-material/Visibility';

import Context from "../context";
import DivSpinner from "../../../components/divSpinner";
import { theme } from "../../../theme";
import { chargeJobPayment } from '../service';
import ColumnSort from "./columnSort";

const Table = () => {
    const context = useContext(Context);
    let navigate = useNavigate();

    return(
        <>
            {context.state.is_loading ? (
                <div style={{height : `${window.innerHeight-387}px`}}>
                    <DivSpinner/>
                </div>
            ) : (
                <div className="table-responsive p-0 m-3">
                    <table className="table align-items-center mb-0">
                        <thead>
                            <tr>
                                {[
                                    'Job ID', 
                                    'Tradesperson', 
                                    'Customer', 
                                    'Postcode', 
                                    'Job type', 
                                    <span>Company<br/>earnings</span>,
                                    <span>Tradesperson<br/>earnings</span>, 
                                    <span>
                                        {context.state.nav_type === 'notpaid' ? 'Receive' : null}
                                        {context.state.nav_type === 'notpaid' ? <br /> : null}
                                        {context.state.nav_type === 'notpaid' ? 'payment by' : null}
                                    </span>,
                                    <span>Tradesperson<br/>Payment</span>
                                ].map((text, index) => (
                                    <th 
                                        key={index}
                                        className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7"
                                    >
                                        <ColumnSort sortKeyName={text} />
                                    </th>
                                ))}
                                <th className="text-secondary opacity-7"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {context.state.jobs_list.length > 0 ? (
                                <>
                                    {context.state.jobs_list.map((job, index) => (
                                        <tr key={index}>
                                            <td className="align-middle">
                                                <span className="text-secondary text-sm font-weight-bold">{job?.job_id}</span>
                                            </td>
                                            <td>
                                                <div className="d-flex px-2 py-1">
                                                    <div className="d-flex flex-column justify-content-center">
                                                        <h6 className="mb-0 text-sm">{job?.ftm?.ftm_forename} {job?.ftm?.ftm_surname}</h6>
                                                        <p className="text-xs text-secondary mb-0">Created {job?.job_createdAt}</p>
                                                    </div>
                                                </div>
                                            </td>
                                            {[
                                                job?.customer?.customer_full_name,
                                                job?.job_postcode === null ? 'N/A' : job?.job_postcode,
                                                job?.sub_skill?.skill?.skill_name+' - '+job?.sub_skill?.sub_skill_name,
                                                `${theme.currency} ${job?.job_value_calculator?.total_company_commission}`,
                                                `${theme.currency} ${job?.job_value_calculator?.total_ftm_payment}`,
                                                <Fragment>{job?.complete_payment_by}</Fragment>,
                                                job?.job_is_pay_ftm == 1 ? <CheckCircleIcon style={{color: 'green'}}/> : <CancelIcon style={{color: 'red'}}/>
                                            ].map((text, index) => (
                                                <td className="align-middle" key={index}>
                                                    <span className={`text-secondary text-sm font-weight-bold ${index === 5 && job?.is_job_payment_failed ? 'txt-payment-failed' : ''}`}>
                                                        {text}
                                                    </span>
                                                    <span className="text-sm font-weight-bold">
                                                        <br/>
                                                        {context.state.nav_type ==='notpaid' && index === 5 && job?.is_job_payment_failed ? (
                                                            <Tooltip title="Automatic job payment processing failed. Please click here to process job payment again." arrow>
                                                                <Chip 
                                                                    label={ context.state.job_id === job?.job_id && context.state.is_payment_success ? "Processing payment..." : "FAILED"} 
                                                                    color={ context.state.job_id === job?.job_id && context.state.is_payment_success ? "success" : "error"}
                                                                    onClick={() => chargeJobPayment(job?.job_id, context)} 
                                                                    icon={<CachedIcon className={context.state.job_id === job?.job_id && context.state.is_payment_success ? "rotateIcon" : null} />} 
                                                                    size="small"
                                                                    disabled={context.state.is_payment_success} 
                                                                    className="disabled-chip"/>
                                                            </Tooltip>
                                                        ) : ( 
                                                            context.state.nav_type ==='notpaid' && index === 5 ? (
                                                                <Tooltip title="Please click here to process job payment." arrow>
                                                                    <Chip 
                                                                        label={ context.state.job_id === job?.job_id && context.state.is_payment_success ? "Processing payment..." : "Process payment"} 
                                                                        color="success"
                                                                        onClick={() => chargeJobPayment(job?.job_id, context)} 
                                                                        icon={<CachedIcon className={context.state.job_id === job?.job_id && context.state.is_payment_success ? "rotateIcon" : null} />} 
                                                                        size="small"
                                                                        disabled={context.state.is_payment_success} 
                                                                        className="disabled-chip"/>
                                                                </Tooltip>
                                                            ) : null
                                                        )}
                                                    </span>
                                                </td>
                                            ))}
                                            
                                            <td className="align-middle">
                                                <VisibilityIcon 
                                                    style={{cursor: 'pointer'}}
                                                    onClick={() => navigate(`/jobs/view/${job?.job_id}`)} 
                                                />
                                            </td> 
                                        </tr>
                                    ))}
                                </>
                            ) : (
                                <tr>
                                    <td colSpan="8">Sorry, no data found!</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )}
        </>
    );
}

export default Table;