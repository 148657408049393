export default function Reducer(prevState, action){
    switch (action.type) {
        case "LOADING":
            return {
                ...prevState,
                loading: true,
            };
        case "LOADED": 
            return {
                ...prevState,
                history: action.history,
                loading: false,
            };
        default:
            console.log("action.type: ", action.type)
            throw new Error();
    }
}