import React, { useReducer, useEffect } from "react";
import Reducer from "./reducer";
import { initialState } from "./initialState";
import Html from "./html";
import Context from "./context";
import { loadData } from "./service";
import DateFilter from "./components/dateFilter";

const AllPayments = () => {
    const [state, dispatch] = useReducer(Reducer,initialState);

    useEffect(() => {
        loadData({
            page_number: state.current_page,
            search_value: state.search_value,
            start_date: state.start_date,
            end_date: state.end_date,
            sort_key: state.sort_key,
            sort_direction: state.sort_direction,
            dispatch: dispatch
        });
    }, [state.current_page, state.search_value, state.end_date, state.sort_key, state.sort_direction]); 

    return (
        <Context.Provider value={{state, dispatch}}>
            <Html/>
            <DateFilter/>
        </Context.Provider>
    );
}

export default AllPayments;