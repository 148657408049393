import { DOWNLOADFILE, GET } from "../../API/get";
import { FORMPOST, POST } from "../../API/post";
import { PUT } from '../../API/put';
import { getUserInfo } from '../../storage';
import { DELETE } from '../../API/delete';
import { usersRef } from '../../firebase';

export const updateFtmAvailability = async (ftm_id, ftm_is_online) => {
    const response = await PUT("update-ftm-online-status", { ftm_id, ftm_is_online: ftm_is_online ? 1 : 0 });
    return response.status === "Success" ? response : null;
}  

export const fetchFtm = async (ftm_id) => {
    const response = await GET(`ftm-profile/${ftm_id}`);
    return response.status === "Success" ? response.ftm : null;
}

export const fetchSkills = async () => {
    const response = await GET("skills");
    return response.status === "Success" ? response.list : null;
}

export const updateDevice = async (ftm_id, ftm_device_id) => {
    const response = await POST("setup/gps/device", { ftm_id, ftm_device_id });
    return response.status === "Success" ? response : null;
}

export const deleteDevice = async (ftm_id) => {
    const response = await PUT("remove/gps/device", { ftm_id });
    return response.status === "Success" ? response : null;
}

export const trackerDispatch = async (ftm_id) => {
    const response = await PUT("tracker/dispatched", { ftm_id });
    return response.status === "Success" ? response : null;
}

export const fetchFTMHolidaysCalendar = async ({ftm_id, start_date, end_date}) => {
    const response = await GET(`ftm/calendar/holidays/${ftm_id}?start_date=${start_date}&end_date=${end_date}`);
    return response.status === "Success" ? response : null;
}

export const fetchFtmPersonalDocumentDownload = async ({ftm_id, file_id, file_ext}) => {
    await DOWNLOADFILE(`ftm/documents/download/${ftm_id}/${file_id}`, file_ext);
}

export const updateExpiryDocument = async ({document_id, expiry_date, comment, document_type}) => {
    const response = await PUT("ftm/documents/expiry-date", { 
        document_id, 
        expiry_date, 
        comment, 
        document_type 
    });
    return response.status === "Success" ? response : null;
}

export const updateApproveDocument = async ({document_id, document_type}) => {
    const response = await PUT("ftm/approve/document", { 
        document_id,
        document_type 
    });
    return response.status === "Success" ? response : null;
}

export const fetchAdminDetailsInFirebase = async (ftm_firebase_document_id, dispatch) => {
    let count = 0;
    if(ftm_firebase_document_id !== null){
        const query = usersRef.doc(ftm_firebase_document_id);
        await query.onSnapshot(async (doc) => {
            if (doc.exists) {
                count = doc.data().user_total_office_admin_unread_message_count;
            } 
        });
    }

    return count;
}

export const updateLastMessageSeenUser = async (document_id) => {
    try {
        // Get user info
        let user_info = getUserInfo();
        let ref = usersRef.doc(document_id);

        ref.update({
            user_last_message_seen_user: user_info.admin_firebase_document_id,
            user_last_message_seen_timestamp: new Date().getTime()
        });
    } catch (error) {
        console.error("Error open chat documents:", error);
    }
}

export const updateFtmSkillApprove = async (ftm_skill_id, ftm_id) => {
    console.log('ftm_id :', ftm_id);
    console.log('ftm_skill_id :', ftm_skill_id);

    const response = await PUT("is/approve/ftm/skill", {
        is_approve: 1,
        ftm_skill_id: ftm_skill_id,
        ftm_id: ftm_id
    });
    return response.status === "Success" ? response : null;
}

export const deleteFtmSkill = async (ftm_id, sub_skill_id) => {
    const response = await DELETE(`delete-ftm-sub-skills/${ftm_id}/${sub_skill_id}`);
    return response.status === "Success" ? response : null;
}

export const updateFtmApproveAndReject = async (ftm_id, ftm_is_verify, reason = "") => {
    const userInfo = getUserInfo();
    const response = await PUT("ftm-is-verify", {
        ftm_id: ftm_id,
        ftm_is_verify: ftm_is_verify,
        ftm_reject_reason: reason,
        admin_id: userInfo.admin_id
    });

    return response.status === "Success" ? response : null;
}

export const fetchFtmLabel = async (ftm_id) => {
    await DOWNLOADFILE(`ftm/label/${ftm_id}`);
}

export const uploadPackageImage = async ({ftm_id, package_image}) => {
    const response = await FORMPOST("upload/ftm/label", {
        ftm_id: ftm_id,
        package_image: package_image
    });

    return response.status === "Success" ? response : null;
}