export const initialState = {
    is_loading: true,
    total_jobs: 0,
    total_pages: 0,
    current_page: 1,
    jobs_list: [],
    search_type: "",
    search_value: "",
    is_show_search_modal: false,
    nav_type: "",
    is_payment_success: false,
    job_id: null,
    sort_key: '',
    sort_direction: ''
}