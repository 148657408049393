import toast from 'react-hot-toast';
import Swal from 'sweetalert2';

import { GET } from "../../API/get";
import { POST } from "../../API/post";
import { PUT } from "../../API/put";
import { theme } from '../../theme';
import { DELETE } from '../../API/delete';
import { commonSwal } from '../../components/swalAlert/swalAlert';

export const loadData = async (skill_id, type, dispatch) => {
    dispatch({ type: "IS_LOADING" });

    let response = await GET(`document/types/sub/skills/${skill_id}/${type}`);

    if(response.status === "Success"){
        dispatch({ 
            type: "IS_LOADED", 
            certificate_types: response.document_types
        });
    }
}

export const saveNewCertificate = async (skill_id, data, type, dispatch, reset) => {
    if(data.certificate_type_name){
        dispatch({ 
            type: "SET_IS_LOADING_BTN", 
            btn_loading: true
        });

        let response = await POST("document-types", {
            documents_types_skill_id : skill_id,
            documents_types_name : data.certificate_type_name,
            documents_types_is_required : data.is_mandatory ? 1 : 0,
            documents_types_type: type
        });

        if(response.status === "Success"){
            toast.success(response.message);
            reset();
            loadData(skill_id, type, dispatch);
        }
    }else{
        dispatch({
            type: 'SET_ERROR',
            error: 'Please add certificate type name'
        });
    }
}

export const updateCertificate = async (event, selected_certificate_type, type, dispatch, reset) => {
    event.preventDefault();
    
    const documents_types_name = event.target.elements.documents_types_name.value;
    const is_mandatory = event.target.elements.is_mandatory.checked; // Get checkbox value

    if(documents_types_name) {
        dispatch({ 
            type: "SET_IS_LOADING_BTN", 
            btn_loading: true
        });

        const response = await PUT("document-types", {
            documents_types_id: selected_certificate_type.documents_types_id,
            documents_types_name: documents_types_name,
            documents_types_is_required: is_mandatory ? 1 : 0 // Send as 1 or 0 based on checkbox status
        });

        if(response.status === "Success") {
            toast.success(response.message);
            reset();
            loadData(selected_certificate_type.documents_types_skill_id, type, dispatch);
        }
    } else {
        dispatch({
            type: 'SET_ERROR',
            error: 'Please add certificate type name'
        });
    }
}

export const deleteCertificate = async (selected_certificate_type, type, dispatch) => {
    commonSwal({
        type: 'DELETE_CERTIFICATE',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: async () => {
            let response = await DELETE("document-types/"+selected_certificate_type.documents_types_id);
            return response;
        },
        allowOutsideClick: () => !Swal.isLoading()
    }).then(async (result) => {
        console.log('result :', result);
        if (result.isConfirmed) {
            console.log('result :', result);

            if(result.value.status === "Success"){
                toast.success(result.value.message);
                loadData(selected_certificate_type.documents_types_skill_id, type,dispatch);
            }
        }
    })
}