import { commonSwal } from '../swalAlert/swalAlert';

export const openImagePreview = (image) => {
    commonSwal({
        type: 'OPEN_IMAGE_PREVIEW',
        imageUrl: image.url,
        imageWidth: 450,
        imageHeight: 250,
        imageAlt: "Custom image",
        showConfirmButton: false,
    });
} 