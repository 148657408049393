import { DELETE } from "../../../API/delete";
import { DOWNLOADFILE, GET } from "../../../API/get";
import { PUT } from "../../../API/put";

const fetchApiData = async (url) => {
    const response = await GET(url);
    return response?.status === "Success" ? response : null;
}

export const fetchPersonalDocuments = (ftm_id) => fetchApiData(`ftm/personal/documents/${ftm_id}`);
export const fetchPersonalDocumentsList = (document_id) => fetchApiData(`ftm/personal/documents/list/${document_id}`);
export const fetchCertificates = (ftm_id) => fetchApiData(`ftm/certificates/${ftm_id}`);
export const fetchCertificatesList = (document_id) => fetchApiData(`ftm/certificates/list/${document_id}`);

const updateDocument = async (url, data) => {
    const response = await PUT(url, data);
    return response?.status === "Success" ? response : null;
}

export const updateApproveDocument = (documentData) => updateDocument("ftm/approve/document", documentData);
export const updateExpiryDocument = (documentData) => updateDocument("ftm/documents/expiry-date", documentData);

export const deleteDocument = async ({ file_id, document_type }) => {
    const response = await DELETE(`ftm/document/file/${file_id}/${document_type}`);
    return response?.status === "Success" ? response : null;
}

export const fetchFtmDocumentDownload = ({ ftm_id, file_id, file_ext, document_type }) => {
    return DOWNLOADFILE(`ftm/documents/download/${ftm_id}/${file_id}/${document_type}`, file_ext);
}