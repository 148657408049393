import { theme } from "../../theme";

const iconWidth = "100px";
const iconHeight = "100px";
const iconViewBox = "0 -960 960 960";
const iconXmlns = "http://www.w3.org/2000/svg";

export const  alertIcons = {
    success: `<svg xmlns="${iconXmlns}" height="${iconHeight}" viewBox="${iconViewBox}" width="${iconWidth}" fill="${theme.main_code}"><path d="m421-298 283-283-46-45-237 237-120-120-45 45 165 166Zm59 218q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-156t86-127Q252-817 325-848.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82-31.5 155T763-197.5q-54 54.5-127 86T480-80Z"/></svg>`,
    error: `<svg xmlns="${iconXmlns}" height="${iconHeight}" viewBox="${iconViewBox}" width="${iconWidth}" fill="${theme.main_code}"><path d="M480-334q14.45 0 24.23-9.77Q514-353.55 514-368q0-14.45-9.77-24.23Q494.45-402 480-402q-14.45 0-24.23 9.77Q446-382.45 446-368q0 14.45 9.77 24.23Q465.55-334 480-334Zm-30-134h60v-207h-60v207Zm30 387q-140-35-230-162.5T160-523v-238l320-120 320 120v238q0 152-90 279.5T480-81Z"/></svg>`,
    warning: `<svg xmlns="${iconXmlns}" height="${iconHeight}" viewBox="${iconViewBox}" width="${iconWidth}" fill="${theme.main_code}"><path d="m40-120 440-760 440 760H40Zm444.18-117q12.82 0 21.32-8.68 8.5-8.67 8.5-21.5 0-12.82-8.68-21.32-8.67-8.5-21.5-8.5-12.82 0-21.32 8.68-8.5 8.67-8.5 21.5 0 12.82 8.68 21.32 8.67 8.5 21.5 8.5ZM454-348h60v-224h-60v224Z"/></svg>`,
    info: `<svg xmlns="${iconXmlns}" height="${iconHeight}" viewBox="${iconViewBox}" width="${iconWidth}" fill="${theme.main_code}"><path d="M453-280h60v-240h-60v240Zm26.98-314q14.02 0 23.52-9.2T513-626q0-14.45-9.48-24.22-9.48-9.78-23.5-9.78t-23.52 9.78Q447-640.45 447-626q0 13.6 9.48 22.8 9.48 9.2 23.5 9.2Zm.29 514q-82.74 0-155.5-31.5Q252-143 197.5-197.5t-86-127.34Q80-397.68 80-480.5t31.5-155.66Q143-709 197.5-763t127.34-85.5Q397.68-880 480.5-880t155.66 31.5Q709-817 763-763t85.5 127Q880-563 880-480.27q0 82.74-31.5 155.5Q817-252 763-197.68q-54 54.31-127 86Q563-80 480.27-80Z"/></svg>`,
    lock: `<svg xmlns="${iconXmlns}" height="${iconHeight}" viewBox="${iconViewBox}" width="${iconWidth}" fill="${theme.main_code}" ml-update="aware"><path d="M240-80q-33 0-56.5-23.5T160-160v-400q0-33 23.5-56.5T240-640h40v-80q0-83 58.5-141.5T480-920q83 0 141.5 58.5T680-720v80h40q33 0 56.5 23.5T800-560v400q0 33-23.5 56.5T720-80H240Zm0-80h480v-400H240v400Zm240-120q33 0 56.5-23.5T560-360q0-33-23.5-56.5T480-440q-33 0-56.5 23.5T400-360q0 33 23.5 56.5T480-280ZM360-640h240v-80q0-50-35-85t-85-35q-50 0-85 35t-35 85v80ZM240-160v-400 400Z"/><script xmlns=""/></svg>`
};

const confirmButtonColor = theme.cancel_btn_code;
const cancelButtonColor = theme.action_btn_code;

export const alertSettings = {
    OPEN_IMAGE_PREVIEW: {
        cancelButtonColor: cancelButtonColor
    },
    DELETE_CARD: {
        title: 'Are you sure?',
        text: 'Do you want to delete this card', 
        iconHtml: alertIcons['warning'] || null, 
        confirmButtonColor: confirmButtonColor,
        cancelButtonColor: cancelButtonColor,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
    },
    GET_JOBS_STATUS_LOGS: {
        title: 'Loading...',
        text: 'Please wait while we fetch the data.',
    },
    CONFIRM_FTM_HOLIDAY: {
        title: 'Holiday Approval',
        text: "Please confirm your decision regarding the holiday leave request?",
        confirmButtonColor: cancelButtonColor,
        cancelButtonColor: confirmButtonColor,
        confirmButtonText: 'Approve',
        denyButtonText: `Reject`
    },
    CANCEL_FTM_HOLIDAY: {
        title: 'Warning',
        text: "Are you sure you want to proceed?",
        iconHtml: alertIcons['warning'] || null,
        confirmButtonColor: confirmButtonColor,
        cancelButtonColor: cancelButtonColor,
        confirmButtonText: 'Yes',
        cancelButtonText: "No",
    },
    DELETE_FTM: {
        title: "Are you sure?",
        text: "Do you want to delete this FTM?",
        iconHtml: alertIcons['warning'] || null,
        confirmButtonColor: cancelButtonColor,
        cancelButtonColor: confirmButtonColor,
        confirmButtonText: "Yes, delete it!",
    },
    DELETE_NOTE: {
        title: 'Warning',
        text: "This note will be permanently deleted. Are you sure you want to proceed?",
        iconHtml: alertIcons['warning'] || null,
        confirmButtonColor: confirmButtonColor,
        cancelButtonColor: cancelButtonColor,
        confirmButtonText: 'Yes',
        cancelButtonText: "No",
    },
    APPROVE_FTM: {
        title: 'Warning',
        text: "This note will be permanently deleted. Are you sure you want to proceed?",
        iconHtml: alertIcons['warning'] || null,
        confirmButtonColor: confirmButtonColor,
        cancelButtonColor: cancelButtonColor,
        confirmButtonText: 'Yes',
        cancelButtonText: "No",
    },
    REJECT_FTM: {
        title: 'Why do you reject this Tradeperson? ',
        iconHtml: alertIcons['warning'] || null,
        input: 'textarea',
        inputAttributes: {
            autocapitalize: "off"
        },
        confirmButtonColor: confirmButtonColor,
        cancelButtonColor: cancelButtonColor,
        confirmButtonText: 'Yes',
        cancelButtonText: "No",
    },
    UPDATE_DEVICE: {
        title: "SET GPS DEVICE",
        input: "text",
        inputAttributes: {
            autocapitalize: "off"
        },
        confirmButtonColor: confirmButtonColor,
        cancelButtonColor: cancelButtonColor,
        confirmButtonText: "Submit",
    },
    DELETE_SKILL: {
        title: 'Warning',
        text: "Once deleted, the service type cannot be recovered. Please confirm deletion?",
        iconHtml: alertIcons['warning'] || null,
        confirmButtonColor: confirmButtonColor,
        cancelButtonColor: cancelButtonColor,
        confirmButtonText: 'Yes',
        cancelButtonText: "No",
    },
    REMOVE_DEVICE: {
        title: 'Are you sure?',
        text: "Do you want to remove this device",
        iconHtml: alertIcons['warning'] || null,
        confirmButtonColor: confirmButtonColor,
        cancelButtonColor: cancelButtonColor,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
    },
    APPROVE_SKILL: {
        title: 'Warning',
        text: "Approving this service type will allow the tradesperson to offer these services",
        iconHtml: alertIcons['warning'] || null,
        confirmButtonColor: cancelButtonColor,
        cancelButtonColor: confirmButtonColor,
        confirmButtonText: 'Approve',
        cancelButtonText: 'Cancel',
    },
    APPROVE_FTM_DOCUMENT: {
        title: 'Are you sure?',
        text: "Do you want to approve this certificate?",
        iconHtml: alertIcons['warning'] || null,
        confirmButtonColor: cancelButtonColor,
        cancelButtonColor: confirmButtonColor,
        confirmButtonText: 'Yes',
        cancelButtonText: "No",
    },
    FTM_TRACKER_DISPATCH: {
        title: "Are you sure tracker dispatch?",
        confirmButtonColor: theme.main_code,
        cancelButtonColor: theme.close_btn_code,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
    },
    DELETE_FTM_DOCUMENT: {
        title: 'Warning',
        text: "Deleting this certificate is permanent. Please proceed with caution",
        iconHtml: alertIcons['warning'] || null,
        confirmButtonColor: confirmButtonColor,
        cancelButtonColor: cancelButtonColor,
        confirmButtonText: 'Delete',
        cancelButtonText: "Cancel"
    },
    UPDATE_FTM_AVAILABILITY_ONLINE: {
        title: 'Are you sure?',
        text: "Tradesperson will not receive new job notifications until they come back online.",
        iconHtml: alertIcons['warning'] || null,
        confirmButtonColor: confirmButtonColor,
        cancelButtonColor: cancelButtonColor,
        confirmButtonText: 'Yes',
        cancelButtonText: "No"
    },
    UPDATE_FTM_AVAILABILITY_OFFLINE: {
        title: null,
        text: "The Tradesperson will start getting new job alerts from now on",
        iconHtml: alertIcons['warning'] || null,
        confirmButtonColor: confirmButtonColor,
        cancelButtonColor: cancelButtonColor,
        confirmButtonText: 'Yes',
        cancelButtonText: "No"
    },
    IS_REVIEW: {
        title: "Are you sure?",
        text: "Do you want to review this cancel job ?",
        iconHtml: alertIcons['warning'] || null,
        confirmButtonColor: cancelButtonColor,
        cancelButtonColor: confirmButtonColor,
        confirmButtonText: "Yes, review it!",
    },
    MARK_DISPUTE_RESOLVED: {
        title: 'Are you sure?',
        text: "Do you want to close this chat? The dispute will be marked as resolved.",
        iconHtml: alertIcons['warning'] || null,
        confirmButtonColor: cancelButtonColor,
        cancelButtonColor: confirmButtonColor,
        confirmButtonText: 'Yes',
    },
    REFUND_PAYMENT: {
        title: 'Are you sure?',
        text: "Do you want to refund payment",
        iconHtml: alertIcons['warning'] || null,
        input: 'text',
        inputAttributes: {
            autocapitalize: "off"
        },
        confirmButtonColor: cancelButtonColor,
        cancelButtonColor: confirmButtonColor,
        confirmButtonText: 'Yes, refund it!',
    },
    REFUND_PAYMENT_ERROR: {
        title: "ERROR"
    },
    HANDLE_DOCUMENT_ARCHIVE: {
        title: 'Are you sure you want to archive this message?',
        text: "It will be moved to the archive folder and will no longer appear in your inbox.",
        iconHtml: alertIcons['warning'] || null,
        confirmButtonColor: confirmButtonColor,
        cancelButtonColor: cancelButtonColor,
        confirmButtonText: 'Yes',
        cancelButtonText: "No",
    },
    DELETE_CERTIFICATE: {
        title: "Are you sure?",
        text: "Do you want to delete certificate ?",
        iconHtml: alertIcons['warning'] || null,
        confirmButtonColor: confirmButtonColor,
        cancelButtonColor: cancelButtonColor,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
    },
    DELETE_SKILLS: {
        title: "Are you sure?",
        text: "Do you want to delete this service category?",
        iconHtml: alertIcons['warning'] || null,
        confirmButtonColor: confirmButtonColor,
        cancelButtonColor: cancelButtonColor,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
    },
    DELETE_SUB_SKILLS: {
        title: "Are you sure?",
        text: "Do you want to delete this service type?",
        iconHtml: alertIcons['warning'] || null,
        confirmButtonColor: confirmButtonColor,
        cancelButtonColor: cancelButtonColor,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
    },
    DELETE_USER: {
        title: 'Are you sure?',
        text: "Do you want to delete this user",
        iconHtml: alertIcons['warning'] || null,
        confirmButtonColor: theme.delete_btn_code,
        cancelButtonColor: theme.close_btn_code,
        confirmButtonText: 'Yes, delete it!',
    },
    UPDATE_FTM_SKILLS_AND_DOCUMENTS: {
        title: 'Are you sure?',
        text: "Do you want to add documents.",
        iconHtml: alertIcons['warning'] || null,
        confirmButtonColor: theme.action_btn_code,
        cancelButtonColor: theme.close_btn_code,
        confirmButtonText: 'Yes',
        cancelButtonText: "No"
    },
    REMOVE_FTM_DOCUMENT: {
        title: 'Are you sure?',
        text: "Do you want to remove this document.",
        iconHtml: alertIcons['warning'] || null,
        confirmButtonColor: theme.action_btn_code,
        cancelButtonColor: theme.close_btn_code,
        confirmButtonText: 'Yes',
        cancelButtonText: "No"
    },
    REPLACE_FTM_DOCUMENT_FILE: {
        title: 'Are you sure?',
        text: "Do you want to replace this document.",
        iconHtml: alertIcons['warning'] || null,
        confirmButtonColor: theme.action_btn_code,
        cancelButtonColor: theme.close_btn_code,
        confirmButtonText: 'Yes',
        cancelButtonText: "No"
    },
    PRINT_LABEL: {
        title: 'Are you sure?',
        text: "Do you want to print labels.",
        iconHtml: alertIcons['info'] || null,
        confirmButtonColor: theme.action_btn_code,
        cancelButtonColor: theme.close_btn_code,
        confirmButtonText: 'Yes',
        cancelButtonText: "No"
    }
}