import React, { createContext, useEffect, useReducer } from "react";
import Reducer from "./reducer";
import { initialState } from "./initialState";
import { fetchCertificateHistory, fetchPersonalDocumentHistory } from "./service";

const Context = createContext();

const Provider = ({ ftm_id, document_type_id, document_type, children }) => {
    const isPersonal = document_type === "personal";
    const [state, dispatch] = useReducer(Reducer, initialState); 

    useEffect(() => {
        const fetchData = async () => {
            try {
                if(document_type_id !== undefined){
                    dispatch({ type: "LOADING" });
                    const fetchFunction = isPersonal ? fetchPersonalDocumentHistory : fetchCertificateHistory;
                    const response = await fetchFunction({ftm_id, document_type_id});
        
                    dispatch({ type: "LOADED", history: response?.history });
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [ftm_id, document_type_id, document_type, isPersonal]);

    return (
        <Context.Provider 
            value={{ 
                ftm_id,
                document_type_id, 
                document_type, 
                state
            }}
        >
            {children}
        </Context.Provider>
    );
};

export { Context, Provider };