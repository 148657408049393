import React from "react";

import Html from "./Html";
import { Provider } from "./Provider";

const Documents = ({ ftm_id, document_type }) => {
    return(
        <Provider
            ftm_id = {ftm_id}
            document_type = {document_type}
            children = {<Html/>}
        />
    )
} 

export default Documents;