import React, { useContext, useEffect, useRef, useState } from 'react';
import { Context } from '../Provider';

const ListFileUpload = ({ skill_id, documents_types_id, sub_skill_id = null }) => {
    const [files, setFiles] = useState([]);
    const fileInputRef = useRef(null); // Reference to the hidden file input

    const context = useContext(Context);
    const { handelSetSkillFiles, handelSetSubSkillFiles } = context;

    useEffect(() => {
        console.log("ListFileUpload useEffect files : ", files);
        if (files.length > 0) {
            if (sub_skill_id == null) {
                handelSetSkillFiles(skill_id, documents_types_id, files);
            } else {
                handelSetSubSkillFiles(skill_id, sub_skill_id, documents_types_id, files);
            }
        }
    }, [files]);

    const handleDrop = (event) => {
        event.preventDefault();
        const newFiles = [...files];
        Array.from(event.dataTransfer.files).forEach((file) => {
            newFiles.push({
                file,
                preview: isImageFile(file) ? URL.createObjectURL(file) : null,
            });
        });
        setFiles(newFiles);
    };

    const handleFileInput = (event) => {
        const newFiles = [...files];
        Array.from(event.target.files).forEach((file) => {
            newFiles.push({
                file,
                preview: isImageFile(file) ? URL.createObjectURL(file) : null,
            });
        });
        setFiles(newFiles);
    };

    const isImageFile = (file) => {
        return file.type.startsWith("image/");
    };

    const removeFile = (index, event) => {
        event.stopPropagation(); // Stop the event from propagating to the drop zone
        const newFiles = files.filter((_, i) => i !== index);
        setFiles(newFiles);
    };

    const preventDefaults = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleClick = () => {
        fileInputRef.current.click(); // Trigger file input click when drop area is clicked
    };

    return (
        <div>
            <div
                onDragEnter={preventDefaults}
                onDragOver={preventDefaults}
                onDrop={handleDrop}
                onClick={files.length ? undefined : handleClick} // Add click handler to trigger file input
                style={{
                    width: '100%',
                    height: '100px',
                    border: '2px dashed gray',
                    borderRadius: '10px',
                    display: 'flex',
                    // flexDirection: 'column',
                    justifyContent: files.length ? 'flex-start' : 'center',
                    alignItems: 'center',
                    padding: '10px',
                    gap: '10px',
                    cursor: 'pointer', // Indicate clickable area
                }}
            >
                {!files.length && <p style={{ marginBottom: '0px' }}>Drag & drop files here, or click to select files</p>}
                <input
                    type="file"
                    multiple
                    onChange={handleFileInput}
                    style={{ display: 'none' }}
                    ref={fileInputRef} // Assign the input to the ref
                />

                <div style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '10px',
                    maxHeight: '100%',
                    overflowY: 'auto',
                }}>
                    {files.map((fileObj, index) => (
                        <div key={index} style={{ position: 'relative', width: '65px', height: '70px' }}>
                            {fileObj.preview ? (
                                <img
                                    src={fileObj.preview}
                                    alt={`Preview ${index}`}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                        borderRadius: '5px',
                                    }}
                                />
                            ) : (
                                <div 
                                    title={fileObj.file.name}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        borderRadius: '5px',
                                        backgroundColor: '#f0f0f0',
                                        fontSize: '40px',
                                        color: 'gray',
                                    }}
                                >
                                📄
                                </div>
                            )}
                            <button
                                onClick={(event) => removeFile(index, event)} // Pass event to stop propagation
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    right: 0,
                                    backgroundColor: 'red',
                                    color: 'white',
                                    border: 'none',
                                    cursor: 'pointer',
                                    borderRadius: '50%',
                                    width: '20px',
                                    height: '20px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    fontSize: '12px'
                                }}
                            >
                                X
                            </button>
                        </div>
                    ))}

                    {/* "+" icon box to add more images */}
                    {files.length > 0 &&
                        <div
                            onClick={handleClick} // Trigger file input when "+" box is clicked
                            style={{
                                width: '65px',
                                height: '70px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: '5px',
                                // border: '2px dashed gray',
                                cursor: 'pointer',
                                fontSize: '24px',
                                color: 'gray',
                                backgroundColor: '#e8e6e6'
                            }}
                        >
                            +
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default ListFileUpload;


