import React, { useContext, useState } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';

import Context from "../context";
import DivSpinner from "../../../components/divSpinner";
import { theme } from "../../../theme";
import { deleteUser } from "../service";
import ModalTemplate from "../../../components/modal/modalTemplate";
import Edit from "./edit";

const Table = () => {
    const [adminData, setAdminData] = useState({
        show: false,
        id: null,
        name: '',
        phone: '',
    });

    const { state: { list, is_loading }, dispatch } = useContext(Context);

    const handleClose = () => setAdminData({ show: false, id: null, name: '', phone: '' });
    const handleShow = (id, name, phone) => setAdminData({ show: true, id, name, phone });

    const handleSubmitClose = () => {
        handleClose();
        dispatch({ type: 'SET_REFRESH_COUNT' });
    }

    const actions = [
        {
            name: "Edit",
            icon: <EditIcon />,
            handleClick: (user) => handleShow(user.admin_id, user.admin_full_name, user.admin_contact_number),
        },
        {
            name: "Delete",
            icon: <DeleteIcon />,
            handleClick: (user) => deleteUser(user.admin_id, dispatch),
        },
    ];

    const columns = [
        { label: '#', key: 'admin_id' },
        { label: 'Full Name', key: 'admin_full_name' },
        { label: 'Contact Number', key: 'admin_contact_number' },
        { label: 'Email', key: 'admin_email' },
        { label: 'Created', key: 'admin_createdAt' },
        { label: 'Status', key: 'admin_status', format: (value) => (value === "Verify" ? "VERIFIED" : "UNVERIFIED") },
    ];

    return (
        <>
            {is_loading ? (
                <div className="spinner-container">
                    <DivSpinner />
                </div>
            ) : (
                <div className="table-responsive p-0">
                    <table className="table align-items-center mb-0">
                        <thead>
                            <tr>
                                {columns.map((col, index) => (
                                    <th
                                        key={index}
                                        className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7"
                                    >
                                        {col.label}
                                    </th>
                                ))}
                                <th className="text-secondary opacity-7"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {list.map((user, rowIndex) => (
                                <tr key={user.admin_id || rowIndex}>
                                    {columns.map((col, colIndex) => (
                                        <td className="align-middle" key={colIndex}>
                                            <span className="text-secondary text-sm font-weight-bold">
                                                {col.format ? col.format(user[col.key]) : user[col.key]}
                                            </span>
                                        </td>
                                    ))}
                                    <td className="align-middle">
                                        {actions.map((action, actionIndex) => (
                                            <IconButton
                                                key={actionIndex}
                                                color={theme.delete_btn}
                                                onClick={() => action.handleClick(user)}
                                            >
                                                {action.icon}
                                            </IconButton>
                                        ))}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
            <ModalTemplate
                show={adminData.show}
                closeFun={handleClose}
                size={null}
                centered={true}
                title="Edit User"
                body={
                    <Edit
                        admin_id={adminData.id}
                        admin_full_name={adminData.name}
                        admin_contact_number={adminData.phone}
                        handleClose={handleSubmitClose}
                    />
                }
                footer={null}
            />
        </>
    );
};

export default Table;