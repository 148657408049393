import { useCallback, useContext, useEffect, useRef, useState } from "react";

import { Context } from "../provider";
import { createMarkerContent, loadScript, map_settings } from "../../../map";
import { formatTimestampInMap, textReplace } from "../../../utils";

export const Map = () => {
    const [mapLoaded, setMapLoaded] = useState(false);
    const mapRef = useRef(null);
    const mapInstance = useRef(null); // Store the map instance
    const boundsRef = useRef(null); // Store the bounds instance
    const markersRef = useRef([]); // Store markers to avoid re-creating markers

    const context = useContext(Context);
    const { state } = context;
    const { details, selected_job } = state;
    const { job_status_change_logs } = selected_job;

    // Load the Google Maps script
    useEffect(() => {
        loadScript(
            map_settings.map_script_url,
            () => setMapLoaded(true),
            () => console.error("Failed to load Google Maps script.")
        );
    }, []);

    // Initialize the map
    const initializeMap = useCallback(() => {
        if (mapInstance.current || !window.google || !window.google.maps) {
            return;
        }

        mapInstance.current = new window.google.maps.Map(mapRef.current, {
            center: { lat: 51.5286416, lng: -0.1015987 },
            zoom: 10,
            mapId: "FTM_LIVE_MAP"
        });

        boundsRef.current = new window.google.maps.LatLngBounds();
    }, []);

    // Remove all markers
    const removeAllMarkers = () => {
        if (markersRef.current && markersRef.current.length > 0) {
            markersRef.current.forEach(marker => {
                marker.setMap(null); // Removes the marker from the map
            });
        }
    }

    // Add markers to the map
    const addMarkers = useCallback(() => {
        if (!mapInstance.current || !job_status_change_logs) return;

        removeAllMarkers();
        
        job_status_change_logs.forEach(log => {
            const title = textReplace({
                text: log.job_status.job_status_name, 
                search: '[[FTM]]', 
                replace: `${details?.ftm?.ftm_forename} ${details?.ftm?.ftm_surname}`
            });

            const infowindow = new window.google.maps.InfoWindow({
                content:`<div className="d-flex">
                            <div className="ms-3">
                                <div className="numbers">
                                    <h6 className="mb-1 text-dark text-sm">
                                        <i className="material-icons  me-2" style="color: ${log.job_status.job_status_color}; font-size: 25px;">${log.job_status.job_status_icon}</i> 
                                        ${title}
                                    </h6>
                                    <span className="text-sm">
                                        ${formatTimestampInMap(log.job_status_change_log_timestamp)}
                                    </span>
                                </div>
                            </div>
                        </div>`,
                ariaLabel: title,
            }); 

            const ftmLatLng = new window.google.maps.LatLng(log.job_status_change_log_latitude, log.job_status_change_log_longitude);
            boundsRef.current.extend(ftmLatLng);

            const marker = new window.google.maps.marker.AdvancedMarkerElement({
                position: ftmLatLng,
                content: createMarkerContent(map_settings.ftm_map_icon),
                map: mapInstance.current,
                title: `${title}`,
            });

            marker.addListener("click", () => {
                infowindow.open({
                    anchor: marker,
                    map: mapInstance.current
                });
            });

            markersRef.current.push(marker);
        });

        mapInstance.current.fitBounds(boundsRef.current);
        mapInstance.current.setZoom(10); // Set zoom once after bounds are set
    }, [job_status_change_logs]);

        // Effect for initializing the map and adding markers
        useEffect(() => {
            if (mapLoaded) {
                initializeMap();
                addMarkers();
            }
        }, [mapLoaded, initializeMap, addMarkers]);

    return (
        <div className="card mt-4" id="basic-info">
            <div className="card-header">
                <h5>VEHICLE HISTORY</h5>
            </div>
            <div className="card-body pt-0">
                <div ref={mapRef} style={{ width: '100%', height: 800 }} />
            </div>
        </div>
    )
}