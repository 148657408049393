import React, { useContext } from "react";

import CardWithHeaderTemplate from "../../../../components/cardWithHeaderTemplate";
import { Context } from "./Provider";
import DocumentsTable from "../common/DocumentsTable";
import PaginationView from "../common/PaginationView";

const Html = () => {
    const context = useContext(Context);
    const { isCertificates } = context;

    return (
        <CardWithHeaderTemplate
            header={<HeaderTitle title={isCertificates ? "CERTIFICATES" : "DOCUMENTS"} />}
            body={
                <DocumentBody 
                    renderDocuments={
                        <DocumentsTable/>
                    } 
                />
            }
            footer={<PaginationView/>}
            extraCardClassName="blur shadow-blur max-height-vh-70 h-100"
            extraBodyClassName="overflow-auto overflow-x-hidden"
        />
    );
};

const HeaderTitle = ({ title }) => (
    <div className="row">
        <div className="col-8 my-auto">
            <div className="numbers">
                <h6 className="text-white font-weight-bolder mb-0 ms-3">{title}</h6>
            </div>
        </div>
    </div>
);

const DocumentBody = ({ renderDocuments }) => (
    <div className="row">
        <div className="row">
            <ul className="list-group">
                {renderDocuments}
            </ul>
        </div>
    </div>
);

export default Html;