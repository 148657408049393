export const certificatesTableHeader = [
    {
       name: "DOCUMENT",
       align: "left", 
    },
    {
        name: "TRADEPERSON",
        align: "left",
    },
    {
        name: "EXPIRED",
        align: "left",
    },
    {
        name: "DAYS",
        align: "left",
    }, 
    {
        name: "SERVICE CATEGORIES",
        align: "left",
    },
    // {
    //     name: "Action",
    //     align: "center",
    // },
];