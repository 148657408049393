import React, { useContext, useState } from "react";
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import { IconButton } from "@mui/material";

import DivSpinner from "../../../components/divSpinner";
import { Context } from "./Provider";
import ModalTemplate from "../../../components/modal/modalTemplate";
import { theme } from "../../../theme";
// import Gallery from "../components/Gallery";
import HistoryList from "./components/HistoryList";

const Html = () => {
    const context = useContext(Context);
    const { state } = context;  // Destructure fetchData function
    const { loading } = state;

    const [show, setShow] = useState(false);

    const handleShow = async () => {
        // await fetchData(); // Fetch data before showing modal
        setShow(true);
    };
    
    const handleClose = () => setShow(false);

    if(loading){
        <DivSpinner />
    }

    return (
        <>
            <IconButton 
                variant={theme.set_expired_color} 
                onClick={handleShow}
            >
                <WorkHistoryIcon/>
            </IconButton>

            <ModalTemplate
                show={show}
                closeFun={handleClose}
                size={null}
                centered={true}
                title={"HISTORY"}
                body={<HistoryList/>}
                footer={null}
            />
        </>
    );
};

export default Html;