import React, { useContext, useState } from "react";
import AutoDeleteIcon from '@mui/icons-material/AutoDelete';

import DivSpinner from "../../../components/divSpinner";
import { Context } from "./Provider";
import ModalTemplate from "../../../components/modal/modalTemplate";
import { IconButton } from "@mui/material";
import { theme } from "../../../theme";
import Gallery from "../components/Gallery";

const Html = () => {
    const context = useContext(Context);
    const { state, fetchData } = context;  // Destructure fetchData function
    const { loading } = state;

    const [show, setShow] = useState(false);

    const handleShow = async () => {
        await fetchData(); // Fetch data before showing modal
        setShow(true);
    };
    
    const handleClose = () => setShow(false);

    return (
        <>
            <IconButton 
                variant={theme.set_expired_color} 
                onClick={handleShow}
            >
                <AutoDeleteIcon/>
            </IconButton>

            <ModalTemplate
                show={show}
                closeFun={handleClose}
                size={null}
                centered={true}
                title={"DELETED DOCUMENTS"}
                body={loading ? <DivSpinner /> : <Gallery context={context} isFtmDocumentView={false}/>}
                footer={null}
            />
        </>
    );
};

export default Html;