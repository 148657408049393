import React, { useContext } from "react";
import Tooltip from '@mui/material/Tooltip';

import { Context } from "../provider";

const Header = () => {
    const context = useContext(Context);

    return (
        <div className="row">
            <div className="col-md-9 col-lg-10">
                <div className="d-flex align-items-center">
                    <div className="ms-3">
                        <h6 className="mb-0 d-block text-white">
                            {context.state.selected_dispute?.dispute_details.type || "Not selected dispute"}
                        </h6>
                        <span className="text-sm text-white opacity-8">
                            {`JOB # ${context.state.selected_dispute?.dispute_job?.job_id} DISP # ${context.state.selected_dispute?.dispute_id}` || "Empty" }
                        </span>
                    </div>
                </div>
            </div>

            <div className="col-1 my-auto">
                {context.state.selected_dispute !== null && (
                    <div className="row">
                        <div className="col-md-6 col-lg-4" style={{marginRight: '10px'}}>
                            <Tooltip title={context.state.selected_dispute?.dispute_archive ? "Unarchive Chat" : "Archive Chat"} arrow>
                                <button
                                    className="btn btn-icon-only text-white mb-0 me-3 me-sm-0"
                                    type="button"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title=""
                                    data-bs-original-title="Video call"
                                    onClick={
                                        () => context.handleDocumentArchive(context.state.selected_dispute)
                                    }
                                >
                                    <i className="material-icons" style={{fontSize: '25px'}}>{context.state.selected_dispute?.dispute_archive ? "unarchive" : "archive"}</i>
                                </button>
                            </Tooltip>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <Tooltip title="View" arrow>
                                <button
                                    className="btn btn-icon-only text-white mb-0 me-3 me-sm-0"
                                    type="button"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title=""
                                    data-bs-original-title="Video call"
                                    onClick={() => context.dispatch({
                                        type: 'IS_SHOW_DISPUTE_INFO',
                                        is_show_dispute_info: true
                                    })}
                                >
                                    <i className="material-icons" style={{fontSize: '25px'}}>visibility</i>
                                </button>
                            </Tooltip>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Header;