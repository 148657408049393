import { DOWNLOADFILE, GET } from "../../../API/get";
import { FORMPOST } from "../../../API/post";

export const fetchData = async ({ftm_id}) => {
    const response = await GET(`ftm/attachment/${ftm_id}`);
    if(response.status === "Success"){
        return response;
    }

    return null;
}

export const downloadFtmAttachment = async (id, file_ext) => {
    await DOWNLOADFILE(`ftm/attachment/download/${id}`, file_ext);
}

export const sendFtmAttachment = async ({ftm_id, data, attachment}) => {
    const response = await FORMPOST("ftm/attachment", {
        ftm_id: ftm_id,
        title: data.title,
        description: data.description,
        attachment: attachment
    });

    if(response.status === "Success"){
        return response;
    }

    return null;
}