import React, { useContext } from "react";
import Tooltip from '@mui/material/Tooltip';
import {useNavigate} from "react-router-dom";

import Context from "../context";
import DivSpinner from "../../../components/divSpinner";
import { getStatusColor, getStatusText} from "../service";

import VisibilityIcon from '@mui/icons-material/Visibility';
import ColumnSort from "./columnSort";

const Table = () => {
    const context = useContext(Context);
    let navigate = useNavigate();

    return(
        <>
            {context.state.is_loading ? (
                <div style={{height : `${window.innerHeight-387}px`}}>
                    <DivSpinner/>
                </div>
            ) : (
                <div className="table-responsive p-0">
                    <table className="table align-items-center mb-0">
                        <thead>
                            <tr>
                                {[
                                    'Customer', 
                                    'Contact Number',
                                    'Email', 
                                    'Status'
                                ].map((text, index) => (
                                    <th 
                                        key={index}
                                        className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7"
                                    >
                                        <ColumnSort sortKeyName={text} />
                                    </th>
                                ))}
                                <th className="text-secondary opacity-7"></th>
                            </tr>
                        </thead>
                        <tbody>
                        {context.state.list.length > 0 ? (
                            <>
                                {context.state.list.map((customer, index) => (
                                    <tr key={index}>
                                        <td>
                                            <div className="d-flex px-2 py-1">
                                                <div>
                                                    <img src={customer.customer_profile_image || "/assets/img/lock_user.png"} className="avatar avatar-sm me-3 border-radius-lg" alt="user1"/>
                                                </div>
                                                <div className="d-flex flex-column justify-content-center">
                                                    <h6 className="mb-0 text-sm">{customer.customer_full_name}</h6>
                                                    <p className="text-xs text-secondary mb-0">Created {customer.customer_createdAt}</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="align-middle">
                                            <span className="text-secondary text-sm font-weight-bold">{customer.customer_contact_number}</span>
                                        </td>
                                        <td className="align-middle">
                                            <span className="text-secondary text-sm font-weight-bold">{customer.customer_email}</span>
                                        </td>
                                        <td className="align-middle text-sm">
                                            <span 
                                                className={`badge badge-sm bg-gradient-${getStatusColor(customer.customer_status)}`}
                                            >
                                                {getStatusText(customer.customer_status)}
                                            </span>
                                        </td>
                                        <td className="align-middle">
                                            <Tooltip title="View" arrow>
                                                <VisibilityIcon 
                                                    style={{cursor: 'pointer'}}
                                                    onClick={() => navigate(`/customers/view/${customer?.customer_id}`)} 
                                                />
                                            </Tooltip>
                                        </td> 
                                    </tr>
                                ))}
                            </>
                        ) : (
                            <tr>
                                <td colSpan="5">Sorry, no data found!</td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            )}
        </>
    );
}

export default Table;