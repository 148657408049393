export default function Reducer(prevState, action){
    switch (action.type) {
        case "LOADING":
            return {
                ...prevState,
                loading: true,
            };
        case "LOADED": 
            return {
                ...prevState,
                data: action.data,
                loading: false,
            };
        case "SELECT_DOCUMENT":
            return {
                ...prevState,
                selectedDocument: action.selectedDocument
            }; 
        case "SET_SELECTED_DOCUMENT": 
            return {
                ...prevState,
                selectedDocument: action.document
            };
        case "REFRESHING":
            return {
                ...prevState,
                refreshingCount: prevState.refreshingCount + 1
            };
        case "LOADING_DOCUMENT": 
            return {
                ...prevState,
                loadingDocument: true
            };
        case "LOADED_DOCUMENT": 
            return {
                ...prevState,
                document: action.document,
                loadingDocument: false
            };
        default:
            console.log("action.type: ", action.type)
            throw new Error();
    }
}