import React, { useContext } from "react";
import IconButton from '@mui/material/IconButton';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { theme } from "../../../theme";
import { Context } from "../Provider";

const TrackerDispatch = () => {
    const context = useContext(Context);
    const { state, ftmTrackerDispatch } = context;
    const { ftm } = state;
    const { ftm_is_verify, ftm_gps_tracker_is_dispatch } = ftm;

    return (
        <div className="col-md-12 mb-lg-0 mb-4">
            <div className="card card-plain">
                <div className="card-body p-3">
                    <div className="row">
                        <div className="col-md-12 mb-md-0 mb-4">
                            <div 
                                className="card card-body border card-plain border-radius-lg d-flex align-items-center flex-row"
                                style={{
                                    paddingTop: '14px',
                                    paddingBottom: '14px',
                                    paddingLeft: '24px',
                                    paddingRight: '20px'
                                }}
                            >
                                <img className="w-8 me-3 mb-0" src="/assets/img/trackerDispatchenew.png" alt="logo"/>
                                <h5 className="mb-0">Tracker dispatched</h5>
                                <div className="d-flex flex-column ms-auto">
                                    {ftm_gps_tracker_is_dispatch === 1 ? (
                                        <CheckCircleIcon sx={{color: theme.main_code}}/>
                                    ) : (
                                        <IconButton 
                                            aria-label="TrackerDispatch" 
                                            color="danger" 
                                            onClick={() => ftmTrackerDispatch()}
                                            disabled = {ftm_is_verify === -2}
                                        >
                                            <DoDisturbOnIcon />
                                        </IconButton>
                                    )}
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TrackerDispatch;