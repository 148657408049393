import { DOWNLOADFILE } from "../../../API/get";
import { FORMPUT } from "../../../API/put";

export const fetchFtmDocumentDownload = ({ ftm_id, file_id, file_ext, document_type }) => {
    return DOWNLOADFILE(`ftm/documents/download/${ftm_id}/${file_id}/${document_type}`, file_ext);
}

export const replaceDocument = async ({ ftm_id, file_id, file_type, ftm_document }) => {
    const response = await FORMPUT(`replace/ftm/documents`,{
        ftm_id,
        file_id,
        file_type,
        ftm_document
    });
    return response?.status === "Success" ? response : null;
}