import React, { useContext } from "react";
import { useForm } from "react-hook-form";

import Button from '@mui/material/Button';

import Form from 'react-bootstrap/Form';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import { saveNote } from "../service";
import Context from "../context";
import { theme } from "../../../../theme";

const Footer = ({ftm_id}) => {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm()

    const context = useContext(Context);

    return(
        <div className="offcanvas-footer">
            <div className="container">
                <div className="row" style={{marginTop: '10px'}}>
                    <div className="col">
                        <div className="d-grid gap-2 mb-3" >
                            <Form onSubmit={handleSubmit((data) => saveNote(data, ftm_id, context.dispatch, reset))} style={{textAlign: 'center'}} >
                                <FormControl error={errors.note} fullWidth className="mb-3">
                                    <TextField
                                        error={errors.note}
                                        placeholder="Enter your note"
                                        size="small"
                                        type="text"
                                        multiline
                                        rows={4}
                                        InputProps={{
                                            style: { backgroundColor: '#fff' },
                                        }}
                                        {...register("note", { required: true })}
                                    />
                                    {errors.note && <FormHelperText>Note is required</FormHelperText>}
                                </FormControl>

                                <Button type="submit" variant="contained" color={theme.main} fullWidth>SAVE</Button>
                            </Form>
                        </div>          
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;