import React from "react";

import Html from "./Html";
import { Provider } from "./Provider";

const FtmView = () => {
    return(
        <Provider>
            <Html/>
        </Provider>
    )
} 

export default FtmView;