import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';

import { theme } from './theme';
import { routes } from './routes';
import { disputesRef, jobsRef, usersRef } from './firebase';
import { PUT } from './API/put';

export const calHourlyRate = (skill_value, skill_company_commission, time_window) => {
    let sum = skill_value * Math.pow(0.95, time_window) * ((100 + skill_company_commission) / 100);
    return sum.toFixed(2);
};

export const diffMinutes = (time) => {
    let today = new Date();
    let start_time = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate() + " " + time;
    let diff = (new Date(start_time).getTime() - new Date().getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff)) < 15 ? 15 : Math.abs(Math.round(diff));
}

export const formatEtaTime = (timestamp) => {
    let newTime = new Date(parseInt(timestamp));
    return String(newTime.getHours()).padStart(2, '0') + ":" + String(newTime.getMinutes()).padStart(2, '0');
}

export const timeConvert = (n, extra_time_payment) => {
    let num = n;
    console.log('timeConvert num :', num);
    let hours = (num / 60);
    let rhours = Math.floor(hours);
    let minutes = (hours - rhours) * 60;
    let rminutes = Math.round(minutes);

    return (
        <div className="card">
            <div className="card-body ">
                <div className="row text-center">
                    <div className="col-6 border-end border-light">
                        <h6 className="text-muted mt-1 mb-2 fw-normal">Hour(s)</h6>
                        <h6 className="mb-0 fw-bold">{rhours}</h6>
                    </div>
                    <div className="col-6">
                        <h6 className="text-muted mt-1 mb-2 fw-normal">Minute(s)</h6>
                        <h6 className="mb-0 fw-bold">{rminutes}</h6>
                    </div>
                </div>

                <hr />
                <p style={{ marginBottom: '0px' }}>
                    Extra Hours Total : {theme.currency} {extra_time_payment?.toFixed(2)}
                </p>
            </div>
        </div>
    );
}

export const materialSubTotal = (item) => {
    let sub_total = 0;
    if (item.request_material_commission > 0) {
        sub_total = Number(item.request_material_unit_price) * Number(item.request_material_commission);
    } else {
        sub_total = Number(item.request_material_unit_price);
    }

    return sub_total?.toFixed(2);
}

export const materialTotal = (item) => {
    let total = 0;
    if (item.request_material_commission > 0) {
        total = Number(item.request_material_qty) * Number(item.request_material_unit_price) * Number(item.request_material_commission);
    } else {
        total = Number(item.request_material_qty) * Number(item.request_material_unit_price)
    }

    return total?.toFixed(2);
}

export const fullDateFormat = (timestamp) => {
    function pad2(n) { return n < 10 ? '0' + n : n }

    let date = new Date(parseInt(timestamp));
    return date.getFullYear().toString() + "-" + pad2(date.getMonth() + 1) + "-" + pad2(date.getDate()) + " " + pad2(date.getHours()) + ":" + pad2(date.getMinutes()) + ":" + pad2(date.getSeconds())
}

export const moneyFormat = (value) => {
    return (Number(value))?.toFixed(2);
}

export const totalCost = (job_details) => {
    let total = Number(job_details.job_ftm_hourly_rate) + Number(job_details.full_total_material_price) + Number(job_details.full_extra_time_payment);
    return moneyFormat(total);
}

export const localeDateStringFormat = (timestamp) => {
    let options = {
        day: "numeric",
        month: "long",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
    };

    let date = new Date(parseInt(timestamp));
    return new Intl.DateTimeFormat("en-US", options).format(date);
};

export const getPaymentStatus = (payment_status) => {
    switch (payment_status) {
        case 1:
            return {
                status: "PRE AUTH",
                icon: 'priority_high',
                text_color: 'dark'
            }
        case 2:
            return {
                status: "CHARGE",
                icon: 'expand_less',
                text_color: 'success'
            }
        case 3:
            return {
                status: "RELEASE",
                icon: 'expand_more',
                text_color: 'danger'
            }
        case 4:
            return {
                status: "REFUND",
                icon: 'expand_more',
                text_color: 'danger'
            }
        default:
            return {
                status: "N/A",
                icon: 'expand_more',
                text_color: 'danger'
            }
    }
}

export const getCardTypeImage = (card_type) => {
    if (card_type === "VISA") {
        return <img className="w-10 mb-0" src="/assets/img/logos/visa.png" alt="logo" />
    } else {
        if (card_type === "VISA") {
            return <img className="w-10 mb-0" src="/assets/img/logos/visa.png" alt="logo" />
        }
    }
}

export const truncateText = (text, maxLength) => {
    const truncatedText = text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
    return (
        <Tooltip title={text} arrow placement="right">
            <span>{truncatedText}</span>
        </Tooltip>
    );
};

export const formatDateString = (dateString) => {
    if (!dateString) return ''; // Handle case where dateString is not provided

    const dateObject = new Date(dateString);
    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1; // Month is zero-based
    const year = dateObject.getFullYear();

    // Pad day and month with leading zero if needed
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    return `${formattedDay}/${formattedMonth}/${year}`;
};

export const convertTimestampToFormat = (timestamp) => {
    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    let date = new Date(parseInt(timestamp));
    let month = months[date.getMonth()];
    let day = date.getDate();
    let year = date.getFullYear();

    let formattedDate = `${month}, ${day}, ${year}`;

    return  timestamp === null ? 'N/A' : formattedDate;
}

export const textReplace = ({ text, search, replace }) => {
    let result = text.replace(search, replace);
    return result;
}

export const findMatchingRoute = (givenPath) => {
    const predefinedRoutes = {
        "/jobs/complied/paid": "Completed jobs paid",
        "/jobs/complied/notpaid": "Completed jobs not paid",
        "/jobs/complied/indispute": "Completed jobs in dispute"
    };
    
    if (predefinedRoutes[givenPath]) {
        return predefinedRoutes[givenPath];
    } else {
        const matchingRoute = routes.find(route => {
            const regexPattern = new RegExp(`^${route.path.replace(/:\w+/g, '\\d+')}$`);
            return regexPattern.test(givenPath);
        });
    
        return matchingRoute ? matchingRoute.page_name : "No matching route found";
    }
}


export const convertToMillions = (amount) => {
    const new_amount = Number(amount);

    if (amount < 1000000) {
        return new_amount?.toFixed(2);
    } else {
        const amountInMillions = (new_amount / 1000000)?.toFixed(2) + " M";
        return <Tooltip title={amount} arrow placement="right">{amountInMillions}</Tooltip>;
    }
}

export const formatTimestampInMap = (timestamp) => {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    const date = new Date(parseInt(timestamp));
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    let hour = date.getHours();
    const minute = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
    const ampm = hour >= 12 ? 'PM' : 'AM';

    // Convert hour to 12-hour format
    hour = hour % 12;
    hour = hour ? hour : 12;

    const formattedDate = day + ' ' + month + ' ' + year + ', at ' + hour + ':' + minute + ' ' + ampm;

    return formattedDate;
}

export const getFirebaseJob = async (job_id) => {
    let job = {};
    
    const queryJobSnapshot = await jobsRef.where('job_id', '==', parseInt(job_id)).get();
    queryJobSnapshot.forEach((doc) => {
        if (doc.exists) {
            console.log("getFirebaseJob doc :", { 
                doc_id: doc.id,
                ...doc.data()
            });

            job = {
                doc_id: doc.id,
                ...doc.data()
            }
        }
    });

    return job;
};

export const getFirebaseDisputes = async (job_id) => {
    let dispute = {};

    const queryDisputesSnapshot = await disputesRef.where('dispute_job.job_id', '==', parseInt(job_id)).get();
    queryDisputesSnapshot.forEach((doc) => {
        if (doc.exists) {
            console.log("getFirebaseDisputes doc :", {
                id: doc.id,
                ...doc.data()
            });

            dispute = {
                id: doc.id,
                ...doc.data()
            }
        }
    });

    return dispute;
}

export const getFirebaseUsers = async (user_id) => {
    let user = {};

    const queryUsersSnapshot = await usersRef.where('user_id', '==', parseInt(user_id)).get();
    queryUsersSnapshot.forEach((doc) => {
        if (doc.exists) {
            console.log("getFirebaseUsers doc :", {
                doc_id: doc.id,
                ...doc.data()
            });

            user = {
                doc_id: doc.id,
                ...doc.data()
            }
        }
    });

    return user;
}

export const isShowChatNotifications = async (job_id, message_type) => {
    PUT("admin/show/notification", {
        job_id: job_id,
        message_type: message_type
    });
}

export const isImageFile = (filePath) => {
    const fileExtension = filePath.split('.').pop().toLowerCase();
  
    switch (fileExtension) {
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'gif':
            return true;
        default:
            return false;
    }
}

export const convertMinutesToHMMS = (minutes) => {
    const duration = moment.duration(minutes, 'minutes');
    const hours = Math.floor(duration.asHours());
    const mins = Math.floor(duration.minutes());
    const secs = Math.floor(duration.seconds());
  
    return `${hours}:${mins < 10 ? '0' : ''}${mins}:${secs < 10 ? '0' : ''}${secs}`;
};

/**
 * Retrieves the sort key for a given table column name.
 * @param {string} name - The name of the table column.
 * @returns {string} The corresponding sort key for the given column name.
 */
export const tableSortKeys = (name, type) => {
    let sortKey = '';

    switch (name) {
        case 'Job ID':
            sortKey = 'job_id';
            break;
        case 'Tradesperson':
            sortKey = 'ftm_full_name';
            break;
        case 'Customer':
            sortKey = 'customer_full_name';
            break;
        case 'Description':
            sortKey = 'job_description';
            break;
        case 'Address':
            sortKey = (type === 'job') ? 'job_address' : 'ftm_address';
            break;
        case 'Postcode':
            sortKey = 'job_postcode';
            break;
        case 'Service Categories':
            sortKey = 'skill_name';
            break;
        case 'Service Types':
            sortKey = 'sub_skill_name';
            break;
        case 'Job Created Date':
            sortKey = 'job_createdAt';
            break;
        case 'Job type':
            sortKey = 'job_type';
            break;
        case 'Payment Type':
            sortKey = 'payment_type';
            break;
        case 'Payment Date':
            sortKey = 'payment_date_time';
            break;
        case 'Contact Number':
            sortKey = (type === 'ftm') ? 'ftm_contact_number' : 'customer_contact_number';
            break;
        case 'Email':
            sortKey = (type === 'ftm') ? 'ftm_email' :  'customer_email';
            break;
        case 'Vehicle Number':
            sortKey = 'ftm_vehicle_number';
            break;
        case 'Created At':
            sortKey = 'ftm_createdAt';
            break;
        case 'Next of Kin':
            sortKey = 'ftm_next_of_kin';
            break;
        case 'Start Date':
            sortKey = 'ftm_holiday_start_date';
            break;
        case 'End Date':
            sortKey = 'ftm_holiday_end_date';
            break;
        default:
            console.log('name :', name);
    }

    return sortKey; 
};
