import React, { createContext, useEffect, useReducer } from "react";
import Reducer from "./reducer";
import { initialState } from "./initialState";
import { fetchFtmCertificates, fetchFtmDocuments } from "./service";

const Context = createContext();

const Provider = ({ children, documentType }) => {
    const isCertificates = documentType === "certificates";
    const [state, dispatch] = useReducer(Reducer, initialState);

    useEffect(() => {
        const getData = async () => {
            try {
                dispatch({ type: "LOADING" });

                const fetchFunction = isCertificates ? fetchFtmCertificates : fetchFtmDocuments;
                const response = await fetchFunction(state.page);

                dispatch({ 
                    type: "LOADED", 
                    documents: response?.documents,
                    total_count: response?.total_documents,
                    total_pages: response?.total_pages 
                });
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        getData();
    }, [isCertificates, state.page, state.refreshing_count]);

    const handelUpdatePage = (page) => dispatch({ type: "UPDATE_PAGE", page: page});
    const handelUpdateRefreshingCount = () => dispatch({ type: "UPDATE_REFRESHING_COUNT"});

    return (
        <Context.Provider value={{ state, isCertificates, handelUpdatePage, handelUpdateRefreshingCount }}>
            {children}
        </Context.Provider>
    );
};

export { Context, Provider };