import React from "react";

import Html from "./Html";
import { Provider } from "./Provider";

const Expired = ({ documentType }) => {
    return(
        <Provider
            documentType = {documentType}
            children = {<Html/>}
        />
    )
} 

export default Expired;