import React from "react";
import { theme } from "../theme";

const CardWithHeaderTemplate = ({ header, body, footer, extraCardClassName = null, extraBodyClassName = null, cardHeight = null, removeMy4 = false }) => {
    return (
        <div className={`card ${removeMy4 ? '' : 'my-4'} ${extraCardClassName != null ? extraCardClassName : ''}`}>
            {header !== null && (
                <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                    <div className={`bg-gradient-${theme.main} shadow-${theme.main} border-radius-lg pt-4 pb-3`}>
                        {header}
                    </div>
                </div>
            )}
            <div className={`card-body ${extraBodyClassName != null ? extraBodyClassName : 'px-0 pb-2'}`} style={{height: cardHeight}}>
                {body}
            </div>
            {footer !== null && (
                <div className="card-footer">
                    {footer}
                </div>
            )}
        </div>
    );
}

export default CardWithHeaderTemplate;
