export default function Reducer(prevState, action){
    switch (action.type) {
        case "LOADING":
            return {
                ...prevState,
                loading: true,
            };
        case "LOADED": 
            return {
                ...prevState,
                documents: action.documents,
                total_count: action.total_count,
                total_pages: action.total_pages,
                loading: false,
            };
        case "UPDATE_PAGE":
            return {
                ...prevState,
                page: action.page,
            };
        case "UPDATE_REFRESHING_COUNT":
            return {
                ...prevState,
                refreshing_count: prevState.refreshing_count+1
            }
        default:
            console.log("action.type: ", action.type)
            throw new Error();
    }
}