import React, { useReducer, useEffect } from "react";

import Html from "./html";
import Reducer from "./reducer";
import Context from "./context";
import { initialState } from "./initialState";
import { loadData } from "./service";

const UsersList = () => {
    const [state, dispatch] = useReducer(Reducer,initialState);

    useEffect(() => {
        loadData(state.page_number, dispatch);
    }, [state.page_number, state.refresh_count, dispatch]);

    return(
        <Context.Provider value={{state, dispatch}}>
            <Html/>
        </Context.Provider>
    );
}

export default UsersList;