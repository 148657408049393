export const imageGallery = {
    galleryTextContainer: {
        backgroundColor: '#fff',
        padding: '15px',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    },
    galleryContainer: {
        padding: '15px',
    },
    expiryDate: {
        fontSize: '18px',
        color: '#888',
        marginBottom: '10px'
    },
    description: {
        fontSize: '14px',
        color: '#666',
        marginBottom: '15px',
        lineHeight: '1.5'
    },
    imageGrid: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '10px',
        marginBottom: '15px'
    },
    imageItem: {
        width: '48%',
        position: 'relative',
        textAlign: 'center'
    },
    image: {
        width: '100%',
        height: '100px',
        objectFit: 'cover',
        borderRadius: '4px'
    },
    imageFooter: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '5px',
        // padding: '5px 0',
        backgroundColor: '#ccc9c9',
        borderRadius: '0 0 4px 4px'
    },
    downloadButton: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '24px',
        height: '24px',
        backgroundColor: '#555',
        borderRadius: '50%',
        color: '#fff',
        fontSize: '16px',
        textDecoration: 'none',
        transition: 'background-color 0.3s',
        marginRight: '5px'
    },
    imageName: {
        flex: 1,
        textAlign: 'left',
        padding: '5px 10px', 
        paddingRight: '50px'
    },
    pdfPlaceholder: {
        width: '100%',
        height: '100px', // Adjust height as necessary
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#e0e0e0', // Light grey background
        border: '1px dashed #888', // Dashed border for distinction
        borderRadius: '4px',
        color: '#333', // Text color
        fontWeight: 'bold',
        textAlign: 'center',
    },
    singleImageViewOverlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
    },
    singleImageView: {
        position: 'relative',
        maxWidth: '80%',
        maxHeight: '80%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '70%',
        height: '80%'
    },
    largeImage: {
        maxWidth: '100%',
        maxHeight: '100%',
        borderRadius: '8px',
    },
    pdfViewer: {
        width: '100%',
        height: '100%',
    },
    closeButton: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        color: '#fff',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
};