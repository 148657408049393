import React, {useContext} from "react";
import Context from "../context";

const FtmPayments = () => {
    const context = useContext(Context);

    return(
        <div className="card">
            <div className="card-header pb-0 p-3">
                <div className="row">
                    <div className="col-6 d-flex align-items-center">
                        <h6 className="mb-0">TRADESPERSON PAYMENTS</h6>
                    </div>
                </div>
            </div>
            <div className="card-body p-3 pb-0">
                <ul className="list-group">
                    <li className="list-group-item border-0 d-flex justify-content-between ps-0 pb-0 border-radius-lg">
                        <div className="d-flex flex-column">
                            <h6 className="mb-1 text-dark font-weight-bold text-sm">Name</h6>
                        </div>
                        <div className="d-flex align-items-center text-sm">
                            <span className="ms-sm-2">
                                {context.state.details.ftms.ftm_forename} {context.state.details.ftms.ftm_surname}
                            </span>
                        </div>
                    </li>
                    <li className="list-group-item border-0 d-flex justify-content-between ps-0 pb-0 border-radius-lg">
                        <div className="d-flex flex-column">
                            <h6 className="mb-1 text-dark font-weight-bold text-sm">Description</h6>
                        </div>
                        <div className="d-flex align-middle text-end text-sm">
                            <span className="ms-sm-2">
                                {context.state.details.skill.skill_name}<br/>{context.state.details.skill.sub_skill.sub_skill_name}
                            </span>
                        </div>
                    </li>
                    <li className="list-group-item border-0 d-flex justify-content-between ps-0 pb-0 border-radius-lg">
                        <div className="d-flex flex-column">
                            <h6 className="mb-1 text-dark font-weight-bold text-sm">Name</h6>
                        </div>
                        <div className="d-flex align-items-center text-end text-sm">
                            <span className="ms-sm-2">
                                {context.state.details.ftms.ftm_forename} {context.state.details.ftms.ftm_surname}
                            </span>
                        </div>
                    </li>
                    <li className="list-group-item border-0 d-flex justify-content-between ps-0 pb-0 border-radius-lg">
                        <div className="d-flex flex-column">
                            <h6 className="mb-1 text-dark font-weight-bold text-sm">Time</h6>
                        </div>
                        <div className="d-flex align-items-center text-end text-sm">
                            <span className="ms-sm-2">
                                {context.state.details.job_value_calculator.ftm_time_diff} min
                            </span>
                        </div>
                    </li>
                    <li className="list-group-item border-0 d-flex justify-content-between ps-0 pb-0 border-radius-lg">
                        <div className="d-flex flex-column">
                            <h6 className="mb-1 text-dark font-weight-bold text-sm">Hourly rate</h6>
                        </div>
                        <div className="d-flex align-items-center text-end text-sm">
                            <span className="ms-sm-2">
                                &#163; {context.state.details.job_value_calculator.ftm_hourly_rate}
                            </span>
                        </div>
                    </li>
                    <li className="list-group-item border-0 d-flex justify-content-between ps-0 border-radius-lg">
                        <div className="d-flex flex-column">
                            <h6 className="mb-1 text-dark font-weight-bold text-sm">Sub total</h6>
                        </div>
                        <div className="d-flex align-items-center text-end text-sm">
                            <span className="ms-sm-2">
                                &#163; {context.state.details.job_value_calculator.job_value}
                            </span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default FtmPayments;