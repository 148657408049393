import React, { createContext, useEffect, useReducer } from "react";
import Reducer from "./reducer";
import { initialState } from "./initialState";
import { fetchSkills, updateFtmDocuments, updateFtmSkills } from "./service";
import { commonSwal } from "../../../components/swalAlert/swalAlert";

const Context = createContext();

const Provider = ({ ftm_id, children, handleClose, updateRefreshCount}) => {
    const [state, dispatch] = useReducer(Reducer,initialState);
    const { skills, selectedSkills } = state;

    useEffect(() => {
        const getSkills = async () => {
            try{
                dispatch({ type: "IS_LOADING" });
                const response = await fetchSkills();

                dispatch({ 
                    type: "IS_LOADED", 
                    skills: response.list, 
                });
            }catch(error){
                console.log(error);
            }
        }

        getSkills();
    }, []); 

    const handelSelectedSkills = (selectedSkills) => {
        commonSwal({
            type: 'UPDATE_FTM_SKILLS_AND_DOCUMENTS',
            showCancelButton: true,
            showLoaderOnConfirm: true,
        }).then(async (result) => {
            dispatch({ 
                type: "UPDATE_SELECTED_SKILLS", 
                selectedSkills: selectedSkills, 
            });
            
            const transform_skills = transformSkills(selectedSkills);

            dispatch({ 
                type: "SET_FTM_UPDATING", 
                ftm_updating: true, 
            });

            await updateFtmSkills({
                ftm_id: ftm_id,
                skills: transform_skills
            });

            dispatch({ 
                type: "SET_FTM_UPDATING", 
                ftm_updating: false, 
            });

            if (result.isConfirmed) {
                console.log('result :', result);
                dispatch({ 
                    type: "UPDATE_STEP", 
                    step: 2, 
                });
            }else{
                updateRefreshCount();
                handleClose();
            }
        })
    }

    const transformSkills = (selectedSkills) => {
        const result = [];
    
        console.log("transformSkills selectedSkills:", selectedSkills);
    
        // Create a Set of selected sub-skill IDs for quick lookup
        const selectedSubSkillIds = new Set();
        selectedSkills.forEach(selectedSkill => {
            selectedSkill.subSkills.forEach(selectedSubSkill => {
                selectedSubSkillIds.add(selectedSubSkill.sub_skill_id);
            });
        });
    
        // Iterate over skills and sub-skills once to populate result
        skills.forEach(skill => {
            skill.sub_skills.forEach(sub_skill => {
                result.push({
                    skill_id: sub_skill.sub_skill_id,  // Use sub_skill_id as skill_id in the result
                    status: selectedSubSkillIds.has(sub_skill.sub_skill_id) ? 1 : 0
                });
            });
        });
    
        return result;
    }    

    const handelSetSkillFiles = (skill_id, documents_types_id, files) => {
        // Locate the skill by its ID and update the document types for that skill
        const updatedSkills = state.selectedSkills.map(skill => {
            if (skill.skill_id === skill_id) {
                const updatedDocumentTypes = skill.documents_types.map(docType => {
                    if (docType.documents_types_id === documents_types_id) {
                        return {
                            ...docType,
                            files: [...(docType.files || []), ...files] // Add new files to the documents_type
                        };
                    }
                    return docType;
                });
    
                return {
                    ...skill,
                    documents_types: updatedDocumentTypes
                };
            }
            return skill;
        });
    
        // Assuming you have a state setter function for 'skills'
        console.log("handelSetSubSkillFiles updatedSkills : ", updatedSkills);

        dispatch({ 
            type: "UPDATE_SELECTED_SKILLS", 
            selectedSkills: updatedSkills, 
        });
    };
    
    const handelSetSubSkillFiles = (skill_id, sub_skill_id, documents_types_id, files) => {
        // Locate the skill by its ID
        const updatedSkills = state.selectedSkills?.map(skill => {
            if (skill.skill_id === skill_id) {
                const updatedSubSkills = skill.subSkills.map(subSkill => {
                    if (subSkill.sub_skill_id === sub_skill_id) {
                        const updatedDocumentTypes = subSkill.documents_types.map(docType => {
                            if (docType.documents_types_id === documents_types_id) {
                                return {
                                    ...docType,
                                    files: [...(docType.files || []), ...files] // Add new files to the documents_type in the sub-skill
                                };
                            }
                            return docType;
                        });
    
                        return {
                            ...subSkill,
                            documents_types: updatedDocumentTypes
                        };
                    }
                    return subSkill;
                });
    
                return {
                    ...skill,
                    subSkills: updatedSubSkills
                };
            }
            return skill;
        });
    
        // Assuming you have a state setter function for 'skills'
        console.log("handelSetSubSkillFiles updatedSkills : ", updatedSkills);
        dispatch({ 
            type: "UPDATE_SELECTED_SKILLS", 
            selectedSkills: updatedSkills, 
        });
    };

    const handelBackStep = () => {
        dispatch({ type: "BACK_STEP" });
    }

    const uploadFtmDocuments = async () => {
        const uploadDocuments = async (documents, documentType) => {
            for (const documents_type of documents) {
                if (documents_type?.files) {
                    for (const fileObj of documents_type.files) {
                        await updateFtmDocuments({
                            ftm_id: ftm_id,
                            document_type_id: documents_type.documents_types_id,
                            document_type: documents_type.documents_types_name,
                            document_file: fileObj.file,
                            document_type_type: documentType,
                        });
                    }
                }
            }
        };
    
        dispatch({ 
            type: "SET_FTM_UPDATING", 
            ftm_updating: true, 
        });

        // Iterate over selected skills and upload main skill documents
        for (const selectedSkill of selectedSkills) {
            await uploadDocuments(selectedSkill.documents_types, 0);
    
            // Upload sub-skill documents
            for (const subSkill of selectedSkill.subSkills) {
                await uploadDocuments(subSkill.documents_types, 1);
            }
        }

        dispatch({ 
            type: "SET_FTM_UPDATING", 
            ftm_updating: false, 
        });

        updateRefreshCount();
        handleClose();
    };
    

    return (
        <Context.Provider value={{state, dispatch, handelSelectedSkills, handelSetSkillFiles, handelSetSubSkillFiles, handelBackStep, uploadFtmDocuments}}>
            {children}
        </Context.Provider>
    );
}

export { Context, Provider };