import React, { useContext } from "react";
import VisibilityIcon from '@mui/icons-material/Visibility';
import {useNavigate} from "react-router-dom";
import Chip from '@mui/material/Chip';
import FlagIcon from '@mui/icons-material/Flag';
import Button from '@mui/material/Button';

import Context from "../context";
import DivSpinner from "../../../../components/divSpinner";
import { truncateText } from "../../../../utils";
import { theme } from "../../../../theme";
import { isReview } from "../service";
import ColumnSort from "./columnSort";

const Table = () => {
    const context = useContext(Context);
    let navigate = useNavigate();

    return(
        <>
            {context.state.is_loading ? (
                <div style={{height : `${window.innerHeight-387}px`}}>
                    <DivSpinner/>
                </div>
            ) : (
                <div className="table-responsive p-0 m-3">
                    <table className="table align-items-center mb-0">
                        <thead>
                            <tr>
                                {[
                                    'Job ID', 
                                    'Tradesperson', 
                                    'Customer', 
                                    'Postcode', 
                                    'Job type',
                                    'Cancelled by',
                                    <span>Customer<br/>penalty</span>,
                                    <span>Tradesperson<br/>penalty</span>,
                                    'Reason'
                                ].map((text, index) => (
                                    <th 
                                        key={index}
                                        className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7"
                                    >
                                        <ColumnSort sortKeyName={text} />
                                    </th>
                                ))}
                                <th className="text-secondary opacity-7"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {context.state.jobs_list.length > 0 ? (
                                <>
                                    {context.state.jobs_list.map((item, index) => (
                                        <tr key={index}>
                                            <td className="">
                                                <span className="text-secondary text-sm font-weight-bold">{item?.cancel_job_job_id}</span>
                                            </td>
                                            <td>
                                                <div className="d-flex px-2 py-1">
                                                    <div className="d-flex flex-column justify-content-center">
                                                        <h6 className="mb-0 text-sm">{item?.job?.ftm?.ftm_forename} {item?.job?.ftm?.ftm_surname}</h6>
                                                        <p className="text-xs text-secondary mb-0">Created {item?.job?.job_createdAt}</p>
                                                    </div>
                                                </div>
                                            </td>
                                            {[
                                                item?.job?.customer?.customer_full_name,
                                                item?.job?.job_postcode === null ? 'N/A' : item?.job?.job_postcode,
                                                item?.job?.sub_skill?.skill?.skill_name+' - '+item?.job?.sub_skill?.sub_skill_name,
                                                <Chip 
                                                    label={item?.cancel_job_user_type == 1 ? "CUSTOMER" : "TRADESPERSON"} 
                                                    variant={item?.cancel_job_user_type == 1 ? "" : "outlined"}
                                                    color="error"
                                                />,
                                                theme.currency+" "+item?.customer_penalty_value,
                                                theme.currency+" "+item?.ftm_penalty_value,
                                                truncateText(item?.job_cancel_reason?.job_cancel_reason_is_other == 0 ? item?.job_cancel_reason?.job_cancel_reason : item?.cancel_job_reason, 20),
                                            ].map((text, index) => (
                                                <td className="align-middle" key={index}>
                                                    <span className="text-secondary text-sm font-weight-bold">
                                                        {text}
                                                    </span>
                                                </td>
                                            ))}
                                            
                                            <td className="align-middle">
                                                <VisibilityIcon 
                                                    style={{cursor: 'pointer'}}
                                                    onClick={() => navigate(`/jobs/view/${item?.cancel_job_job_id}`)} 
                                                />
                                                {item?.cancel_job_admin_is_show === 1 ? (
                                                    <FlagIcon color={theme.checkbox_fill_color} style={{fontSize: '30px'}}/>
                                                ) : (
                                                    <Button 
                                                        variant="text" 
                                                        color={theme.main} 
                                                        onClick={() => isReview(item?.cancel_job_job_id, context.state.current_page, context.dispatch)}
                                                    >
                                                        REVIEW
                                                    </Button>
                                                )}
                                            </td> 
                                        </tr>
                                    ))}
                                </>
                            ) : (
                                <tr>
                                    <td colSpan="8">Sorry, no data found!</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )}
        </>
    );
}

export default Table;