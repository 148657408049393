import toast from 'react-hot-toast';
import Swal from 'sweetalert2';

import { GET } from "../../API/get";
import { POST } from '../../API/post';
import { PUT } from '../../API/put';
import { theme } from '../../theme';
import { DELETE } from '../../API/delete';
import { commonSwal } from '../../components/swalAlert/swalAlert';

export const loadData = async (skill_id, dispatch) => {
    dispatch({ type: "IS_LOADING" });

    let response = await GET(`sub/skills/by/skill/${skill_id}`);

    if(response.status === "Success"){
        dispatch({ 
            type: "IS_LOADED", 
            sub_skills: response.list
        });
        dispatch({ 
            type: "SET_COMMISSION_SELECTED_CHARGE_TYPE", 
            selected_charge_type: 0 
        });
    }
}

const checkError = (data, error, dispatch) => {
    if(data.skill_name == '' ){
        error.push();
    }
}

export const saveNewSubSkill = async (skill_id, data, dispatch, reset) => {
    if(data.skill_name !== "" && data.rate !== "" && data.commission !== ""){
        dispatch({
            type: 'SET_IS_LOADING_BTN',
            btn_loading: true
        });

        let response = await POST("sub-skills", {
            sub_skill_skill_id: skill_id,
            sub_skill_name : data.skill_name,
            sub_skill_hourly_rate : data.rate,
            sub_skill_company_commission : data.commission,
        });

        if(response.status === "Success"){
            toast.success(response.message);
            reset();
            loadData(skill_id, dispatch);
        }else{
            let error = [];
            error.push(response.message);

            dispatch({
                type: 'SET_ERROR',
                error: error
            });     
        }
    }else {
        let error = [];

        if(data.skill_name == ""){
            error.push("Please enter service type")
        }

        if(data.rate == ""){
            error.push("Please enter rate")
        }

        if(data.commission == ""){
            error.push("Please enter commission")
        }

        dispatch({
            type: 'SET_ERROR',
            error: error
        });
    }
}

export const editSubSkill = async (event, selected_sub_skills, dispatch, reset) => {
    event.preventDefault();

    let skill_name = event.target.elements.skill_name.value;
    let rate = event.target.elements.rate.value;
    let commission = event.target.elements.commission.value;

    if(skill_name !== "" && rate !== "" && commission !== ""){
        dispatch({
            type: 'SET_IS_LOADING_BTN',
            btn_loading: true
        });

        let response = await PUT("sub-skills", {
            sub_skill_id: selected_sub_skills.sub_skill_id,
            sub_skill_name : skill_name,
            sub_skill_hourly_rate : rate,
            sub_skill_company_commission : commission,
        });

        if(response.status === "Success"){
            toast.success(response.message);
            reset();
            loadData(selected_sub_skills.sub_skill_skill_id, dispatch);
        }else{
            let error = [];
            error.push(response.message);

            dispatch({
                type: 'SET_ERROR',
                error: error
            });     
        }
    }else {
        let error = [];

        if(skill_name == ""){
            error.push("Please select service type")
        }

        if(rate == ""){
            error.push("Please select rate")
        }

        if(commission == ""){
            error.push("Please select commission")
        }

        dispatch({
            type: 'SET_ERROR',
            error: error
        });
    }
}

export const deleteSubSkills = (sub_skill, dispatch) => {
    commonSwal({
        type: 'DELETE_SUB_SKILLS',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: async () => {
            let response = await DELETE("sub-skills/"+sub_skill.sub_skill_id);
            return response;
        },
        allowOutsideClick: () => !Swal.isLoading()
    }).then(async (result) => {
        console.log('result :', result);
        if (result.isConfirmed) {
            console.log('result :', result);

            if(result.value.status === "Success"){
                loadData(sub_skill.sub_skill_skill_id, dispatch);
            }
        }
    })
}

export const getChargeTypes = async (dispatch) => {
    let response = await GET("charge/types");

    if(response.status === "Success"){
        dispatch({
            type: 'SET_CHARGE_TYPES',
            charge_types: response.charge_types
        });
    }
}

export const saveCommissions = async (selected_sub_skills, data, dispatch, reset) => {
console.log('selected_sub_skills :', selected_sub_skills);
console.log('data :', data);
    dispatch({
        type: 'SET_IS_LOADING_BTN',
        btn_loading: true
    });

    let response = await POST("material/commissions", {
        sub_skill_id: selected_sub_skills.sub_skill_id,
        charge_type_id: data.types,
        commission_value: data.commission,
    });

    if(response.status === "Success"){
        toast.success(response.message);

        reset();
        loadData(selected_sub_skills.sub_skill_skill_id, dispatch);
    }
}

export const handleCommisionModalClose = (event, dispatch, reset) => {
    reset();

    dispatch({
        type: 'IS_SHOW_MATERIAL_COMMISSIONS', 
        is_show_material_commissions: false,
        selected_sub_skills: {}
    });

    dispatch({ 
        type: "SET_COMMISSION_SELECTED_CHARGE_TYPE", 
        selected_charge_type: 0 
    });
}