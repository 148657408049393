/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import { Badge } from "@mui/material";

import { theme } from "../../../theme";
import { Context } from "../Provider";
import { fetchAdminDetailsInFirebase, updateLastMessageSeenUser } from "../service";

const Btn = () => {
    const {state, openEditView, handleIsShowEditFtm } = useContext(Context);
    const {ftm} = state;

    const [unreadMessageCount, setUnreadMessageCount ] = useState(0);

    useEffect(() => {
        const getAdminDetailsInFirebase = async() => {
            const count = await fetchAdminDetailsInFirebase(ftm.ftm_firebase_document_id);
            setUnreadMessageCount(count);;
        }

        getAdminDetailsInFirebase();
    }, [ftm.ftm_firebase_document_id]);

    const handelOpenChatView = async () => {
        await updateLastMessageSeenUser(ftm.ftm_firebase_document_id);
        handleIsShowEditFtm();
    }

    return (
        <div className="col-lg-3 col-md-3 my-sm-auto ms-sm-auto me-sm-0 mx-auto mt-3">
            <div className="nav-wrapper position-relative end-0">
                <ul className="nav nav-pills nav-fill p-1" role="tablist">
                    <li className="nav-item">
                        <a 
                            className="nav-link mb-0 px-0 py-1" 
                            data-bs-toggle="tab" 
                            href="#" 
                            role="tab" 
                            aria-selected="false"
                            onClick={openEditView}
                        >
                            <i className="material-icons text-lg position-relative">edit</i>
                            <span className="ms-1"><b>EDIT</b></span>
                        </a>
                    </li>

                    <li className="nav-item">
                        <Badge 
                            badgeContent={unreadMessageCount} 
                            color={theme.sidebar_notification_count_color}
                        >
                            <a 
                                className="nav-link mb-0 px-0 py-1" 
                                data-bs-toggle="tab" 
                                href="#" 
                                role="tab" 
                                aria-selected="false"
                                onClick={handelOpenChatView}
                            >
                                <i className="material-icons text-lg position-relative">forum</i>
                                <span className="ms-1"><b>CHATS</b></span>
                            </a>
                        </Badge>
                    </li>
                </ul>
                
            </div>
        </div>
    );
}

export default Btn;