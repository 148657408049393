import { GET } from "../../API/get";

export const loadData = async (page_number, serach_text, sort_key, sort_direction, dispatch) => {
    dispatch({ type: "IS_LOADING" });
    
    let url = `get-all-customers/${page_number}?search=${serach_text.trim()}&sort_key=${sort_key}&sort_direction=${sort_direction}`;
    let response = await GET(url);

    if(response.status === "Success"){
        dispatch({ 
            type: "IS_LOADED",
            total_customers: response.total_customers,
            total_pages: response.total_pages,
            list: response.list,
            // page_number: page_number
        });
    }
}

export const getStatusColor = (customer_status) => {
    switch(customer_status){
        case 'Verify':
            return 'success';
        case 'Not verify':
            return 'danger';
        default:
            return '';
    }
}

export const getStatusText = (customer_status) => {
    switch(customer_status){
        case 'Verify':
            return 'Verified';
        case 'Not verify':
            return 'Not Verified';
        default:
            return '';
    }
}