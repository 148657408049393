export default function  ftmsReducer(prevState, action){
    switch (action.type) {
        case "IS_LOADING":
            return {
                ...prevState,
                is_loading: true,
            };
        case "IS_JOB_LIST_LOADING":
            return {
                ...prevState,
                is_job_list_loading: true,
            };
        case "IS_LOADED": 
            return {
                ...prevState,
                ftms_list: action.ftms_list,
                total_ftms: action.total_ftms,
                current_page: action.current_page,
                total_pages: action.total_pages,
                is_loading: false,
            };
        case "OPEN_SHOW_JOBS":
            return {
                ...prevState,
                is_show_jobs: true,
                selected_ftm: action.selected_ftm,
            };
        case "CLOSE_SHOW_JOBS":
            return {
                ...prevState,
                is_show_jobs: false,
            };
        case "SET_JOB_LIST":
            return {
                ...prevState,
                job_list: action.job_list,
                job_list_total: action.job_list_total,
                job_list_total_pages: action.job_list_total_pages,
                job_list_current_page: action.job_list_current_page,
                is_job_list_loading: false,
            };
        case "UPDATE_CURRENT_PAGE":
            return {
                ...prevState,
                current_page: action.current_page,
            };
        case "SET_SERACH_VALUE":
            return {
                ...prevState,
                current_page: 1,
                search_value: action.search_value,
            }; 
        case "UPDATE_JOB_LIST_CURRENT_PAGE":
            return {
                ...prevState,
                job_list_current_page: action.job_list_current_page,
            };
        case "SET_JOB_LIST_SEARCH_VALUE":
            return {
                ...prevState,
                job_list_current_page: 1,
                job_list_search_value: action.job_list_search_value,
            };
        case "SET_SORT_CONFIG":
            return {
                ...prevState,
                sort_key: action.sort_key,
                sort_direction: action.sort_direction,
            };
        default:
            throw new Error();
    }
}