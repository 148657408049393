import React, { useReducer, useEffect } from "react";
import { useParams } from "react-router-dom";
import Reducer from "./reducer";
import { initialState } from "./initialState";
import Html from "./html";
import Context from "./context";
import { loadData } from "./service";

const CompliedJobs = () => {
    const { nav_type } = useParams();
    const [state, dispatch] = useReducer(Reducer,initialState);

    useEffect(() => {
        loadData({
            page_number: state.current_page, 
            nav_type: nav_type,
            search_value: state.search_value, 
            sort_key: state.sort_key,
            sort_direction: state.sort_direction,
            dispatch: dispatch
        });
    }, [nav_type, state.current_page, state.search_value, state.sort_key, state.sort_direction]); 

    return (
        <Context.Provider value={{nav_type, state, dispatch}}>
            <Html/>
        </Context.Provider>
    );
}

export default CompliedJobs;