import React, { createContext, useEffect, useReducer } from "react";
import Reducer from "./reducer";
import { initialState } from "./initialState";
import { fetchFtms } from "./service";

const Context = createContext();

const Provider = ({ children }) => {
    const [state, dispatch] = useReducer(Reducer, initialState);

    useEffect(() => {
        const getData = async () => {
            try{
                dispatch({ type: 'IS_LOADING' });

                const response = await fetchFtms(state.page_number, state.serach_text, state.sort_key, state.sort_direction);
                dispatch({ 
                    type: "IS_LOADED", 
                    ftms: response.list, 
                    total_ftms: response.total_ftms, 
                    total_pages: response.total_pages, 
                });
            } catch (error) {
                console.log(error);
            }
        };

        getData();
    }, [state.page_number, state.serach_text, state.sort_key, state.sort_direction]);
    

    return (
        <Context.Provider value={{state, dispatch}}>
            {children}
        </Context.Provider>
    )
}

export { Context, Provider };