import React, { forwardRef, useState } from "react";
import InputMask from "react-input-mask";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { theme } from "../../../theme";
import { editAdmin } from "../service";
import BtnSpinner from "../../../components/btnSpinner";

// Wrap InputMask with React.forwardRef
const MaskedInput = forwardRef((props, ref) => {
    const { mask, maskChar, onChange, value, ...otherProps } = props;
    return (
        <InputMask
            mask={mask}
            maskChar={maskChar}
            value={value}
            onChange={onChange}
            {...otherProps}
        >
            {(inputProps) => <input {...inputProps} ref={ref} />}
        </InputMask>
    );
});

// Validation schema
const schema = yup.object({
    admin_full_name: yup.string().required("Full name is required"),
    admin_contact_number: yup.string().required("Contact number is required"),
});

const Edit = ({ admin_id, admin_full_name, admin_contact_number, handleClose }) => {
    const [isLoadingBtn, setIsLoadingBtn] = useState(false);

    const {
        control,
        register,
        formState: { errors },
        handleSubmit,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            admin_full_name: admin_full_name || "",
            admin_contact_number: admin_contact_number || "",
        },
    });

    const onSubmit = async (data) => {
        console.log("Form Data:", data);
        setIsLoadingBtn(true);

        await editAdmin({
            admin_id: admin_id,
            admin_full_name: data.admin_full_name,
            admin_contact_number: data.admin_contact_number.replace(/\D/g, '')
        }).then((response) => {
            setIsLoadingBtn(false);
            if (response?.status === "Success") {
                handleClose();
            }
        });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="container">
                {/* Full Name Field */}
                <div className="row">
                    <div className="col-12">
                        <FormControl error={!!errors.admin_full_name} fullWidth>
                            <strong>Full Name</strong>
                            <TextField
                                {...register("admin_full_name")}
                                placeholder="Full name"
                                size="small"
                                error={!!errors.admin_full_name}
                                helperText={errors.admin_full_name?.message}
                            />
                            {errors.admin_full_name && (
                                <FormHelperText>{errors.admin_full_name.message}</FormHelperText>
                            )}
                        </FormControl>
                    </div>
                </div>

                {/* Contact Number Field */}
                <div className="row mt-4">
                    <div className="col-12">
                        <FormControl error={!!errors.admin_contact_number} fullWidth>
                            <strong>Contact Number</strong>
                            <Controller
                                name="admin_contact_number"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        placeholder="Phone number"
                                        size="small"
                                        InputProps={{
                                            inputComponent: MaskedInput,
                                            inputProps: {
                                                mask: "+44 799 999 9999",
                                                maskChar: "",
                                            },
                                        }}
                                        error={!!errors.admin_contact_number}
                                    />
                                )}
                            />
                            {errors.admin_contact_number && (
                                <FormHelperText>{errors.admin_contact_number.message}</FormHelperText>
                            )}
                        </FormControl>
                    </div>
                </div>

                {/* Submit Button */}
                <div className="row mt-4">
                    <div className="col-12 text-end">
                        <button type="submit" className={`btn btn-sm btn-${theme.main}`} disabled={isLoadingBtn}>
                            {isLoadingBtn ? (
                                <BtnSpinner />
                            ) : (
                                "Save"
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default Edit;