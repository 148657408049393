import { GET } from "../../API/get";
import { map_settings } from "../../map";
import { formatTimestampInMap, textReplace } from "../../utils";

export const fetchData = async (ftm_id) => {
    let response = await GET(`ftm/jobs/history/${ftm_id}`);
    if (response.status === "Success") {
        return response;
    }

    return null;
}

export const makeJobMarkers = async ({ map, google, context }) => {
    if (context.state.selected_job_markers && context.state.selected_job_markers.length > 0) {
        context.state.selected_job_markers.forEach(marker => {
            marker.setMap(null); // Removes the marker from the map
        });
    }

    if(map){
        const newMarkers = [];
        if (context.state.selected_job != null) {
            let bounds = new google.maps.LatLngBounds();
            for (let job_status_change_log of context.state.selected_job.job_status_change_logs) {
                let title = textReplace({
                    text: job_status_change_log.job_status.job_status_name, 
                    search: '[[FTM]]', 
                    replace: context.state.details?.ftm?.ftm_forename+" "+context.state.details?.ftm?.ftm_surname
                });
                

                let infowindow = new google.maps.InfoWindow({
                    content:`<div className="d-flex">
                                <div className="ms-3">
                                    <div className="numbers">
                                        <h6 className="mb-1 text-dark text-sm">
                                            <i className="material-icons  me-2" style="color: ${job_status_change_log.job_status.job_status_color}; font-size: 25px;">${job_status_change_log.job_status.job_status_icon}</i> 
                                            ${title}
                                        </h6>
                                        <span className="text-sm">
                                            ${formatTimestampInMap(job_status_change_log.job_status_change_log_timestamp)}
                                        </span>
                                    </div>
                                </div>
                            </div>`,
                    ariaLabel: title,
                }); 

                let latLng = new google.maps.LatLng(job_status_change_log.job_status_change_log_latitude, job_status_change_log.job_status_change_log_longitude);
                bounds.extend(latLng);

                let marker = new google.maps.Marker({
                    position: {
                        lat: job_status_change_log.job_status_change_log_latitude,
                        lng: job_status_change_log.job_status_change_log_longitude
                    },
                    map,
                    icon:map_settings.ftm_map_icon ,
                    options: {
                        color: job_status_change_log.job_status.job_status_color
                    }
                });

                marker.addListener("click", () => {
                    infowindow.open({
                        anchor: marker,
                        map,
                    });
                });

                newMarkers.push(marker);
            }

            map.fitBounds(bounds);
            context.dispatch({
                type: "SET_SELECTED_JOB_MARKERS",
                selected_job_markers: newMarkers
            });
        }
    }
}

export const fetchAllJobsStatusLogs = async ({ftm_id, page_number, serach_value}) => {
    let response = await GET(`ftm/jobs/status/logs/${ftm_id}/${page_number}?search=${serach_value}`);
    if(response.status === "Success"){
        return response;
    }

    return null;
}

export const fetchJobsStatusLogs = async (job_id) => {
    let response = await GET(`job/status/change/logs/${job_id}`);
    if(response.status === "Success"){
        return response;
    }

    return null;
}