import { GET } from "../../API/get";

export const loadData = async ({page_number, search_value, start_date, end_date, sort_key, sort_direction, dispatch}) => {
    dispatch({ type: "IS_LOADING" });
    let response;
    if(start_date !== "" && end_date !== ""){
        response = await GET(`all/jobs/payments/${page_number}?start_date=${start_date}&end_date=${end_date}&search=${search_value}&sort_key=${sort_key}&sort_direction=${sort_direction}`);
    }else{
        response = await GET(`all/jobs/payments/${page_number}?search=${search_value}&sort_key=${sort_key}&sort_direction=${sort_direction}`);
    }

    if(response.status === "Success"){
        dispatch({ 
            type: "SET_PAYMENTS", 
            total_jobs: response.total_jobs, 
            total_pages: response.total_pages, 
            payments: response.list, 
            current_page: page_number 
        });
    }
}

export const filterDate = (data, reset, dispatch) => {
    console.log('data :', data);

    if(data.start_date === '' && data.end_date === ''){
        dispatch({
            type: "SET_ERROR",
            error: 'Please select start date and end date.'
        });
    }else{
        if(data.start_date === ''){
            dispatch({
                type: "SET_ERROR",
                error: 'Please select start date.'
            });
        }else{
            if(data.end_date === ''){
                dispatch({
                    type: "SET_ERROR",
                    error: 'Please select end date.'
                });
            }else{
                dispatch({
                    type: "SET_ERROR",
                    error: null
                });
                reset();
                dispatch({
                    type: 'SET_DATE_RANGE',
                    start_date: data.start_date,
                    end_date: data.end_date
                });
            }
        }
    }
}

export const dateFilter = (start, end) => {
console.log('end :', end);
console.log('start :', start);

}

export const saveAsCSV = (payments, dispatch) => {
    const csvHeaders = [
        { label: "JOB ID", key: "job_id" },
        { label: "JOB CREATE", key: "job_create_at" },
        { label: "TRADESPERSON", key: "ftm" },
        { label: "JOB DURATION(MIN)", key: "ftm_time_diff" },
        { label: "RATE PER HOUR", key: "ftm_hourly_rate" },
        { label: "TRADESPERSON CHARGE", key: "job_ftm_value" },
        { label: "COMPANY COMMISSION", key: "company_commission" },
        { label: "SUB TOTAL", key: "job_value" },
        { label: "COMPANY COMMISSION(MATERIALS)", key: "total_extra_payments_company_commission"},
        { label: "MATERIALS CHARGE(TRADESPERSON)", key: "total_extra_payments_ftm_payment"},
        { label: "VAT", key: "vat"},
        { label: "TOTAL", key: "final_total_value_with_vat"}
    ];

    const csvData = payments.map(payment => {
        return {
            job_id: payment.job_id,
            job_create_at: payment.job_create_at,
            ftm: payment.ftm.ftm_forename + " " + payment.ftm.ftm_surname,
            ftm_time_diff: payment.ftm_time_diff,
            ftm_hourly_rate: payment.ftm_hourly_rate,
            job_ftm_value: payment.job_ftm_value,
            company_commission: payment.company_commission,
            job_value: payment.job_value,
            total_extra_payments_company_commission: payment.total_extra_payments_company_commission,
            total_extra_payments_ftm_payment: payment.total_extra_payments_ftm_payment,
            vat: payment.vat,
            final_total_value_with_vat: payment.final_total_value_with_vat
        }
    });
    

    dispatch({ 
        type: "SET_CSV_DATA",
        csv_data: csvData,
        csv_headers: csvHeaders
    });
}