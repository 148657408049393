export const initialState = {
    is_loading: true,
    total_jobs: 0,
    total_pages: 0,
    current_page: 1,
    payments: [],
    start_date: '',
    end_date: '',
    is_show_date_filter: false,
    error: null,
    csv_data: [],
    csv_headers: [],
    search_value: '',
    sort_key: '',
    sort_direction: ''
}