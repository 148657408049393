import React, { useEffect, useContext, useRef, useCallback, useState, useMemo } from "react";

import { createMarkerContent, loadScript, map_settings } from "../../../map";
import { Context } from "../Provider";

const FtmMap = () => {
    const mapRef = useRef(null);
    const mapInstance = useRef(null);
    const boundsRef = useRef(null);
    const [mapLoaded, setMapLoaded] = useState(false);

    const context = useContext(Context);
    const { state } = context;
    const { ftm } = state;
    const { ftm_forename, ftm_surname, ftm_latitude, ftm_longitude } = ftm;

    // Load the Google Maps script
    useEffect(() => {
        loadScript(
            map_settings.map_script_url,
            () => setMapLoaded(true),
            () => console.error("Failed to load Google Maps script.")
        );
    }, []);

    // Initialize the map
    const initializeMap = useCallback(() => {
        if (mapInstance.current || !window.google?.maps) return;

        mapInstance.current = new window.google.maps.Map(mapRef.current, {
            center: { lat: parseFloat(ftm_latitude), lng: parseFloat(ftm_longitude) },
            zoom: 8,
            mapId: "FTM_MAP",
        });

        boundsRef.current = new window.google.maps.LatLngBounds();
    }, [ftm_latitude, ftm_longitude]);

    // Add markers
    const addMarkers = useCallback(() => {
        if (!ftm_latitude || !ftm_longitude) return;

        const location = {
            lat: parseFloat(ftm_latitude),
            lng: parseFloat(ftm_longitude),
        };

        new window.google.maps.marker.AdvancedMarkerElement({
            position: location,
            content: createMarkerContent(map_settings.ftm_map_icon),
            map: mapInstance.current,
            title: `${ftm_forename} ${ftm_surname}`,
        });

        mapInstance.current.setZoom(8);
    }, [ftm_forename, ftm_latitude, ftm_longitude, ftm_surname]);

    // Effect for initializing the map and adding markers
    useEffect(() => {
        if (mapLoaded) {
            initializeMap();
            addMarkers();
        }
    }, [mapLoaded, initializeMap, addMarkers]);

    return (
        <div className="page-header min-height-300 border-radius-xl mt-4">
            <div ref={mapRef} style={{ width: '100%', height: 350 }} />
        </div>
    );
};

export default FtmMap;