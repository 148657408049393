import React, { useContext } from "react";
import Swal from "sweetalert2";
import { Button, Stack } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

import { updateFtmApproveAndReject } from "../service";
import { Context } from "../Provider";
import { commonSwal } from "../../../components/swalAlert/swalAlert";

const FooterBtn = () => {
    const { state, ftm_id, updateRefreshCount } = useContext(Context);
    const { ftm } = state;
    const isApproved = ftm?.ftm_is_verify === 1;
    const isRejected = ftm?.ftm_is_verify === -1;

    const handleFtmApproveAndReject = async (isApprove) => {
        const isApproval = isApprove === 1;
    
        commonSwal({
            type: isApproval ? 'APPROVE_FTM' : 'REJECT_FTM',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            input: !isApproval && 'textarea',
            preConfirm: async (reason = "") => {
                if (!isApproval && reason === "") {
                    Swal.showValidationMessage(`Reason required`);
                    return;
                }
                return await updateFtmApproveAndReject(ftm_id, isApprove, reason);
            }
        }).then(async (result) => {
            if (result?.isConfirmed && result?.value?.status === "Success") {
                updateRefreshCount();
            }
        });
    };
    

    return (
        <Stack direction="row" spacing={2} justifyContent="right">
            {[
                {
                    startIcon: <CheckCircleIcon />,
                    color: "success",
                    disabled: isApproved,
                    name: isApproved ? "APPROVED" : "APPROVE",
                    functionValue: 1
                },
                {
                    startIcon: <CancelIcon />,
                    color: "error",
                    disabled: isRejected,
                    name: isRejected ? "REJECTED" : "REJECT",
                    functionValue: -1
                }
            ].map(({ startIcon, color, disabled, name, functionValue }) => (
                <Button 
                    variant="contained" 
                    startIcon={startIcon} 
                    color={color}
                    size="small"
                    onClick={() => handleFtmApproveAndReject(functionValue)}
                    disabled={disabled}
                >
                    {name}
                </Button>
            ))}
        </Stack>
    );
};

export default FooterBtn;