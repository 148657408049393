export const initialState = {
    is_loading: true,
    filer: 0,
    total_jobs: 0,
    total_pages: 0,
    current_page: 1,
    jobs_list: [],
    search_value: "",
    sort_key: '',
    sort_direction: ''
}