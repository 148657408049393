import React, { useContext } from "react";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid';
import Tooltip from '@mui/material/Tooltip';

import { Context } from "../Provider";

const CalendarView = () => {
    const context = useContext(Context);
    const { state, loadFTMHolidaysCalendar } = context;
    const { holiday_list } = state;

    return(
        <FullCalendar
            plugins={[ dayGridPlugin ]}
            initialView="dayGridMonth"
            editable={false}
            datesSet={(arg) => loadFTMHolidaysCalendar({
                start_date: arg.start, 
                end_date: arg.end
            })}
            events={holiday_list?.map(event => ({
                title: event.ftm_holiday_reason_concat,
                start: event.ftm_holiday_start_date,
                end: event.ftm_holiday_end_date,
                className: event.holiday_duration > 4 ? "bg-gradient-danger" : "bg-gradient-info",
                extendedProps: {
                    event: event
                }
            }))}
            eventContent={(eventInfo) => (
                <Tooltip title={`${eventInfo.event.extendedProps.event.ftm_holiday_reason}`} arrow>
                    <div>
                        <span>{eventInfo.event.title}</span>
                    </div>
                </Tooltip>
            )}
        />
    );
}

export default CalendarView