import { GET } from "../../../API/get";

export const fetchData = async ({page_number, search_value, sort_key, sort_direction}) => {
    let url = `all/running/jobs/${page_number}/10?search=${search_value}&sort_key=${sort_key}&sort_direction=${sort_direction}`;
    let response = await GET(url);

    if(response.status === "Success"){
        return response;
    }

    return null;
}