import React, { useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Alert from '@mui/material/Alert';
import { useForm } from 'react-hook-form';
import { Container } from 'react-bootstrap';
import { Stack, TextField } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { updateCertificate } from '../service';
import { theme } from '../../../theme';
import BtnSpinner from '../../../components/btnSpinner';
import Context from '../context';
import ModalTemplateWithForm from '../../../components/modal/modalTemplateWithForm';

const EditCertificate = () => {
    const {
        register,
        handleSubmit,
        reset
    } = useForm();

    const context = useContext(Context);

    return (
        <ModalTemplateWithForm
            show={context.state.is_show_edit_certificate_types} 
            closeFun = {() => context.dispatch({
                type: 'IS_SHOW_EDIT_CERTIFICATE_TYPES', 
                is_show_edit_certificate_types: false,
                selected_certificate_type: {}
            })}
            size = {null}
            centered = {false}
            fullscreen = {false}
            dialogClassName = {null}
            title = {`Edit ${context.state?.selected_certificate_type?.documents_types_name}`}
            body = {
                <Container>
                    {context.state.error !== null ? (
                        <Stack sx={{ width: '100%' }} spacing={2} className="mb-3">
                            <Alert severity="error">{context.state.error}</Alert>
                        </Stack>
                    ) : null}

                    <label style={{ color: 'black' }}>NAME</label>
                    <TextField
                        placeholder="Certificate type name"
                        defaultValue={context.state?.selected_certificate_type?.documents_types_name}
                        fullWidth
                        size="small"
                        name="documents_types_name"
                        InputProps={{
                            style: { backgroundColor: '#fff' },
                        }}
                    />

                    <FormControlLabel 
                        control={
                            <Checkbox 
                                color={theme.main} 
                                name="is_mandatory"
                                defaultChecked = {context.state?.selected_certificate_type?.documents_types_is_required === 1 ? true : false}
                            />
                        } 
                        label="Mandatory" 
                    />
                </Container>
            }
            footer = {
                <>
                    <Button
                        variant={theme.close_btn}
                        onClick={() => context.dispatch({
                            type: 'IS_SHOW_EDIT_CERTIFICATE_TYPES', 
                            is_show_edit_certificate_types: false,
                            selected_certificate_type: {}
                        })}
                        size="sm"
                        disabled={context.state.btn_loading}
                    >
                        {context.state.btn_loading ? <BtnSpinner /> : 'CLOSE'}
                    </Button>
                    <Button
                        variant={theme.action_btn}
                        type="submit"
                        size="sm"
                        disabled={context.state.btn_loading}
                    >
                        {context.state.btn_loading ? <BtnSpinner /> : 'SAVE'}
                    </Button>
                </>
            }
            onSubmit = {(event) => updateCertificate(event, context.state?.selected_certificate_type, context.type, context.dispatch, reset)}
        />
    );
};

export default EditCertificate;