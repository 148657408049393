import React, { useContext } from "react";

import ImagesButton from "../../../components/imagesButton";
import { Context } from "../Provider";
import FtmOnlineOfflineSwitch from "./FtmOnlineOfflineSwitch";

const ProfileImage = () => {
    const context = useContext(Context);
    const { state } = context;
    const { ftm } = state;
    const {
        ftm_profile_image_path = "/assets/img/lock_user.png", 
        ftm_forename, 
        ftm_surname, 
        ftm_is_verify, 
        ftm_vehicle_number
    } = ftm;

    return (
        <>
            <div className="col-auto">
                <div className="avatar-xl position-relative">
                    <ImagesButton
                        images={[{ url: ftm_profile_image_path, title: 'View' }]}
                    />
                </div>
            </div>
            <div className="col-auto my-auto">
                <div className="h-100">
                    <h5 className="mb-1">
                        {ftm_forename} {ftm_surname}{" "}
                        {ftm_is_verify === 1 && (
                            <>
                                <i className="material-icons text-lg position-relative" style={{ color: 'green' }}>
                                    thumb_up
                                </i>
                                &nbsp;
                                <i className="material-icons text-lg position-relative">
                                    satellite_alt
                                </i>
                                <FtmOnlineOfflineSwitch />
                            </>
                        )}

                        {ftm_is_verify === -1 && (
                            <i className="material-icons text-lg position-relative" style={{ color: 'red' }}>
                                thumb_down
                            </i>
                        )}
                    </h5>
                    <p className="mb-0 font-weight-normal text-sm">
                        {ftm_vehicle_number}
                    </p>
                </div>
            </div>
        </>
    );
}

export default ProfileImage;
