import React, { useEffect, useState } from 'react';
import OffcanvasTemplate from '../../../../components/offcanvasTemplate';
import { fetchCertificatesList, fetchPersonalDocumentsList } from '../service';
import DivSpinner from '../../../../components/divSpinner';
import Gallery from '../../components/Gallery';

const ImageGallery = ({ftm_id, documentId, show, handleClose, isCertificates}) => {
    const [loading, setLoading] = useState(true);
    const [document, setDocument] = useState(null);

    useEffect(() => {
        const getData = async () => {
            try {
                setLoading(true);

                const fetchFunction = !isCertificates ? fetchPersonalDocumentsList : fetchCertificatesList;
                const response = await fetchFunction(documentId);

                setLoading(false);
                setDocument(response?.document);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        documentId > 0 && getData();
    }, [show, documentId, isCertificates]);

    return (
        <OffcanvasTemplate
            open = {show}
            handleClose = {handleClose} 
            placement = {'end'}  
            title = {"Image Gallery"}
            body = {loading ? <DivSpinner /> : (
                <Gallery
                    context={{
                        ftm_id: ftm_id,
                        document_type: isCertificates ? "certificates" : "personal",
                        state: {
                            document: document
                        },  
                    }}
                    isFtmDocumentView={false}
                />
            )} 
            footer = {
                <div className="container">
                    <div className="row" style={{marginTop: '10px'}}>
                        <div className="col">
                            <div className="d-grid gap-2 mb-3" >
                                {/* <FormSubmit/> */}
                            </div>
                        </div>
                    </div>
                </div>
            } 
        />
    );
};

export default ImageGallery;
