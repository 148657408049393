import React, { useContext, useState } from "react";
import {
    Box,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import {
    ScheduleSend as ScheduleSendIcon,
    FileUpload as FileUploadIcon,
    Visibility as VisibilityIcon,
    ThumbUp as ThumbUpIcon,
} from "@mui/icons-material";
import toast from "react-hot-toast";

import { Context } from "../components/Provider";
import { certificatesTableHeader } from "../components/certificates/tableHeader";
import { documentsTableHeader } from "../components/documents/tableHeader";
import { NoDataSub } from "./NoData";
import { convertTimestampToFormat } from "../../../../utils";
import SetExpiryDate from "../../view/components/SetExpiryDate";
import NewFileUpload from "./NewFileUpload";
import ImageGallery from "./Gallery";
import { commonSwal } from "../../../../components/swalAlert/swalAlert";
import { updateApproveDocument } from "../service";

const NewTable = ({ ftm, newDocuments }) => {
    const [dialogState, setDialogState] = useState({
        openImageGallery: false,
        newFileUpload: false,
        newFileUploadHeader: "",
        selectedDocumentId: 0,
    });

    const { isCertificates, handelUpdateRefreshingCount } = useContext(Context);
    const headers = isCertificates ? certificatesTableHeader : documentsTableHeader;

    // Helper function for toggling dialogs
    const handleDialogToggle = (dialogKey, state, id = 0, header = "") => {
        setDialogState((prev) => ({
            ...prev,
            [dialogKey]: state,
            selectedDocumentId: id,
            newFileUploadHeader: header,
        }));
    };

    // Approve document handler
    const handleApproveDocument = async (documentId) => {
        const documentType = isCertificates ? "certificate" : "personal";
        try {
            const result = await commonSwal({
                type: "APPROVE_FTM_DOCUMENT",
                showCancelButton: true,
                showLoaderOnConfirm: true,
                preConfirm: () => updateApproveDocument({ document_id: documentId, document_type: documentType }),
            });

            if (result.isConfirmed) {
                handelUpdateRefreshingCount();
                const message = result.value.message;
                result.value.status === "Success" ? toast.success(message) : toast.error(message);
            }
        } catch (error) {
            toast.error("An error occurred while approving the document.");
        }
    };

    // Render a single table row
    const Row = ({ row }) => {
        const documentData = {
            id: isCertificates ? row.ftm_document_id : row.ftm_personal_document_id,
            expiryDays: isCertificates ? row.ftm_document_days_until_expiry : row.ftm_personal_document_days_until_expiry,
            typeName: isCertificates
                ? row.ftm_document_type
                : row.personal_document_type?.personal_document_type_name,
            expiryDate: isCertificates ? row.ftm_document_expiry_date : row.ftm_personal_document_expiry_date,
            comment: row.ftm_personal_document_comment,
        };

        const listItems = [
            { subtitle: documentData.typeName, caption: isCertificates ? null : `#${documentData.id}` },
            {
                subtitle: `${ftm?.ftm_forename} ${ftm?.ftm_surname}`,
                caption: `#${ftm?.ftm_id} ${documentData.id}`,
            },
            { subtitle: convertTimestampToFormat(documentData.expiryDate), caption: null },
            { subtitle: documentData.expiryDays ?? "N/A", caption: null },
        ];

        return (
            <TableRow>
                <TableCell />
                {listItems.map((item, index) => (
                    <TableCell key={index}>
                        <Typography variant="subtitle2">{item.subtitle}</Typography>
                        {item.caption && (
                            <Typography variant="caption" display="block" gutterBottom>
                                {item.caption}
                            </Typography>
                        )}
                    </TableCell>
                ))}
                <TableCell>
                    <SetExpiryDate
                        listItem={{ name: "EXPIRED", icon: <ScheduleSendIcon /> }}
                        selectedDocument={{
                            document_id: documentData.id,
                            expiry_date: documentData.expiryDate,
                            comment: documentData.comment,
                        }}
                        document_type="personal"
                    />
                    <IconButton
                        onClick={() =>
                            handleDialogToggle(
                                "newFileUpload",
                                true,
                                documentData.id,
                                `${ftm?.ftm_forename} ${ftm?.ftm_surname} - ${documentData.typeName}`
                            )
                        }
                    >
                        <FileUploadIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDialogToggle("openImageGallery", true, documentData.id)}>
                        <VisibilityIcon />
                    </IconButton>
                </TableCell>
                <TableCell>
                    <IconButton onClick={() => handleApproveDocument(documentData.id)}>
                        <ThumbUpIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
        );
    };

    return (
        <Box sx={{ margin: 1 }}>
            <Typography variant="h6">New Document</Typography>
            <Table size="small" aria-label="documents">
                <TableHead>
                    <TableRow>
                        <TableCell />
                        {headers.map(
                            (item, index) =>
                                item.name !== "SERVICE CATEGORIES" && (
                                    <TableCell align={item.align} key={index}>
                                        {item.name}
                                    </TableCell>
                                )
                        )}
                        <TableCell />
                        <TableCell>ACTIONS</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {newDocuments ? <Row row={newDocuments} /> : <NoDataSub />}
                </TableBody>
            </Table>

            {/* File Upload Dialog */}
            <NewFileUpload
                headerName={dialogState.newFileUploadHeader}
                open={dialogState.newFileUpload}
                handleClose={() => handleDialogToggle("newFileUpload", false)}
                selectedDocumentId={dialogState.selectedDocumentId}
                isCertificates={isCertificates}
            />

            {/* Image Gallery Dialog */}
            <ImageGallery
                ftm_id={ftm?.ftm_id}
                documentId={dialogState.selectedDocumentId}
                show={dialogState.openImageGallery}
                handleClose={() => handleDialogToggle("openImageGallery", false)}
                isCertificates={isCertificates}
            />
        </Box>
    );
};

export default NewTable;
