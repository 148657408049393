import Swal from "sweetalert2";
import { alertIcons, alertSettings } from "./alertSettings";
import { triggerDateSelect } from "@fullcalendar/core/internal.js";

export const  commonSwal = (options) => {
    let msgSettings = alertSettings[options.type] || null;

    if(options.type === "ERROR_ALERT"){
        msgSettings = {
            type: 'ERROR',
            title: 'ERROR',
            text: options.text,
            iconHtml: alertIcons[options.icon]
        }
    }
    
    return Swal.fire({
        ...msgSettings,
        imageUrl: options.imageUrl || null,
        imageWidth: options.imageWidth || null,
        imageHeight: options.imageHeight || null,
        imageAlt: options.imageAlt || '',
        showConfirmButton: options.showConfirmButton !== undefined ? options.showConfirmButton : true,
        showCancelButton: options.showCancelButton || false,
        showLoaderOnConfirm: options.showLoaderOnConfirm || false,
        showLoaderOnDeny: options.showLoaderOnDeny || false,
        showDenyButton: options.showDenyButton || false,
        preConfirm: options.preConfirm || null,
        preDeny: options.preDeny || null,
        allowOutsideClick: options.preConfirm !== undefined ? () => !Swal.isLoading() : true,
        didOpen: options.didOpen || null,
        // input: options.input || null,
        // inputAttributes: options.inputAttributes || null,
        customClass: {
            icon: 'alert-custom-icon'
        }
    });
}