import React, { createContext, useEffect, useReducer } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";

import Reducer from "./reducer";
import { deleteDevice, fetchFtm, fetchFTMHolidaysCalendar, fetchSkills, trackerDispatch, updateDevice, updateFtmAvailability, updateLastMessageSeenUser } from "./service";
import toast from "react-hot-toast";
import { commonSwal } from "../../components/swalAlert/swalAlert";
import { initialState } from "./initialState";

const Context = createContext();

const Provider = ({ children }) => {
    const { ftm_id } = useParams();
    const [state, dispatch] = useReducer(Reducer, initialState);

    useEffect(() => {
        const getData = async () => {
            dispatch({ type: 'IS_LOADING' });

            try {
                const [ftm, skills] = await Promise.all([fetchFtm(ftm_id), fetchSkills()]);
                dispatch({ type: "IS_LOADED", ftm, skills });
            } catch (error) {
                console.log(error);
            }
        };

        getData();
    }, [ftm_id, state.ftm_edit_count, state.refresh_count]);

    const setupDevice = async (ftm_device_id) => {
        commonSwal({
            type: 'UPDATE_DEVICE',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: async (device_id) => {
                if (device_id === "" || device_id === ftm_device_id) {
                    Swal.showValidationMessage(`Invalid Device ID`);
                } else {
                    return await updateDevice(ftm_id, device_id);
                }
            },
        }).then(async (result) => {
            if (result.isConfirmed && result.value.status === "Success") {
                dispatch({ type: "SCROLL_IN_TO_VIEW", scroll_to_tracker_view: true });
                toast.success(result.value.message);
                dispatch({ type: "SET_FTM_EDIT_COUNT" });
            }
        });
    };

    const removeDevice = async () => {
        commonSwal({
            type: 'REMOVE_DEVICE',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => deleteDevice(ftm_id)
        }).then(async (result) => {
            if (result.isConfirmed && result.value.status === "Success") {
                dispatch({ type: "SCROLL_IN_TO_VIEW", scroll_to_tracker_view: true });
                toast.success(result.value.message);
                dispatch({ type: "SET_FTM_EDIT_COUNT" });
            }
        });
    };

    const ftmTrackerDispatch = async () => {
        commonSwal({
            type: 'FTM_TRACKER_DISPATCH',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => trackerDispatch(ftm_id)
        }).then(async (result) => {
            if (result.isConfirmed && result.value.status === "Success") {
                dispatch({ type: "SCROLL_IN_TO_VIEW", scroll_to_tracker_view: true });
                dispatch({ type: "SET_FTM_EDIT_COUNT" });
                toast.success(result.value.message);
            } else {
                toast.error(result.value.message);
            }
        });
    };

    const openSkillModal = () => dispatch({ type: "IS_SHOW_SKILL_MODAL", is_show_skill_modal: true });
    const closeSkillModal = () => dispatch({ type: "IS_SHOW_SKILL_MODAL", is_show_skill_modal: false });

    const updateRefreshCount = () => dispatch({ type: "UPDATE_REFRESH_COUNT"});

    const loadFTMHolidaysCalendar = async ({start_date, end_date}) => {
        const startTimestamp = new Date(start_date).getTime();
        const endTimestamp = new Date(end_date).getTime();
    
        const response = await fetchFTMHolidaysCalendar({ftm_id, start_date: startTimestamp, end_date: endTimestamp});
        console.log('ftm holidays: ', response.holiday_list);
        if(response?.status === "Success"){
            dispatch({
                type: "LOAD_FTM_HOLIDAYS_CALENDAR", 
                holiday_list: response.holiday_list
            });
        }
    }

    const openChatView = async (document_id) => {
        await updateLastMessageSeenUser(document_id);
        dispatch({
            type: 'IS_SHOW_CHAT', 
            is_show_chat: true
        });
    }

    const openEditView = () => dispatch({
        type: 'IS_SHOW_EDIT_FTM', 
        is_show_edit_ftm: true
    });

    const handleIsShowEditFtm = () => dispatch({
        type: 'IS_SHOW_EDIT_FTM', 
        is_show_edit_ftm: false
    });

    return (
        <Context.Provider 
            value={{ 
                ftm_id, 
                state, 
                dispatch, 
                setupDevice, 
                removeDevice, 
                ftmTrackerDispatch, 
                openSkillModal, 
                closeSkillModal, 
                updateRefreshCount, 
                loadFTMHolidaysCalendar,
                openChatView,
                openEditView,
                handleIsShowEditFtm
            }}
        >
            {children}
        </Context.Provider>
    );
};

export { Context, Provider };