export const documentsTableHeader = [
    {
       name: "DOCUMENT",
       align: "left", 
    },
    {
        name: "TRADEPERSON",
        align: "left",
    },
    {
        name: "EXPIRED",
        align: "left",
    },
    {
        name: "DAYS",
        align: "left",
    }
];