import { GET } from "../../API/get";
import { PUT } from "../../API/put";

export const fetchFtms = async (page_number, serach_text, sort_key, sort_direction) => {
    const response = await GET(`pending/applications/${page_number}?search=${serach_text.trim()}&sort_key=${sort_key}&sort_direction=${sort_direction}`);
    return response.status === "Success" ? response : null;
}

export const ftmReview = async (ftm_id) => {
    const response = await PUT(`ftm/review`,{
        ftm_id: ftm_id
    });

    return response.status === "Success" ? response : null;
}