import React, { useContext } from "react";
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { theme } from "../../../theme";
import { Context } from "../Provider";

const ConnectTracker = () => {
    const context = useContext(Context);
    const { state, setupDevice, removeDevice } = context;
    const { ftm } = state;
    const { ftm_device_id } = ftm;

    return (
        <div className="col-md-12 mb-lg-0 mb-4">
            <div className="card card-plain">
                <div className="card-body p-3">
                    <div className="row">
                        <div className="col-md-12 mb-md-0 mb-4">
                            <div
                                className="card card-body border card-plain border-radius-lg d-flex align-items-center flex-row"
                                style={{
                                    paddingTop: '14px',
                                    paddingBottom: '14px',
                                    paddingLeft: '1.5rem',
                                    paddingRight: '1.5rem'
                                }}
                            >
                                <img className="w-7 me-3 mb-0" src="/assets/img/gps_tracker.png" alt="logo" />
                                <h5 className="mb-0">{ftm_device_id !== "" ? ftm_device_id : "NOT SET DEVICE"}</h5>
                                <div className="d-flex flex-column ms-auto">
                                    <Stack direction="row" spacing={1}>
                                        <EditIcon style={{cursor: 'pointer'}} onClick={() => setupDevice(ftm_device_id)}/>
                                        <DeleteIcon style={{cursor: 'pointer', color: theme.delete_btn_code}} onClick={() => removeDevice()}/>
                                    </Stack>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ConnectTracker;