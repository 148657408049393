export const initialState = {
    is_loading: true,
    ftm: {},
    ftm_job_count: 0,
    monthly_income: {},
    all_income: {},
    documents: [],
    ftm_skills: [],
    skills: [],
    search_type: "",
    is_show_skill_modal: false,
    selected_skills: null,
    selected_sub_skills: null,
    selected_document_type: null,
    uploaded_document: null,
    is_sending_req: false,
    ftm_profile_image: null,
    show_edit_canvas: false,
    is_saving_edit_ftm_info: false,
    selected_address_option: null,
    is_open_logs: false,
    selected_availability_status: 1,
    is_open_ftm_note: false,
    is_show_chat: false,
    is_show_ftm_attachments: false,
    is_show_edit_ftm: false,
    ftm_edit_count: 0,
    scroll_to_documents_view: false,
    scroll_to_tracker_view: false,
    scroll_to_footer_btns_view: false,
    refresh_count: 0,
    selected_personal_document: null,
    selected_certificates: null,
}