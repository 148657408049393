import { GET } from "../../../API/get";

export const loadData = async (option) => {
    option.dispatch({ type: "IS_LOADING" });
    let response = await GET(`ftm/cancel/job/list/${option.ftm_id}/${option.page_number}?sort_key=${option.sort_key}&sort_direction=${option.sort_direction}`);

    if(response.status === "Success"){
        option.dispatch({ 
            type: "IS_LOADED", 
            total_jobs: response.total_jobs, 
            total_pages: response.total_pages, 
            ftm: response.details.ftm, 
            jobs_list: response.details.list, 
            current_page: option.page_number 
        });
    }
}