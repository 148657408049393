import React from "react";
import { TableCell, TableRow, Typography } from "@mui/material";

const NoDataMessage = ({ colSpan, message, variant = "body1" }) => (
    <TableRow>
        <TableCell colSpan={colSpan} align="center">
            <Typography variant={variant} className='remedial-table-content'>
                {message || "Sorry, no data found!"}
            </Typography>
        </TableCell>
    </TableRow>
);

const NoDataMain = () => <NoDataMessage colSpan={9} />;
const NoDataSub = () => <NoDataMessage colSpan={4} variant="subtitle2" />;

export { NoDataMain, NoDataSub };