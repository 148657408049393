export default function reducer(prevState, action){
    switch (action.type) {
        case "IS_LOADING":
            return {
                ...prevState,
                is_loading: true,
            };
        case "IS_LOADED_OLD": 
            return {
                ...prevState,
                ftm: action.ftm,
                ftm_job_count: action.ftm_job_count,
                monthly_income: action.monthly_income,
                all_income: action.all_income,
                documents: action.documents,
                skills: action.skills,
                ftm_skills: action.ftm_skills,
                is_loading: false,
            };
        case "IS_LOADED": 
            return {
                ...prevState,
                is_loading: false,
                ftm: action.ftm,
                skills: action.skills
            };
        case "IS_SHOW_SKILL_MODAL":
            return {
                ...prevState,
                is_show_skill_modal: action.is_show_skill_modal,
                uploaded_document: null
            }; 
        case "SELECTED_SKILL":
            return {
                ...prevState,
                selected_skills: action.selected_skills
            };
        case "SELECTED_SUB_SKILL":
            return {
                ...prevState,
                selected_sub_skills: action.selected_sub_skills
            };
        case "SELECTED_DOCUMENT_TYPE":
            return {
                ...prevState,
                selected_document_type: action.selected_document_type
            };
        case "UPLOADED_DOCUMENT":
            return {
                ...prevState,
                uploaded_document: action.uploaded_document
            };
        case "IS_SENDING_REQ":
            return {
                ...prevState,
                is_sending_req: action.is_sending_req
            };
        case "RESET_ADD_SKILLS":
            return {
                ...prevState,
                is_sending_req: false,
                is_show_skill_modal: false,
                selected_skills: null,
                selected_sub_skills: null,
                selected_document_type: null,
                uploaded_document: null
            };
        case "SET_FTM_PROFILE_IMAGE":
            return {
                ...prevState,
                ftm_profile_imag_de: action.ftm_profile_image
            };
        case "SHOW_EDIT_CANVAS":
            return {
                ...prevState,
                show_edit_canvas: action.show_edit_canvas
            };
        case "SELECTED_ADDRESS_OPTION":
            return {
                ...prevState,
                selected_address_option: action.selected_address_option
            };
        case "IS_SAVING_EDIT_FTM_INFO":
            return {
                ...prevState,
                is_saving_edit_ftm_info: action.is_saving_edit_ftm_info
            };
        case "IS_OPEN_LOGS":
            return {
                ...prevState,
                is_open_logs: action.is_open_logs
            };
        case "SELECTED_AVAILABILITY_STATUS":
            return {
                ...prevState,
                selected_availability_status: action.selected_availability_status
            };
        case "IS_SHOW_SET_EXPIRY_DATE": 
            return {
                ...prevState,
                is_show_set_expiry_date: action.is_show_set_expiry_date,
                ftm_document_id: action.ftm_document_id,
                ftm_document_expiry_date: action.ftm_document_expiry_date,
                ftm_document_comment: action.ftm_document_comment
            }; 
        case "RESET_SET_EXPIRY_DATE":
            return {
                ...prevState,
                is_sending_req: false,
                is_show_set_expiry_date: false,
                ftm_document_id: null,
                ftm_document_expiry_date: null,
                ftm_document_comment: null,
                scroll_to_documents_view: true
            };
        case "IS_OPEN_FTM_NOTE":
            return {
                ...prevState,
                is_open_ftm_note: action.is_open_ftm_note
            };
        case "IS_SHOW_CHAT":
            return {
                ...prevState,
                is_show_chat: action.is_show_chat,
            };
        case "ADMIN_UNREAD_MESSAGE_COUNT":
            return {
                ...prevState,
                admin_unread_message_count: action.admin_unread_message_count,
            };
        case "IS_OPEN_TRADESPERSON_HOLIDAYS":
            return {
                ...prevState,
                is_open_tradesperson_holidays: action.is_open_tradesperson_holidays
            };
        case "LOAD_FTM_HOLIDAYS_CALENDAR":
            return {
                ...prevState,
                holiday_list: action.holiday_list
            };
        case "IS_SHOW_FTM_ATTACHMENTS":
            return {
                ...prevState,
                is_show_ftm_attachments: action.is_show_ftm_attachments
            };
        case "IS_SHOW_EDIT_FTM":
            return {
                ...prevState,
                is_show_edit_ftm: action.is_show_edit_ftm
            };
        case "SET_FTM_EDIT_COUNT":
            return {
                ...prevState,
                is_show_edit_ftm: false,
                // refresh_count: prevState.refresh_count+1,
                ftm_edit_count: prevState.ftm_edit_count+1
            };
        case "SCROLL_IN_TO_VIEW":
            return {
                ...prevState,
                scroll_to_documents_view: action.scroll_to_documents_view,
                scroll_to_tracker_view: action.scroll_to_tracker_view,
                scroll_to_footer_btns_view: action.scroll_to_footer_btns_view,
            };
        case "UPDATE_REFRESH_COUNT":
            return {
                ...prevState,
                refresh_count: prevState.refresh_count+1
            };
        case "IS_SHOW_PERSONAL_DOCUMENTS":
            return {
                ...prevState,
                is_show_personal_documents: action.is_show_personal_documents
            };
        case "SET_SELECTED_PERSONAL_DOCUMENT":
            return {
                ...prevState,
                is_show_personal_documents: action.selected_personal_document === null ? false : true,
                selected_personal_document: action.selected_personal_document
            };
        case "IS_SHOW_CERTIFICATES":
            return {
                ...prevState,
                is_show_certificates: action.is_show_certificates
            };
        case "SET_SELECTED_CERTIFICATES":
            return {
                ...prevState,
                is_show_certificates: action.selected_certificates === null ? false : true,
                selected_certificates: action.selected_certificates
            };
        default:
            console.log("action.type: ", action.type)
            throw new Error();
    }
}