const API_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;
const DOMAIN  = process.env.REACT_APP_DOMAIN;

export const map_settings = {
    ftm_map_icon: `${DOMAIN}map-icons/ftm.png`,
    ftm_deactivate_map_icon: `${DOMAIN}map-icons/ftm_deactivate.png`,
    loading_map_icon: 'http://apiv2.myflobot.com/filed-team-tracking/job_loading.gif',
    customer_map_icon: `${DOMAIN}map-icons/customer.png`,
    map_script_url: `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places,marker`
}

export const loadScript = (url, onLoad, onError) => {
    const existingScript = document.getElementById("google-maps-script");
    if (existingScript) {
        if (onLoad) onLoad();
        return;
    }

    const script = document.createElement("script");
    script.src = url;
    script.id = "google-maps-script";
    script.async = true;
    script.onload = onLoad;
    script.onerror = onError || (() => console.error("Failed to load script."));
    document.head.appendChild(script);
};

export const createMarkerContent = (iconURL) => {
    const div = document.createElement("div");
    div.style.backgroundImage = `url(${iconURL})`;
    div.style.backgroundSize = "cover";
    div.style.width = "55px";
    div.style.height = "55px";
    return div;
};