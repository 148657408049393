import React, { useContext, useState } from "react";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";

import { Context } from "../Provider";
import { commonSwal } from "../../../components/swalAlert/swalAlert";
import { updateFtmAvailability } from "../service";
import { theme } from "../../../theme";

const FtmOnlineOfflineSwitch = () => {
    const context = useContext(Context);
    const { state, updateRefreshCount } = context;
    const { ftm } = state;
    const { ftm_id, ftm_is_online } = ftm;

    // Manage the state for the Switch component
    const [isOnline, setIsOnline] = useState(ftm_is_online === 1);

    const handleFtmAvailability = async (newIsOnline) => {
        try {
            const result = await commonSwal({
                type: newIsOnline ? "UPDATE_FTM_AVAILABILITY_OFFLINE" : "UPDATE_FTM_AVAILABILITY_ONLINE",
                showCancelButton: true,
                showLoaderOnConfirm: true,
                preConfirm: async () => updateFtmAvailability(ftm_id, newIsOnline),
            });

            if (result.isConfirmed) {
                updateRefreshCount();
                setIsOnline(newIsOnline); // Update the state if confirmed
            } else {
                setIsOnline(!newIsOnline); // Revert the state if not confirmed
            }
        } catch (error) {
            console.log(error);
            setIsOnline(!newIsOnline); // Revert the state on error
        }
    };

    return (
        <Tooltip title={isOnline ? "Online" : "Offline"} arrow>
            <Switch
                checked={isOnline} // Controlled state
                sx={{ fontSize: "small" }}
                color={theme.main}
                onChange={(event) => handleFtmAvailability(event.target.checked)} // Update state based on user interaction
            />
        </Tooltip>
    );
};

export default FtmOnlineOfflineSwitch;