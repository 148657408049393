import React, { useContext } from "react";

import Context from "../context";
import { textReplace, convertMinutesToHMMS } from "../../../utils";
import ModalTemplate from "../../../components/modal/modalTemplate";

const JobStatusChangeLogs = () => {
    const context = useContext(Context);

    return (
        <ModalTemplate
            show = {context.state.is_show_job_timeline}
            closeFun = {() => context.dispatch({ type: 'IS_SHOW_JOB_TIMELINE', is_show_job_timeline: false })}
            size = {null}
            centered = {true}
            title = {"JOB TIMELINE"}
            body = {
                <div className={`col-12 col-xl-12`}>
                    <div className="card card-plain h-100">
                        <div className="card-body p-3" style={{ overflow: 'auto', height: '350px' }}>
                            <div className="timeline timeline-one-side" data-timeline-axis-style="dotted">
                                {context.state.job_status_change_logs.map((item, index) => (
                                    <div className="timeline-block mb-4" key={index}>
                                        <span className="timeline-step p-3" style={{ backgroundColor: item.job_status.job_status_color }}>
                                            <i className="material-icons text-white text-sm opacity-10">
                                                {item.job_status.job_status_icon}
                                            </i>
                                        </span>
                                        <div className="timeline-content pt-1">
                                            <h6 className="text-dark text-sm font-weight-bold mb-0">
                                                {textReplace({ text: item.job_status.job_status_name, search: "[[FTM]]", replace: context.state?.ftms?.ftm_forename + " " + context.state?.ftms?.ftm_surname })}
                                            </h6>
                                            <p className="text-dark text-sm mt-1 mb-0">{convertMinutesToHMMS(item.job_status_time_period)}</p>
                                            {/* <p className="text-sm text-dark mt-3 mb-2">
                                                TIME STAMP : {item.job_status_change_log_timestamp}
                                            </p> */}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            }
            footer = {null}
        />
    );
};

export default JobStatusChangeLogs;