import React, { useContext } from "react";
import { Context } from "../../Provider";

const Profile = () => {
    const context = useContext(Context);
    const { state } = context;
    const { selected_ftm } = state;

    return(
        <div className="col-lg-12 col-sm-6 mb-xl-0 mb-4">
            <div className="card">
                <div className="card-header p-3 pt-2">
                    <div className="icon icon-lg icon-shape text-center border-radius-xl mt-n4 position-absolute">
                        <img 
                            src={selected_ftm?.ftm_profile_image_path || "/assets/img/lock_user.png"} 
                            className="avatar avatar-lg  border-radius-lg" 
                            alt="user1"
                        />
                    </div>
                    <div className="text-end pt-1">
                        <span 
                            className={`badge badge-sm bg-gradient-info`}
                        >
                            NEW TRADESPERSON
                        </span>
                        <h4 className="mb-0">
                            {selected_ftm?.ftm_forename} {selected_ftm?.ftm_surname}
                        </h4>
                    </div>
                </div>
                <hr className="dark horizontal my-0"/>
                <div className="card-footer p-3">
                    <p className="mb-0">
                        <span className="text-dark text-sm font-weight-bolder">#TRADESPERSON </span>{selected_ftm?.ftm_id}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Profile;