export default function Reducer(prevState, action){
    switch (action.type) {
        case "IS_LOADING":
            return {
                ...prevState,
                is_loading: true,
            };
        case "IS_LOADED": 
            return {
                ...prevState,
                is_loading: false,
                total_ftms: action.total_ftms,
                total_pages: action.total_pages,
                ftms: action.ftms
            }; 
        case "SELECTED_FTM":
            return {
                ...prevState,
                selected_ftm: action.selected_ftm,
                is_open_previous_details: true
            } 
        case "IS_OPEN_PREVIOUS_DETAILS":
            return {
                ...prevState,
                is_open_previous_details: action.is_open_previous_details
            } 
        case "UPDATE_CURRENT_PAGE":
            return {
                ...prevState,
                page_number: action.page_number
            } 
        case "SET_SERACH_TEXT":
            return {
                ...prevState,
                page_number: 1,
                serach_text: action.serach_text,
            }; 
        case "SET_SORT_CONFIG":
            return {
                ...prevState,
                sort_key: action.sort_key,
                sort_direction: action.sort_direction,
            }; 
        default:
            return prevState;
    }
}