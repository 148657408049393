import React, { createContext, useEffect, useReducer } from "react";
import { initialState } from "./initialState";
import Reducer from "./reducer";
import { fetchData, sendFtmAttachment } from "./service";
import toast from "react-hot-toast";

const Context = createContext();

const Provider = ({ ftm_id, children }) => {
    const [state, dispatch] = useReducer(Reducer,initialState);

    useEffect(() => {
        const getData = async () => {
            try{
                dispatch({ type: "IS_LOADING" });
                let response = await fetchData({
                    ftm_id : ftm_id
                });

                dispatch({ 
                    type: "IS_LOADED", 
                    attachments: response.attachments, 
                });
            }catch(error){
                console.log(error);
            }
        };

        getData();
    }, [ftm_id, state.new_attachment_count]); 

    const submitAttachments = async (data, reset) => {
        console.log("🚀 ~ submitAttachments ~ data:", data)

        if(state.uploaded_document == null){
            toast.error("Attachment is required");
        }else{
            dispatch({ 
                type: "IS_LOADING_SAVE_BTN", 
                is_loading_save_btn: true, 
            });

            let response = await sendFtmAttachment({
                ftm_id: ftm_id, 
                data: data, 
                attachment: state.uploaded_document
            });

            if(response.status === "Success"){
                reset(); 
                dispatch({ 
                    type: "ADDED_NEW_ATTACHMENT", 
                    new_attachment_count: state.new_attachment_count+1, 
                });
                toast.success(response.message);
            }else{
                toast.error(response.message);
            }
        }
    }

    return (
        <Context.Provider value={{state, dispatch, submitAttachments}}>
            {children}
        </Context.Provider>
    );
}

export { Context, Provider };