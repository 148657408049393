import React, { useContext } from "react";
import { Pagination, Stack } from "@mui/material";

import Template from "../../components/template";
import Table from "./components/Table";
import CardWithHeaderTemplate from "../../components/cardWithHeaderTemplate";
import Search from "../../components/searchField";
import { Context } from "./Provider";
import { theme } from "../../theme";
import PreviousDetails from "./components/previousDetails";

const Html = () => {
    const context = useContext(Context);
    const { state, dispatch } = context;
    const { is_loading, total_pages, page_number } = state;

    const PaginationView = () => {
        return(
            <>
                {!is_loading && (
                    <Stack
                        spacing={2}
                        className="mb-3"
                        style={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        <Pagination
                            count={total_pages}
                            page={page_number}
                            onChange={(event, newPage) => dispatch({ type: 'UPDATE_CURRENT_PAGE', page_number: newPage})}
                            color={theme.main}
                        />
                    </Stack>
                )}
            </>
        );
    }

    return(
        <Template
            children={
                <div className="container-fluid py-4">
                    <div className="row">
                        <div className="col-12">
                            <CardWithHeaderTemplate
                                header={
                                    <div className="row">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <h6 className="text-white text-capitalize ps-3">PENDING APPLICATION</h6>
                                            </div>
                                            <div className="col-md-6">
                                                <Search
                                                    onSearch={(search_text) => {
                                                        dispatch({
                                                            type: 'SET_SERACH_TEXT',
                                                            serach_text: search_text
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }
                                body={<Table />}
                                footer={<PaginationView/>}
                                cardHeight={'450px'}
                            />
                        </div>
                    </div>

                    <PreviousDetails/>
                </div>
            }
        />
    );
}

export default Html;