import React from "react";
import FtmLiveMap from "./ftmLiveMap";

const Map = () => {
    return (
        <div className="card h-100">
            <div className="card-header pb-0 p-3">
                <div className="d-flex justify-content-between">
                    <h6 className="mb-0">TRADESPERSONS LIVE MAP</h6>
                </div>
            </div>
            <div className="card-body p-3">
                {/* <div ref={ref} style={{ width: '100%', height: 400 }} /> */}
                <FtmLiveMap/>
            </div>
        </div>
    );
}

export default Map;