import React, { useContext } from "react";
import Context from "../context";
import Search from "../../../components/searchField";

const HeaderView = () => {
    const context = useContext(Context);

    const generateHeaderTitle = (nav_type) => {
        let headerTitle = "";
        if (nav_type === 'notpaid') {
            headerTitle = "NOT PAID";
        } else if (nav_type === 'paid') {
            headerTitle = "PAID";
        } else if (nav_type === 'indispute') {
            headerTitle = "IN DISPUTE";
        }

        return headerTitle;
    };

    return(
        <div className="row">
            <div className="col-lg-6 col-7">
                <h6 className="text-uppercase text-white text-capitalize ps-3">
                    COMPLETED JOBS - {generateHeaderTitle(context.state.nav_type)}
                </h6>
            </div>
            <div className="col-lg-6 col-5 my-auto text-end">
                <Search
                    onSearch = {(search_text) => {
                        context.dispatch({ type: "SET_SERACH_VALUE", search_value: search_text });
                    }}
                />
            </div>
        </div>
    );
}

export default HeaderView;