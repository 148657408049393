import React, { useContext } from "react";
import CardWithHeaderTemplate from "../../../components/cardWithHeaderTemplate";
import { Context } from "../Provider";

const FtmInfo = () => {
    const context = useContext(Context);
    const { state } = context;
    const { ftm } = state;
    const { ftm_forename, ftm_surname, ftm_street_address, ftm_place, ftm_postcode, ftm_email, ftm_contact_number, ftm_birth_of_date, ftm_gender } = ftm;

    return (
        <CardWithHeaderTemplate
            header={
                <div className="row">
                    <div className="col-8 my-auto">
                        <div className="numbers">
                            <h6 className="text-white font-weight-bolder mb-0 ms-3">
                                PERSONAL INFORMATION
                            </h6>
                        </div>
                    </div>
                </div>
            }
            body={
                <ul className="list-group">
                    <li className="list-group-item border-0 ps-0 pt-0 text-sm">
                        <strong className="text-dark">Full Name:</strong>
                        &nbsp; {ftm_forename} {ftm_surname}
                    </li>
                    <li className="list-group-item border-0 ps-0 text-sm">
                        <strong className="text-dark">Address:</strong>
                        &nbsp; {ftm_street_address} {ftm_place} {ftm_postcode}
                    </li>
                    <li className="list-group-item border-0 ps-0 text-sm">
                        <strong className="text-dark">Email:</strong>
                        &nbsp; {ftm_email}
                    </li>
                    <li className="list-group-item border-0 ps-0 text-sm">
                        <strong className="text-dark">Phone number:</strong>
                        &nbsp; {ftm_contact_number}
                    </li>
                    <li className="list-group-item border-0 ps-0 text-sm">
                        <strong className="text-dark">Date of Birth:</strong>
                        &nbsp; {ftm_birth_of_date}
                    </li>
                    <li className="list-group-item border-0 ps-0 text-sm">
                        <strong className="text-dark">Gender:</strong>
                        &nbsp; {ftm_gender === 0 ? "Male" : ftm_gender === 1 ?  "Female" : "Non-binary"}
                    </li>
                </ul>
            }
            footer={null}
            extraCardClassName={`blur shadow-blur max-height-vh-70 h-100`}
            extraBodyClassName={`overflow-auto overflow-x-hidden`}
        />
    );
}

export default FtmInfo;