import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import { Toaster } from 'react-hot-toast';
import "./App.css";

import SnackbarAlert from "./components/snackbar";
import { getUserInfo } from "./storage";
import { routes } from "./routes";

function App() {
    const [snackbar, setSnackbar] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        const isForgotPasswordRoute = window.location.pathname.includes("/forgot/password/");
        const userInfo = getUserInfo();

        if (!isForgotPasswordRoute && !userInfo) {
            navigate("/auth");
        }
    }, [navigate]);

    return (
        <>
            <Routes>
                {routes.map((route, index) => (
                    <Route
                        key={index}
                        path={route.path}
                        element={
                            route.protected 
                                ? <ProtectedRoute element={route.element} roles={route.roles} />
                                : route.element
                        }
                    />
                ))}
            </Routes>

            <Toaster position="top-right" reverseOrder={false} />
            <SnackbarAlert
                open={snackbar?.is_show}
                handleClose={() => setSnackbar({})}
                type={snackbar?.type}
                msg={snackbar?.msg}
            />
        </>
    );
}

export default App;

const ProtectedRoute = ({ element, roles }) => {
    const userInfo = getUserInfo();

    if (!userInfo) {
        return <Navigate to="/auth" />;
    }

    if(!roles.includes(userInfo?.admin_role_id)){
        return <Navigate to="/access-denied" />;
    }

    return element;
};