import React, { useContext, useState, memo } from "react";
import { Collapse, IconButton, TableCell, TableRow, Typography, Tooltip } from "@mui/material";
import {
    ScheduleSend as ScheduleSendIcon,
    Chat as ChatIcon,
    Call as CallIcon,
    Visibility as VisibilityIcon,
    NotificationsActive as NotificationsActiveIcon,
    Alarm as AlarmIcon,
    Snooze as SnoozeIcon,
} from "@mui/icons-material";

import { Context } from "../Provider";
import { convertTimestampToFormat } from "../../../../../utils";
import NewTable from "../../common/NewTable";
import { theme } from "../../../../../theme";
import SetExpiryDate from "../../../view/components/SetExpiryDate";
import Chat from "../../../../../components/userChat/chat";
import ImageGallery from "../../common/Gallery";

const Render = ({ isCertificates }) => {
    const [chatState, setChatState] = useState({ open: false, ftm: null });
    const [ftmState, setFtmState] = useState({ open: false, ftm: null, documentId: null });

    const { state, handelUpdateRefreshingCount } = useContext(Context);
    const { documents } = state;

    const handleToggleChat = (ftm) =>
        setChatState({ open: !chatState.open, ftm: chatState.open ? null : ftm });

    const handleToggleDocument = (id, ftm) =>
        setFtmState({ open: !ftmState.open, ftm: ftmState.open ? null : ftm, documentId: id });

    const Row = memo(({ row }) => {
        const [open, setOpen] = useState(false);
        const {
            personal_document_type,
            ftm_personal_document_expiry_date,
            ftm,
            ftm_personal_document_days_until_expiry,
            ftm_personal_document_id,
            new_ftm_personal_document,
            ftm_personal_document_comment,
        } = row;

        const daysUntilExpiry = (ftm_personal_document_days_until_expiry) => (
            <Tooltip title={ftm_personal_document_days_until_expiry} arrow placement="right">
                {ftm_personal_document_days_until_expiry > 0 ? <AlarmIcon sx={{ color: 'green' }}/> : <SnoozeIcon sx={{ color: 'red' }}/>}
            </Tooltip>
        );

        const list = [
            {
                subtitle: personal_document_type?.personal_document_type_name,
                caption: `#${ftm_personal_document_id}`,
            },
            {
                subtitle: `${ftm?.ftm_forename} ${ftm?.ftm_surname}`,
                caption: `#${ftm?.ftm_id}`,
            },
            {
                subtitle: convertTimestampToFormat(ftm_personal_document_expiry_date),
                caption: null,
            },
            {
                subtitle: daysUntilExpiry(ftm_personal_document_days_until_expiry)  ?? "N/A",
                caption: null,
            },
        ];

        return (
            <>
                <TableRow className="remedial-table-row">
                    <TableCell>
                        {new_ftm_personal_document && (
                            <IconButton onClick={() => setOpen((prev) => !prev)}>
                                <NotificationsActiveIcon color={theme.main} />
                            </IconButton>
                        )}
                    </TableCell>
                    {list.map(({ subtitle, caption }, index) => (
                        <TableCell key={index}>
                            <Typography variant="subtitle2">{subtitle}</Typography>
                            {caption && (
                                <Typography
                                    variant="caption"
                                    display="block"
                                    className="remedial-table-content"
                                >
                                    {caption}
                                </Typography>
                            )}
                        </TableCell>
                    ))}
                    <TableCell>
                        <SetExpiryDate
                            listItem={{ name: "EXPIRED", icon: <ScheduleSendIcon /> }}
                            selectedDocument={{
                                document_id: ftm_personal_document_id,
                                expiry_date: ftm_personal_document_expiry_date,
                                comment: ftm_personal_document_comment,
                            }}
                            document_type="personal"
                            refresh={handelUpdateRefreshingCount}
                        />
                        <IconButton onClick={() => handleToggleChat(ftm)} variant={theme.set_expired_color}>
                            <ChatIcon />
                        </IconButton>
                        <IconButton variant={theme.set_expired_color}>
                            <CallIcon />
                        </IconButton>
                        <IconButton
                            onClick={() => handleToggleDocument(ftm_personal_document_id, ftm)}
                            variant={theme.set_expired_color}
                        >
                            <VisibilityIcon />
                        </IconButton>
                    </TableCell>
                </TableRow>
                {new_ftm_personal_document && (
                    <TableRow className="remedial-table-row" style={{ backgroundColor: "#ece7e7" }}>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={list.length + 1}>
                            <Collapse in={open} timeout="auto" unmountOnExit>
                                <NewTable ftm={ftm} newDocuments={new_ftm_personal_document} />
                            </Collapse>
                        </TableCell>
                    </TableRow>
                )}
            </>
        );
    });

    Row.displayName = "Row";

    return (
        <>
            {documents.map((document, index) => (
                <Row key={index} row={document} />
            ))}

            {chatState.ftm && (
                <Chat
                    user_name={`${chatState.ftm.ftm_forename} ${chatState.ftm.ftm_surname}`}
                    user_firebase_document_id={chatState.ftm.ftm_firebase_document_id}
                    show={chatState.open}
                    handleClose={() => setChatState({ open: false, ftm: null })}
                />
            )}

            <ImageGallery
                ftm_id={ftmState.ftm?.ftm_id}
                documentId={ftmState.documentId}
                show={ftmState.open}
                handleClose={() => setFtmState({ open: false, ftm: null, documentId: null })}
                isCertificates={isCertificates}
            />
        </>
    );
};

export default Render;