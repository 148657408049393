import React, { useContext } from "react";
import { Alert, Stack } from "@mui/material";
import { Context } from "../Provider";


const Notification = () => {
    const context = useContext(Context);
    const { state } = context;
    const { ftm } = state;
    const { ftm_is_verify, ftm_is_new, ftm_verify_skill, ftm_verify_details, ftm_verify_documents, ftm_reject_reason } = ftm;

    const getAlert = (alertType, msg) => (
        <Stack sx={{ width: '100%' }} spacing={2} className="mb-3">
            <Alert variant="filled" severity={alertType}>
                {msg}
            </Alert>
        </Stack>
    );

    // Checks if FTM is verified
    if (ftm_is_verify === 1) return null;

    // Logic for new tradesperson
    if (ftm_is_new === 1) {
        const isVerify = ftm_is_verify;
        const alertType = isVerify === -3 ? "info" : "warning";
        const msg = isVerify === -2 
            ? "The new tradesperson has not yet completed the required compliance process."
            : isVerify === -3
            ? "The tracker has been successfully dispatched."
            : "A new tradesperson application requires your review. Please take a moment to examine their associated information, documents, and certificates to determine their approval.";
        return getAlert(alertType, msg);
    }

    // Logic for existing tradesperson with updates
    if (ftm_is_new === -1) {
        const verifySkill = ftm_verify_skill;
        const verifyDetails = ftm_verify_details;
        const verifyDocuments = ftm_verify_documents;
        const rejectReason = ftm_reject_reason;

        if (verifySkill === 0) {
            return getAlert("warning", "The Tradesperson has updated their skills. Please review their changes and consider any necessary actions.");
        }

        if (verifyDetails === 0 && verifyDocuments === 0) {
            return getAlert("warning", "The Tradesperson has expired documents and has also updated their details. Please review the changes and address the expired documents promptly.");
        }

        if (verifyDocuments === 0) {
            return getAlert("warning", "A Tradesperson has documents that have expired. Please take prompt action to address this issue.");
        }

        if (verifyDetails === 0) {
            return getAlert("warning", "A Tradesperson has updated their details. Please review the changes for accuracy and completeness.");
        }

        return getAlert("warning", rejectReason ? rejectReason : "Waiting for the approval! Please approve the updated FTM's documents.");
    }

    return null;
};

export default Notification;