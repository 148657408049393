/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo, useCallback, useRef, useState } from 'react';
import { CardActions, IconButton } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import DescriptionIcon from '@mui/icons-material/Description';
import DeleteIcon from '@mui/icons-material/Delete';
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import CloseIcon from '@mui/icons-material/Close';

import { imageGallery } from './styles/imageGallery';
import { fetchFtmDocumentDownload, replaceDocument } from './service';
import { commonSwal } from '../../../components/swalAlert/swalAlert';

const Gallery = ({ context: { ftm_id, state: { document } = {}, document_type, removeDocument }, isFtmDocumentView, refresh = () => {}}) => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [replaceIndex, setReplaceIndex] = useState(null);
    const fileInputRef = useRef(null);

    const isPersonal = document_type === "personal";
    const documentFiles = isPersonal ? document?.ftm_personal_document_files : document?.ftm_document_files || [];
    const [images, setImages] = useState(documentFiles);

    const formattedExpiryDate = document?.[isPersonal ? 'ftm_personal_document_expiry_date' : 'ftm_document_expiry_date'] 
        ? new Date(document[isPersonal ? 'ftm_personal_document_expiry_date' : 'ftm_document_expiry_date']).toISOString().split('T')[0]
        : '';
    const documentComment = document?.[isPersonal ? 'ftm_personal_document_comment' : 'ftm_document_comment'];

    const handleImageClick = (item) => setSelectedImage(item);
    const handleCloseView = () => setSelectedImage(null);

    const downloadFile = useCallback(async ({ file_id, file_path }) => {
        await fetchFtmDocumentDownload({
            ftm_id,
            file_id,
            file_ext: file_path.split('.').pop().toLowerCase(),
            document_type: isPersonal ? 2 : 1,
        });
    }, [ftm_id, isPersonal]);

    const handleReplaceImageClick = (index) => {
        setReplaceIndex(index);
        fileInputRef.current.click();
    };

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        console.log("handleFileChange ", file);
        if (file && replaceIndex !== null) {

            const response =  await commonSwal({
                type: 'REMOVE_FTM_DOCUMENT',
                showCancelButton: true,
                showLoaderOnConfirm: true,
            });

            console.log("response : ", response);
    
            if (response.isConfirmed) {
                const newImageURL = URL.createObjectURL(file);
                const updatedImages = [...images];
                updatedImages[replaceIndex] = {
                    ...updatedImages[replaceIndex],
                    newFile: {
                        url: newImageURL,
                        isImage: /\.(jpeg|jpg|gif|png)$/i.test(file?.name)
                    },
                };
                setImages(updatedImages);
                setReplaceIndex(null);

                await replaceDocument({
                    ftm_id,
                    file_id: isPersonal ? updatedImages[replaceIndex].ftm_personal_document_file_id : updatedImages[replaceIndex].ftm_document_file_id,
                    file_type: isPersonal ? 0 : 1,
                    ftm_document: file
                });

                refresh();
            }
        }
    };

    const ImageItem = memo(({ item, index }) => {
        const fileId = isPersonal ? item.ftm_personal_document_file_id : item.ftm_document_file_id;
        console.log("item.new_file_url ", item.new_file_url);
        const filePath = item?.newFile?.url || (isPersonal ? item.ftm_personal_document_file_path : item.ftm_document_file_path);
        const isImage = item?.newFile?.url ? item?.newFile?.isImage : /\.(jpeg|jpg|gif|png)$/i.test(filePath);

        return (
            <div style={imageGallery.imageItem}>
                <a onClick={() => handleImageClick(item)} style={{ cursor: 'pointer' }} alt="imageView">
                    {isImage ? (
                        <img src={filePath} alt="Document" style={imageGallery.image} />
                    ) : (
                        <div style={imageGallery.pdfPlaceholder}>
                            <DescriptionIcon style={{ fontSize: '65px' }} />
                        </div>
                    )}
                </a>
                <div style={imageGallery.imageFooter}>
                    <CardActions disableSpacing style={{ padding: 0 }}>
                        {isFtmDocumentView && (
                            <IconButton
                                style={imageGallery.imageName}
                                onClick={() => handleReplaceImageClick(index)}
                            >
                                <CameraswitchIcon sx={{ fontSize: '20px' }} />
                            </IconButton>
                        )}
                    </CardActions>
                    <CardActions disableSpacing style={{ height: '40px', paddingRight: 0 }}>
                        <IconButton aria-label="download" onClick={() => downloadFile({ file_id: fileId, file_path: filePath })}>
                            <DownloadIcon sx={{ fontSize: '20px' }} />
                        </IconButton>
                        {isFtmDocumentView && (
                            <IconButton aria-label="delete" onClick={() => removeDocument({ file_id: fileId })}>
                                <DeleteIcon sx={{ fontSize: '20px' }} />
                            </IconButton>
                        )}
                    </CardActions>
                </div>
            </div>
        );
    });

    const SingleImageView = () => {
        const filePath = selectedImage ? (selectedImage?.newFile?.url || (isPersonal ? selectedImage.ftm_personal_document_file_path : selectedImage.ftm_document_file_path)) : '';
        const isImage = selectedImage?.newFile?.url ? selectedImage?.newFile?.isImage : /\.(jpeg|jpg|gif|png)$/i.test(filePath);

        return (
            <div style={imageGallery.singleImageViewOverlay}>
                <IconButton style={imageGallery.closeButton} onClick={handleCloseView}>
                    <CloseIcon />
                </IconButton>
                <div style={imageGallery.singleImageView}>
                    {isImage ? (
                        <img src={filePath} alt="Selected View" style={imageGallery.largeImage} />
                    ) : (
                        <iframe src={filePath} title="PDF Document" style={imageGallery.pdfViewer} />
                    )}
                </div>
            </div>
        );
    }

    return (
        <>
            {selectedImage ? (
                <SingleImageView/>
            ) : (
                <>
                    {isFtmDocumentView && formattedExpiryDate && (
                        <div style={imageGallery.galleryTextContainer} className="mb-3">
                            <div style={imageGallery.expiryDate}>
                                <strong>EXPIRY DATE: {formattedExpiryDate}</strong>
                            </div>
                            <div style={imageGallery.description}>{documentComment}</div>
                        </div>
                    )}
                    <div style={imageGallery.galleryContainer}>
                        <div style={imageGallery.imageGrid}>
                            {images?.length === 0 ? (
                                <p style={imageGallery.noDocuments}>N/A</p>
                            ) : (
                                images?.map((item, index) => <ImageItem key={index} item={item} index={index} />)
                            )}
                        </div>
                    </div>
                </>
            )}
            <input type="file"  ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} />
        </>
    );
};

export default Gallery;