import { useContext, useRef } from "react";
import Button from 'react-bootstrap/Button';

import ModalTemplate from "../../../components/modal/modalTemplate";
import { Context } from "./Provider";
import CheckboxesList from "./components/CheckboxesList";
import { theme } from "../../../theme";
import SkillsFileUpload from "./components/SkillsFileUpload";

const Html = ({ open, handleClose, ftmSkills }) => {
    const context = useContext(Context);
    const { state, handelBackStep, uploadFtmDocuments } = context;
    const { step, stepBackButtonDisable, ftm_updating } = state;

    // Use a ref to access the form submit function from the child component
    const submitRef = useRef(null);

    return (
        <ModalTemplate
            show={open}
            closeFun={handleClose}
            size={null}
            centered={true}
            title={"SERVICE CATEGORIES & TYPES"}
            body={
                <>
                    {step === 1 && (
                        <CheckboxesList 
                            submitRef={submitRef} 
                            ftmSkills={ftmSkills}
                        />
                    )}

                    {step === 2 && (
                        <SkillsFileUpload/>
                    )}
                </>
            }
            footer={
                <>
                    <Button variant={theme.close_btn} size="sm" disabled={stepBackButtonDisable || ftm_updating} onClick={handelBackStep}>
                        {ftm_updating ? 'Uploading...' : 'Back'}
                    </Button>
                    <Button variant={theme.main} size="sm" onClick={step === 1 ? () => submitRef.current?.click() : uploadFtmDocuments} disabled={ftm_updating}>
                        {ftm_updating ? 'Uploading...' : step === 1 ? 'Next' : 'Upload'}
                    </Button>
                </>
            }
        />
    );
};

export default Html;
