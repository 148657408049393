import { GET } from "../../API/get";

export const fetchData = async ({
    type,
    text,
    column,
    option,
    sort_key,
    sort_direction
}) => {
    let URL = `full/search?type=${type}&text=${text}&column=${column}&option=${option}&sort_key=${sort_key}&sort_direction=${sort_direction}`;
    let response = await GET(URL);

    if(response.status === "Success"){
        return response;
    }

    return null;
}