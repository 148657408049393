import React from "react";
import { Link } from "react-router-dom";

import Template from "../../components/template";
import { theme } from "../../theme";

const Html = () => {
    return (
        <Template>
            <div className="container-fluid py-4">
                <div className="row">
                    <div className="col-12">
                        <div className="d-flex align-items-center justify-content-center">
                            <div className="text-center">
                                <h1 className="display-1 fw-bold text-danger">403</h1>
                                <p className="fs-3">
                                    <span className="text-danger">Oops!</span> You don't have permission to access this page.
                                </p>
                                <p className="lead">
                                    The page you're trying to reach may not exist or you don't have the necessary permissions.
                                </p>
                                <Link to="/" className={`btn btn-${theme.main}`}>Return to Home</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Template>
    );
}

export default Html;