import React, { useContext, useState, useEffect } from "react";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import Context from "../context";
import { tableSortKeys } from "../../../../utils";

const ColumnSort = ({sortKeyName}) => {
    const context = useContext(Context);
    const [sortKey, setSortKey] = useState(null);

    useEffect(() => {
        let newSortKey = sortKeyName;
        if(typeof sortKeyName === 'object' && sortKeyName !== null) {
            if (sortKeyName.props.children !== null) {
                // Filter out non-string elements
                const filteredSortKeyArr = sortKeyName.props.children.filter(item => typeof item === 'string');
                // Join the filtered array
                newSortKey = filteredSortKeyArr.join(' ');
            }
        } 

        setSortKey(tableSortKeys(newSortKey));
    },[sortKeyName]);

    const onSort = (sortKeyName) => {
        let direction = "ASC";

        if (context.state.sort_key === sortKeyName && context.state.sort_direction === "ASC") {
          direction = "DESC";
        }

        if (sortKeyName) {
            context.dispatch({ 
                type: "SET_SORT_CONFIG", 
                sort_key: sortKeyName, 
                sort_direction: direction 
            });
        }
    };

    const getArrowIcon = (sortKeyName) => {
        if (context.state.sort_key === sortKeyName) {
          return context.state.sort_direction === "ASC" ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/>;
        }
        
        return "";
    };

    return(
        <>
            {sortKey ? (
                <div
                    onClick={() => onSort(sortKey)}
                    style={{ cursor: "pointer" }}
                >
                    {sortKeyName} {getArrowIcon(sortKey)}
                </div>
            ) : (
                <div style={{ cursor: "default" }}>
                    {sortKeyName}
                </div>
            )}
        </>
    );
}

export default ColumnSort;