import React, { useContext, useRef } from "react";

import { Context } from "../Provider";
import FtmMap from "./FtmMap";
import Notification from "./Notification";
import ProfileImage from "./ProfileImage";
import Btn from "./Btn";
import useWindowDimensions from "../../../components/hooks/useWindowDimensions";
import CardWithHeaderTemplate from "../../../components/cardWithHeaderTemplate";
import FtmInfo from "./FtmInfo";
import LicenceAndVehicleDetails from "./LicenceAndVehicleDetails";
import NextOfKin from "./NextOfKin";
import TaxAndInsuranceDetails from "./TaxAndInsuranceDetails";
import BusinessDetails from "./BusinessDetails";
import BankInfo from "./BankInfo";
import ConnectTracker from "./ConnectTracker";
import TrackerDispatch from "./TrackerDispatch";
import UploadPageImage from "./UploadPageImage";
import Skills from "./Skills";
import FooterBtn from "./FooterBtn";
import Documents from "../../documents/view/Documents";

const Profile = () => {
    const trackerRef = useRef(null);
    const { width } = useWindowDimensions();
    const { ftm_id, state } = useContext(Context);
    const { ftm } = state;
    const { ftm_is_verify, ftm_is_new } = ftm;

    const renderFtmMapOrEmpty = () => (
        ftm_is_verify !== 1 && ftm_is_new === 1
            ? <div className="min-height-100 border-radius-xl mt-4" style={{ minHeight: '50px !important' }} />
            : <FtmMap />
    );

    const renderSection = (leftComponent, rightComponent, index) => (
        <div className={`row ${index > 1 && 'mt-5'}`}>
            <div className="col-12 col-xl-6">{leftComponent}</div>
            <div className="col-12 col-xl-6">{rightComponent}</div>
        </div>
    );

    return (
        <div className="container-fluid px-2 px-md-4">
            {renderFtmMapOrEmpty()}
            <div className="card card-body mx-3 mx-md-4 mt-n6">
                <Notification />
                <div className="row gx-4 mb-2">
                    <ProfileImage />
                    <Btn />
                </div>

                <div className="row">
                    <div className="col-12 col-xl-12">
                        <div className="row">
                            <div className="col-12 col-xl-8">
                                <CardWithHeaderTemplate
                                    header={null}
                                    body={
                                        <>
                                            {renderSection(<FtmInfo />, <LicenceAndVehicleDetails />, 1)}
                                            {renderSection(<NextOfKin />, <TaxAndInsuranceDetails />, 2)}
                                            {renderSection(<BusinessDetails />, <BankInfo />, 3)}
                                        </>
                                    }
                                    footer={null}
                                    extraCardClassName="card-plain h-100"
                                    extraBodyClassName="overflow-auto overflow-x-hidden"
                                />
                            </div>
                            <div className="col-12 col-xl-4" style={{ marginTop: width < 1320 ? '50px' : '44px' }}>
                                <Skills />
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-xl-4 mt-5">
                        <Documents ftm_id={ftm_id} document_type="personal" />
                    </div>

                    <div className="col-12 col-xl-4 mt-5">
                        <Documents ftm_id={ftm_id} document_type="certificate"/>
                    </div>

                    <div className="col-12 col-xl-4 mt-5">
                        <CardWithHeaderTemplate
                            header={<HeaderTitle title="TRACKER" />}
                            body={
                                <div ref={trackerRef}>
                                    <ConnectTracker />
                                    <TrackerDispatch />
                                    <UploadPageImage ftm_id={ftm_id}/>
                                </div>
                            }
                            footer={null}
                            extraCardClassName="blur shadow-blur max-height-vh-70 h-100"
                            extraBodyClassName="overflow-auto overflow-x-hidden"
                        />
                    </div>
                </div>
                <FooterBtn/>
            </div>
        </div>
    );
};

const HeaderTitle = ({ title }) => (
    <div className="row">
        <div className="col-8 my-auto">
            <div className="numbers">
                <h6 className="text-white font-weight-bolder mb-0 ms-3">{title}</h6>
            </div>
        </div>
    </div>
);

export default Profile;