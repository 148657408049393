import React, { useState } from "react";
import Template from "../../../components/template";
import Expired from "./components/Expired";
import TabPanel from "./common/TabPanel";

const ExpiredDocuments = () => {
    const [tab, setTab] = useState("documents");

    return (
        <Template>
            <div className="container-fluid px-2 px-md-4">
                {/* <PageHeader/>
                <div className="card card-body mx-3 mx-md-4 mt-n6" style={{ backgroundColor: '#efefef' }}>
                    <div className="row gx-4 mb-4">
                        <div className="col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0 mx-auto mt-3">
                            <TabPanel tab={tab} setTab={setTab}/>
                        </div>
                    </div>
                    <div className="row m-1">
                        <Expired documentType={tab === "documents" ? "documents" : "certificates"} />
                    </div>
                </div> */}
                <TabPanel tab={tab} setTab={setTab}/>
                <Expired documentType={tab === "documents" ? "documents" : "certificates"} />
            </div>
        </Template>
    );
};

export default ExpiredDocuments;