export const initialState = {
    is_loading: true,
    total_ftms: 0,
    total_pages: 0,
    page_number: 1,
    list: [],
    search_type: "",
    serach_text: '',
    sort_key: '',
    sort_direction: ''
}