import React, { useContext } from "react";
import {useNavigate} from "react-router-dom";

import Context from "../context";
import DivSpinner from "../../../components/divSpinner";
import { theme } from "../../../theme";
import { fullDateFormat } from "../../../utils";
import ColumnSort from "./columnSort";

const Table = () => {
    const context = useContext(Context);
    let navigate = useNavigate();

    return(
        <>
            {context.state.is_loading ? (
                <div style={{height : `${window.innerHeight-387}px`}}>
                    <DivSpinner/>
                </div>
            ) : (
                <div className="table-responsive p-0 m-3">
                    <table className="table align-items-center mb-0">
                        <thead>
                            <tr>
                                {[
                                    '#', 
                                    'Customer',
                                    'Postcode', 
                                    'Job ID', 
                                    'Payment Type',
                                    `Amount`,
                                    'Last four digits',
                                    <span>Card<br/>type</span>,
                                    'Reference',
                                    // <span>Materials<br/>charge<br/>(FTM)</span>,
                                    'Payment Date',
                                    'Status',
                                ].map((text, index) => (
                                    <th 
                                        key={index}
                                        className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7"
                                    >
                                        <ColumnSort sortKeyName={text} />
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {context.state.payments.length > 0 ? (
                                <>
                                    {context.state.payments.map((payment, index) => (
                                        <tr key={index}>
                                            {[
                                                payment.payment_id,
                                                payment?.job?.customer?.customer_full_name,
                                                payment?.job?.job_postcode,
                                                payment?.payment_job_id,
                                                payment?.payment_type,
                                                `${theme.currency} ${payment?.payment_amount?.toFixed(2)}`,
                                                `**** **** **** ${payment?.card?.card_last_four_digits}`,
                                                `${payment?.card?.card_type}`,
                                                `${payment?.payment_reference}`,
                                                `${fullDateFormat(payment.payment_date_time)}`,
                                                `${
                                                    payment?.payment_status === 1 ? "PRE AUTH" : 
                                                    payment?.payment_status === 2 ? "CHARGE": 
                                                    payment?.payment_status === 3 ? "RELEASE":
                                                    payment?.payment_status === 4 ? "REFUND" : null
                                                }`
                                            ].map((text, index) => (
                                                <td className="align-middle" key={index}>
                                                    <span className="text-secondary text-sm font-weight-bold">
                                                        {text}
                                                    </span>
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </>
                            ) : (
                                <tr>
                                    <td colSpan="8">Sorry, no data found!</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )}
        </>
    );
}

export default Table;