import React, { createContext, useEffect, useReducer } from "react";
import Swal from "sweetalert2";
import toast from "react-hot-toast";

import Reducer from "./reducer";
import { initialState } from "./initialState";
import { 
    deleteDocument, 
    fetchCertificates, 
    fetchCertificatesList, 
    fetchFtmDocumentDownload, 
    fetchPersonalDocuments, 
    fetchPersonalDocumentsList, 
    updateApproveDocument, 
    updateExpiryDocument 
} from "./service";
import { commonSwal } from "../../../components/swalAlert/swalAlert";

const Context = createContext();

const Provider = ({ ftm_id, document_type, children }) => {
    const isPersonal = document_type === "personal";
    const [state, dispatch] = useReducer(Reducer, initialState);

    useEffect(() => {
        const getData = async () => {
            dispatch({ type: "LOADING" });

            try {
                const fetchFunction = isPersonal ? fetchPersonalDocuments : fetchCertificates;
                const response = await fetchFunction(ftm_id);

                dispatch({ type: "LOADED", data: response?.data });
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        getData();
    }, [ftm_id, document_type, isPersonal]);

    useEffect(() => {
        const getDocuments = async () => {
            try {
                const fetchFunction = isPersonal ? fetchPersonalDocumentsList : fetchCertificatesList;

                const documentId = isPersonal ? state.selectedDocument?.document?.ftm_personal_document_id : state.selectedDocument.ftm_document_id;
                console.log("documentId : ", documentId);

                if(documentId !== undefined){
                    dispatch({ type: "LOADING_DOCUMENT" });
                    const response = await fetchFunction(documentId);
        
                    dispatch({ type: "LOADED_DOCUMENT", document: response?.document || {} });              
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        state.selectedDocument !== null && getDocuments();
    }, [state.selectedDocument, state.refreshingCount, isPersonal]);

    const setSelectedDocument = (selectedDocument) => dispatch({ type: "SET_SELECTED_DOCUMENT", document: selectedDocument }); // Set Selected Document

    const approveFtmDocument = ({document_id}) => {
        commonSwal({
            type: 'APPROVE_FTM_DOCUMENT',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                const response = await updateApproveDocument({document_id, document_type});
                return response;
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then(async (result) => {
            if (result.isConfirmed) {
                if(result.value.status === "Success"){
                    dispatch({ type: "REFRESHING"});
                    toast.success(result.value.message);
                } else {
                    toast.error(result.value.message);
                }
            }
        })
    }

    const setupExpiryDocument = async ({document_id, expiry_date, comment}) => {
        if(document_id === undefined){
            toast.error("Don't have document");
        }else{
            const response = await updateExpiryDocument({
                document_id, 
                expiry_date: new Date(expiry_date).getTime(), 
                comment, 
                document_type
            });
    
            response.status === "Success" ? toast.success(response.message) : toast.error(response.message);
            dispatch({ type: "REFRESHING"});
        }
    }

    const removeDocument = async ({file_id}) => {
        const response = await commonSwal({
            type: 'REMOVE_FTM_DOCUMENT',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                const response = await deleteDocument({
                    file_id, 
                    document_type : isPersonal ? 2 : 1
                });
                return response;
            },
            allowOutsideClick: () => !Swal.isLoading()
        });

        if (response.isConfirmed) {
            if(response.value.status === "Success"){
                dispatch({ type: "REFRESHING"});
                toast.success(response.value.message);
            } else {
                toast.error(response.value.message);
            }
        }
    }

    const downloadFile = async ({file_id, file_path}) => {
        await fetchFtmDocumentDownload({
            ftm_id, 
            file_id, 
            file_ext: file_path.split('.').pop().toLowerCase(), 
            document_type
        });
    }

    const refresh = () => {
        dispatch({ type: "REFRESHING" })
    };

    return (
        <Context.Provider 
            value={{ 
                ftm_id, 
                document_type, 
                state, 
                dispatch, 
                setSelectedDocument, 
                approveFtmDocument, 
                setupExpiryDocument, 
                removeDocument, 
                downloadFile,
                refresh
            }}
        >
            {children}
        </Context.Provider>
    );
};

export { Context, Provider };  // Export the Context and Provider