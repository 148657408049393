import React from "react";
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import { convertToMillions } from "../../utils";
import { theme } from "../../theme";

const Stats = ({color, icon, title, sub_title, link, value}) => {
    let navigate = useNavigate();

    return(
        <div className="col-xl-3 col-sm-6 mb-xl-0 mb-4">
            <div className="card">
                <div className="card-header p-3 pt-2">
                    <div className={`icon icon-lg icon-shape bg-gradient-${color} shadow-${color} text-center border-radius-xl mt-n4 position-absolute`}>
                        <i className="material-icons opacity-10">{icon}</i>
                    </div>
                    <div className="text-end pt-1">
                        <p className="text-sm mb-0 text-capitalize">
                            <b>{title} <br/>{sub_title !== "" ? sub_title : null}</b>
                        </p>
                        <h4 className="mb-0">
                            {title !== "ONLINE TRADESPERSONS" && theme.currency} 
                            {title !== "ONLINE TRADESPERSONS" ? convertToMillions(value) : value}
                        </h4>
                    </div>
                </div>
                <hr className={`${color} horizontal my-0`}/>
                <div className="card-footer p-3">
                    <p className="mb-0">
                        <span className="text-success text-sm font-weight-bolder"/>
                        {/* <b>VIEW</b> */}
                        <Button variant="text" style={{color: '#000'}} onClick={() => navigate(link)}>VIEW</Button>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Stats;