import React, { useContext } from 'react';

import { ListItem } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';

import { Context } from '../Provider';
import Gallery from '../../components/Gallery';

const HistoryList = () => {
    const context = useContext(Context);
    const { ftm_id, document_type, state } = context; 
    const { history } = state;

    const isPersonal = document_type === "personal";

    const Items = ({ item }) => {
        const documentExpiryDate = isPersonal ? item.ftm_personal_document_expiry_date : item.ftm_document_expiry_date;
        const documentComment = isPersonal ? item.ftm_personal_document_comment : item.ftm_document_comment;

        const formattedExpiryDate = documentExpiryDate ?
        new Date(documentExpiryDate).toISOString().split('T')[0] : '';

        return (
            <React.Fragment>
                <ListSubheader sx={{ bgcolor: 'background.paper' }}>
                    {formattedExpiryDate}
                </ListSubheader>

                <ListItem>
                    <ListItemText primary={documentComment} />
                </ListItem>

                <Gallery
                    context={{
                        ftm_id: ftm_id,
                        document_type: document_type,
                        state: {
                            document: item
                        },  
                    }}
                    isFtmDocumentView={false}
                />
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <CssBaseline />
            <Paper style={{backgroundColor: 'unset'}}>
                <List sx={{ mb: 2 }}>
                    {history.map((item, index) => (
                        <Items item={item} key={index} />
                    ))}
                </List>
            </Paper>
        </React.Fragment>
    );
}

export default HistoryList;
