import { useContext } from "react";
import { textReplace } from "../../../utils";
import { Context } from "../provider";

export const JobStatuses = () => {
    const {state} = useContext(Context);
    const { details } = state;

    return (
        <div className="card position-sticky top-1">
            <div className="card-header pb-0 p-3">
                <div className="row">
                    <div className="col-6 d-flex align-items-center">
                        <h5 className="mb-1 font-weight-bolder">Statuses</h5>
                    </div>
                </div>
            </div>
            <div className="card-body p-0">
            <ul className="nav flex-column bg-white border-radius-lg p-3">
                {details?.job_statuses?.map((status, index) => (
                    <li className="nav-item" key={index}>
                        <div className="nav-link text-dark d-flex" data-scroll="">
                            <i className="material-icons  me-2" style={{color: status.job_status_color, fontSize: '25px'}}>{status.job_status_icon}</i>
                            &nbsp; 
                            <span className="text-md">
                                {status.job_status_number} .&nbsp; 
                                {textReplace({
                                    text: status.job_status_name, 
                                    search: '[[FTM]]', 
                                    replace: `${details?.ftm?.ftm_forename} ${details?.ftm?.ftm_surname}`
                                })}
                            </span>
                            <hr/>
                        </div>
                    </li>
                ))}
            </ul>
            </div>
        </div>
    )
}