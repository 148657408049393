import React, { useContext, useState, useCallback, useMemo } from "react";
import { Tooltip } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { theme } from "../../../../theme";
import { Context } from "../Provider";
import OffcanvasTemplate from "../../../../components/offcanvasTemplate";
import ActionList from "./ActionList";
import DivSpinner from "../../../../components/divSpinner";
import Gallery from "../../components/Gallery";

const RenderDocuments = () => {
    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = () => setIsOpen(true);
    const handleClose = useCallback(() => {
        setIsOpen(false);
    }, []);

    const context = useContext(Context);
    const { state, document_type, setSelectedDocument, refresh } = context;
    const { data, selectedDocument, loadingDocument } = state;
    const isPersonal = document_type === "personal";

    const handleSelectDocument = (document) => {
        setSelectedDocument(document);
        handleOpen();
    };

    const documentIconStyle = useMemo(() => ({
        top: '3px',
        fontSize: '20px',
    }), []);

    const SideView = useMemo(() => {
        if (!selectedDocument) return null;
        const documentTypeIcon = isPersonal && (
            <i className="material-icons position-relative me-1" style={documentIconStyle}>
                {selectedDocument.document_type_icon}
            </i>
        );

        const documentTypeName = isPersonal
            ? selectedDocument.document_type_name
            : selectedDocument.ftm_document_type;

        return (
            <OffcanvasTemplate
                open={isOpen}
                handleClose={handleClose}
                placement="end"
                title={
                    <>
                        {documentTypeIcon}
                        {documentTypeName?.toUpperCase()}
                    </>
                }
                body={loadingDocument ? <DivSpinner /> : <Gallery context={context} isFtmDocumentView={true} refresh={refresh}/>}
                footer={<ActionList />}
            />
        );
    }, [context, documentIconStyle, handleClose, isOpen, isPersonal, loadingDocument, selectedDocument]);

    return (
        <>
            {data?.documents.map((document, index) => {
                const isApproved = (isPersonal
                    ? document?.document?.ftm_personal_document_is_approve
                    : document?.ftm_document_is_approve) === 1;

                const documentTypeIcon = isPersonal && (
                    <i className="material-icons position-relative me-1" style={documentIconStyle}>
                        {document.document_type_icon}
                    </i>
                );

                const documentTypeName = isPersonal
                    ? document.document_type_name
                    : document.ftm_document_type;

                const documentCode = isPersonal
                    ? `#${document.document_type_code}`
                    : `#${document.documents_type?.sub_skill?.skill?.skill_name} #${document.documents_type?.sub_skill?.sub_skill_name}`;

                return (
                    <React.Fragment key={index}>
                        <li className="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                            <div className="d-flex flex-column">
                                <h6 className="mb-1 text-dark font-weight-bold">
                                    {documentTypeIcon}
                                    {documentTypeName}
                                    {isApproved && (
                                        <Tooltip title="Approved">
                                            <CheckCircleIcon fontSize="small" sx={{ color: theme.action_btn_code }} />
                                        </Tooltip>
                                    )}
                                </h6>
                                <span className="text-sm">{documentCode}</span>
                            </div>
                            <div className="d-flex align-items-center">
                                <button
                                    className="btn btn-link text-dark mb-0 px-0 ms-4"
                                    onClick={() => handleSelectDocument(document)}
                                >
                                    <i className="material-icons position-relative me-1" style={{ top: '3px', fontSize: '25px' }}>
                                        visibility
                                    </i>
                                </button>
                            </div>
                        </li>
                        <hr className="dark horizontal my-0" />
                    </React.Fragment>
                );
            })}

            {SideView}
        </>
    );
};

export default React.memo(RenderDocuments);