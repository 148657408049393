import React, { createContext, useEffect, useReducer } from "react";
import Swal from 'sweetalert2';

import Reducer from "./reducer";
import { initialState } from "./initialState";
import { fetchJobs } from "./service";
import { isShowChatNotifications } from "../../utils";
import { getUserInfo } from "../../storage";
import { jobsRef } from "../../firebase";
import { theme } from '../../theme';
import { commonSwal } from "../../components/swalAlert/swalAlert";

const Context = createContext();

const Provider = ({ children }) => {
    const [state, dispatch] = useReducer(Reducer,initialState);

    useEffect(() => {
        const getJobs = async () => {
            try {
                dispatch({type: 'IS_LOADING_JOBS'});
                const jobs = await fetchJobs({
                    is_get_archive_job: state.is_get_archive_job,
                    jobs_list_search_text: state.jobs_list_search_text
                });
    
                console.log("🚀 ~ getJobs ~ jobs:", jobs)
    
                dispatch({
                    type: 'IS_LOADED_JOBS',
                    jobs: jobs
                }); 
            } catch (error) {
                console.error('Error fetching jobs:', error);
            }
        };
    
        const unsubscribe = jobsRef.onSnapshot(() => {
            getJobs();
        });
    
        return () => unsubscribe();
    }, [state.is_get_archive_job, state.jobs_list_search_text]);
    
    const handleChatLoading = (job) => {
        isShowChatNotifications(job.job_id, "NEW_DIRECT_MESSAGE");
    
        // Get job info
        let user_info = getUserInfo();
        let ref = jobsRef.doc(job.doc_id);
    
        ref.update({
            job_last_message_seen_user: user_info.admin_firebase_document_id,
            job_last_message_seen_user_timestamp: new Date().getTime()
        });
    
        dispatch({ type: "SELECTED_JOB", selected_job: job })
    }
    
    const handleDocumentArchive = (job) => {
        if (!job.job_archive) {
            commonSwal({
                type: 'HANDLE_DOCUMENT_ARCHIVE',
                showCancelButton: true,
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                    let ref = jobsRef.doc(job.doc_id);
    
                    ref.update({
                        job_archive: !job.job_archive
                    });
    
                    return ref;
                },
                allowOutsideClick: () => !Swal.isLoading()
            }).then(async (result) => {
                if (result.isConfirmed) {
                    console.log('result :', result);
                    job.job_archive = true;
                    dispatch({ type: "SELECTED_JOB", selected_job: job })
                }
            })
        } else {
            let ref = jobsRef.doc(job.doc_id);
    
            ref.update({
                job_archive: !job.job_archive
            });

            job.job_archive = false;
            dispatch({ type: "SELECTED_JOB", selected_job: job })
        }
    }
    
    const filteredJobs = state.jobs.filter(job => {
        const jobIdMatch = job.job_id && job.job_id.toString().toLowerCase().includes(state.jobs_list_search_text.toLowerCase());
        const subSkillNameMatch = job.job_details?.service_category?.sub_skill?.name?.toLowerCase().includes(state.jobs_list_search_text.toLowerCase());
        return jobIdMatch || subSkillNameMatch;
    });
    
    return (
        <Context.Provider value={{state, filteredJobs, dispatch, handleChatLoading, handleDocumentArchive}}>
            {children}
        </Context.Provider>
    );
    
}

export { Context, Provider };