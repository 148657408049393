export default function Reducer(prevState, action){
    switch (action.type) {
        case "IS_LOADING":
            return {
                ...prevState,
                is_loading: true,
            };
        case "IS_LOADED": 
            return {
                ...prevState,
                skills: action.skills,
                is_loading: false,
            };
        case "UPDATE_STEP":
            return {
                ...prevState,
                step: action.step,
                stepBackButtonDisable: action.step === 1 ? true : false
            };
        case "BACK_STEP":
            return {
                ...prevState,
                step: prevState.step-1,
                stepBackButtonDisable: prevState.step-1 === 1 ? true : false
            };
        case "UPDATE_SELECTED_SKILLS":
            return {
                ...prevState,
                stepBackButtonDisable: false,
                selectedSkills: action.selectedSkills,
            };
        case "REFRESH_COUNT":
            return {
                ...prevState,
                refreshCount: prevState.refreshCount+1,
            };
        case "SET_FTM_UPDATING":
            return {
                ...prevState,
                ftm_updating: action.ftm_updating,
            };
        default:
            throw new Error();
    }
}