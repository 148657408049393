import React, { createContext, useEffect, useReducer } from "react";
import Reducer from "./reducer";
import { initialState } from "./initialState";
import { fetchDeletedCertificatesList, fetchPersonalDeletedDocuments } from "./service";

const Context = createContext();

const Provider = ({ ftm_id, document_id, document_type, children }) => {
    const isPersonal = document_type === "personal";
    const [state, dispatch] = useReducer(Reducer, initialState); 

    const fetchData = async () => {
        try {
            if(document_id !== undefined){
                dispatch({ type: "LOADING" });
                const fetchFunction = isPersonal ? fetchPersonalDeletedDocuments : fetchDeletedCertificatesList;
                const response = await fetchFunction(document_id);

                dispatch({ type: "LOADED", document: response?.document });
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        fetchData();  // Initial data fetch
    }, [document_id, isPersonal]);

    return (
        <Context.Provider 
            value={{ 
                ftm_id,
                document_id, 
                document_type, 
                state, 
                dispatch,
                fetchData // Pass fetchData to context
            }}
        >
            {children}
        </Context.Provider>
    );
}

export { Context, Provider };