import React, { useContext } from "react";
import Tooltip from '@mui/material/Tooltip';

import { Context } from "../provider";
import useCustomNavigate from "../../../components/hooks/useCustomNavigate";

const Header = () => {
    const context = useContext(Context);
    const { navigateToCustom } = useCustomNavigate();

    return (
        <div className="row">
            <div className="col-md-9 col-lg-10">
                <div className="d-flex align-items-center">
                    <div className="ms-3">
                        <h6 className="mb-0 d-block text-white">
                            #{context.state.selected_job?.job_id} {context.state.selected_job?.job_details?.service_category?.sub_skill.name || "Not selected job"}
                        </h6>
                        <span className="text-sm text-white opacity-8">
                            {context.state.selected_job?.job_details?.service_category?.skill.name || "Empty" }
                        </span>
                    </div>
                </div>
            </div>

            <div className="col-1 my-auto">
                {context.state.selected_job !== null && (
                    <div className="row">
                        <div className="col-md-6 col-lg-4" style={{marginRight: '10px'}}>
                            <Tooltip title={context.state.selected_job?.job_archive ? "Unarchive Chat" : "Archive Chat"} arrow>
                                <button
                                    className="btn btn-icon-only text-white mb-0 me-3 me-sm-0"
                                    type="button"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title=""
                                    data-bs-original-title="Video call"
                                    onClick={
                                        () => context.handleDocumentArchive(context.state.selected_job)
                                    }
                                >
                                    <i className="material-icons" style={{fontSize: '25px'}}>{context.state.selected_job?.job_archive ? "unarchive" : "archive"}</i>
                                </button>
                            </Tooltip>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <Tooltip title="View Job" arrow>
                                <button
                                    className="btn btn-icon-only text-white mb-0 me-3 me-sm-0"
                                    type="button"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title=""
                                    data-bs-original-title="Video call"
                                    onClick={
                                        () => navigateToCustom(`${`/jobs/view/${context.state.selected_job?.job_id}`}`)
                                    }
                                >
                                    <i className="material-icons" style={{fontSize: '25px'}}>visibility</i>
                                </button>
                            </Tooltip>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Header;