import { GET } from "../../API/get";

export const loadData = async (page_number, search_value, sort_key, sort_direction, dispatch) => {
    dispatch({ type: "IS_LOADING" });
    
    let response = await GET(`get-ftms-rejected-jobs/${page_number}?search=${search_value}&sort_key=${sort_key}&sort_direction=${sort_direction}`);
    if(response.status === "Success"){
        dispatch({ 
            type: "IS_LOADED", 
            total_ftms: response.total_ftms, 
            total_pages: response.total_pages, 
            ftms_list: response.list, 
            current_page: page_number 
        });
    }
}

export const getRejectedJobs = async (page_number, ftm_id, search_value, dispatch) => {
    dispatch({ type: "IS_JOB_LIST_LOADING" });
    let response = await GET(`get-ftms-rejected-jobs-list/${page_number}/${ftm_id}?search=${search_value}`);
    if(response.status === "Success"){
        dispatch({ 
            type: "SET_JOB_LIST", 
            job_list: response.list,
            job_list_total: response.total_jobs,
            job_list_total_pages: response.total_pages, 
            job_list_current_page: page_number 
        });
    }
}