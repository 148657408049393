export const initialState = {
    is_loading: true,
    is_job_list_loading: true,
    total_ftms: 0,
    total_pages: 0,
    current_page: 1,
    ftms_list: [],
    search_value: "",
    is_show_jobs: false,
    selected_ftm: {},
    job_list: [],
    job_list_total: 0,
    job_list_total_pages: 0,
    job_list_current_page: 1,
    job_list_search_value: "",
    sort_key: '',
    sort_direction: ''
}