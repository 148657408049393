
import React, { useState, useRef, useEffect, useContext } from "react";
import Button from 'react-bootstrap/Button';

import ModalTemplate from "../../../../components/modal/modalTemplate";
import { theme } from "../../../../theme";
import "./css/FileUpload.css";// import css file
import { uploadMoveDocuments } from "../service";
import { Context } from "../components/Provider";

const FileUpload = ({files, setFiles}) => {
    const fileInputRef = useRef(null);

    const handleDrop = (event) => {
        event.preventDefault();
        addFiles(event.dataTransfer.files);
    };

    const handleFileInput = (event) => {
        addFiles(event.target.files);
    };

    const addFiles = (fileList) => {
        const newFiles = Array.from(fileList).map((file) => ({
            file,
            preview: isImageFile(file) ? URL.createObjectURL(file) : null,
        }));
        setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    };

    const isImageFile = (file) => file.type.startsWith("image/");

    const removeFile = (index, event) => {
        event.stopPropagation();
        setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    };

    const preventDefaults = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleClick = () => fileInputRef.current.click();

    useEffect(() => {
        return () => {
            files.forEach((fileObj) => {
                if (fileObj.preview) {
                    URL.revokeObjectURL(fileObj.preview);
                }
            });
        };
    }, [files]);

    return (
        <div>
            <div
                className="upload-area"
                onDragEnter={preventDefaults}
                onDragOver={preventDefaults}
                onDrop={handleDrop}
                onClick={handleClick}
            >
                {!files.length && <p style={{paddingTop: '25px'}}>Drag & drop files here, or click to select files</p>}
                <input
                    type="file"
                    multiple
                    onChange={handleFileInput}
                    style={{ display: "none" }}
                    ref={fileInputRef}
                />

                <div className="file-previews">
                    {files.map((fileObj, index) => (
                        <div key={index} className="file-item">
                            {fileObj.preview ? (
                                <img
                                    src={fileObj.preview}
                                    alt={`Preview ${index}`}
                                    className="file-preview"
                                />
                            ) : (
                                <div
                                    className="file-icon"
                                    title={fileObj.file.name}
                                >
                                    📄
                                </div>
                            )}
                            <button
                                onClick={(event) => removeFile(index, event)}
                                className="remove-button"
                            >
                                X
                            </button>
                        </div>
                    ))}

                    {files.length > 0 && (
                        <div className="add-more-box" onClick={handleClick}>+</div>
                    )}
                </div>
            </div>
        </div>
    );
}

const NewFileUpload = ({headerName, open, handleClose, selectedDocumentId, isCertificates}) => {
    const { handelUpdateRefreshingCount } = useContext(Context);
    const [files, setFiles] = useState([]);
    const [updating, setUpdating] = useState(false);

    const handleUpload = async () => {
        setUpdating(true);
        const documentType = isCertificates ? 2 : 1;
        for(const file of files){
            await uploadMoveDocuments(selectedDocumentId, documentType, file.file);
            handelUpdateRefreshingCount();
        }

        setUpdating(false);
    }

    const Btn = () => {
        return (
            <>  
                {[
                    {name: 'Cancel', onClick: handleClose, variant: theme.close_btn},
                    {name: 'Upload', onClick: handleUpload, variant: theme.main},
                ].map((item, index) => (
                    <Button variant={item.variant} size="sm" disabled={updating} onClick={item.onClick} key={index}>
                        {updating ? 'Uploading...' : item.name}
                    </Button>
                ))}
            </>
        )
    }

    return (
        <ModalTemplate
            show={open}
            closeFun={handleClose}
            size={null}
            centered={true}
            title={headerName}
            body={<FileUpload files={files} setFiles={setFiles}/>}
            footer={<Btn/>}
        />
    );
}

export default NewFileUpload;
