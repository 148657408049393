import React from "react";
import Html from "./Html";
import { Provider } from "./Provider";

const AddFtmSkills = ({
    ftm_id,
    open,
    handleClose,
    ftmSkills = null,
    updateRefreshCount = () => {}
}) => {
    return (
        <Provider
            ftm_id = {ftm_id}
            children = {
                <Html
                    open = {open}
                    handleClose = {handleClose}
                    ftmSkills = {ftmSkills}
                />
            }
            open = {open}
            handleClose={handleClose}
            updateRefreshCount = {updateRefreshCount}
        />
    );
}

export default AddFtmSkills;