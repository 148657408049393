import React, { useContext } from "react";
import { Pagination, Stack } from "@mui/material";

import { Context } from "../components/Provider";
import { theme } from "../../../../theme";

const PaginationView = () => {
    const { state, handelUpdatePage } = useContext(Context);
    const {loading, total_pages, page} = state;

    return(
        <>
            {!loading && (
                <Stack 
                    spacing={2} 
                    className="mb-3" 
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center'
                    }}      
                >
                    <Pagination 
                        count={total_pages} 
                        page={page} 
                        onChange={(event, newPage) => handelUpdatePage(newPage)}
                        color={theme.main}
                    />
                </Stack>
            )}
        </>
    );
}

export default PaginationView;