import React from "react";
import { Link } from "react-router-dom";

import Template from "../../components/template";
import { theme } from "../../theme";

const Html = () => {
    return(
        <Template>
            <div className="container-fluid py-4">
                <div className="row">
                    <div className="col-12">
                        <div className="d-flex align-items-center justify-content-center">
                            <div className="text-center">
                                <h1 className="display-1 fw-bold">403</h1>
                                <p className="fs-3"> <span className="text-danger">Oops!</span> Page not found.</p>
                                <p className="lead">
                                    The page you're looking for doesn't exist.
                                </p>
                                <Link to="/" className={`btn btn-${theme.main}`}>Go Home</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Template>
    );
}

export default Html;