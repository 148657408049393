import React, { useContext } from "react";
import VisibilityIcon from '@mui/icons-material/Visibility';
import moment from "moment";
import TimerIcon from '@mui/icons-material/Timer';
import TimerOffIcon from '@mui/icons-material/TimerOff';

import { Context } from "../Provider";
import DivSpinner from "../../../components/divSpinner";
import ColumnSort from "./columnSort";

const TableHeader = () => (
    <thead>
        <tr>
            {[
                "Tradesperson",
                "Contact Number",
                "Email",
                "Address",
                "Vehicle Number",
                "Created At",
                "Next of Kin",
                "Status",
            ].map((header, index) => (
                <th
                    key={index}
                    className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7"
                >
                    <ColumnSort sortKeyName={header} />
                </th>
            ))}
            <th className="text-secondary opacity-7"></th>
        </tr>
    </thead>
);

const TableRow = ({ ftm, dispatch }) => {
    const {
        ftm_profile_image_path,
        ftm_forename,
        ftm_surname,
        ftm_id,
        ftm_contact_number,
        ftm_email,
        ftm_street_address,
        ftm_place,
        ftm_postcode,
        ftm_vehicle_number,
        ftm_createdAt,
        ftm_next_of_kin,
        ftm_next_of_kin_contact_number,
        ftm_last_review_hours
    } = ftm;

    return (
        <tr>
            <td>
                <div className="d-flex px-2 py-1">
                    <img
                        src={ftm_profile_image_path || "/assets/img/lock_user.png"}
                        className="avatar avatar-sm me-3 border-radius-lg"
                        alt="profile"
                    />
                    <div className="d-flex flex-column justify-content-center">
                        <h6 className="mb-0 text-sm">
                            {ftm_forename} {ftm_surname}
                        </h6>
                        <p className="text-xs text-secondary mb-0">#FTM ID {ftm_id}</p>
                    </div>
                </div>
            </td>
            <td className="text-secondary text-sm font-weight-bold">{ftm_contact_number}</td>
            <td className="text-secondary text-sm font-weight-bold">{ftm_email}</td>
            <td className="text-secondary text-sm font-weight-bold">
                {[ftm_street_address, ftm_place, ftm_postcode].join() || "N/A"}
            </td>
            <td className="text-secondary text-sm font-weight-bold text-center">{ftm_vehicle_number || "N/A"}</td>
            <td className="text-secondary text-sm font-weight-bold">
                {moment(ftm_createdAt).format("YYYY-MM-DD HH:mm:ss")}
            </td>
            <td>
                {ftm_next_of_kin ? (
                    <div className="d-flex flex-column justify-content-center">
                        <h6 className="mb-0 text-sm">{ftm_next_of_kin}</h6>
                        <p className="text-xs text-secondary mb-0">{ftm_next_of_kin_contact_number}</p>
                    </div>
                ) : "N/A"}
            </td>
            <td className="text-center">
                {ftm_last_review_hours > 24 ? <TimerOffIcon style={{ color: "red" }}/> : <TimerIcon style={{ color: "green" }}/>}
            </td>
            <td className="align-middle">
                <VisibilityIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => dispatch({ type: 'SELECTED_FTM', selected_ftm: ftm})}
                />
            </td>
        </tr>
    );
};

const NoDataAvailable = () => (
    <tr>
        <td colSpan="8" className="text-center">
            <span className="text-secondary text-sm font-weight-bold">
                No data available
            </span>
        </td>
    </tr>
);

const Table = () => {
    const context = useContext(Context);
    const { state, dispatch } = context;
    const { is_loading, ftms } = state;

    if (is_loading) {
        return (
            <div style={{ height: `${window.innerHeight - 387}px` }}>
                <DivSpinner />
            </div>
        );
    }

    return (
        <div className="table-responsive p-0">
            <table className="table align-items-center mb-0">
                <TableHeader />
                <tbody>
                    {ftms.length > 0 ? (
                        ftms.map((ftm, index) => (
                            <TableRow
                                key={index}
                                ftm={ftm}
                                dispatch = {dispatch}
                            />
                        ))
                    ) : <NoDataAvailable/>}
                </tbody>
            </table>
        </div>
    );
};

export default Table;