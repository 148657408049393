import React, { useEffect, useContext, useRef, useCallback, useState } from "react";
import Context from "../context";
import { createMarkerContent, loadScript, map_settings } from "../../../map";

const CustomerMap = () => {
    const [mapLoaded, setMapLoaded] = useState(false);
    const mapRef = useRef(null);
    const mapInstance = useRef(null); // Store the map instance
    const boundsRef = useRef(null); // Store the bounds instance

    const context = useContext(Context);
    const { state } = context;
    const { customer_jobs } = state;

    // Load the Google Maps script
    useEffect(() => {
        loadScript(
            map_settings.map_script_url,
            () => setMapLoaded(true),
            () => console.error("Failed to load Google Maps script.")
        );
    }, []);

    // Initialize the map
    const initializeMap = useCallback(() => {
        if (mapInstance.current || !window.google || !window.google.maps) {
            return;
        }

        mapInstance.current = new window.google.maps.Map(mapRef.current, {
            center: { lat: 51.5286416, lng: -0.1015987 },
            zoom: 8,
            mapId: "FTM_LIVE_MAP"
        });

        boundsRef.current = new window.google.maps.LatLngBounds();
    }, []);

    // Add markers to the map
    const addMarkers = useCallback(() => {
        if (!mapInstance.current || !customer_jobs) return;

        customer_jobs.forEach(job => {
            const jobLatLng = new window.google.maps.LatLng(parseFloat(job.job_latitude), parseFloat(job.job_longitude));
            boundsRef.current.extend(jobLatLng);

            new window.google.maps.marker.AdvancedMarkerElement({
                position: jobLatLng,
                content: createMarkerContent(map_settings.ftm_map_icon),
                map: mapInstance.current,
                title: `${job.job_address}`,
            });
        });

        mapInstance.current.fitBounds(boundsRef.current);
        mapInstance.current.setZoom(8); // Set zoom once after bounds are set
    }, [customer_jobs]);

    // Effect for initializing the map and adding markers
    useEffect(() => {
        if (mapLoaded) {
            initializeMap();
            addMarkers();
        }
    }, [mapLoaded, initializeMap, addMarkers]);

    return (
        <div className="page-header min-height-300 border-radius-xl mt-4" style={{ cursor: 'pointer' }}>
            <div ref={mapRef} style={{ width: '100%', height: 420 }}/>
        </div>
    );
};

export default CustomerMap;