import React, { useContext, useState, useMemo } from "react";
import { Collapse, IconButton, TableCell, TableRow, Typography, Tooltip } from "@mui/material";
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import ChatIcon from '@mui/icons-material/Chat';
import CallIcon from '@mui/icons-material/Call';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AlarmIcon from '@mui/icons-material/Alarm';
import SnoozeIcon from '@mui/icons-material/Snooze';

import { Context } from "../Provider";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { convertTimestampToFormat } from "../../../../../utils";
import NewTable from "../../common/NewTable";
import { theme } from "../../../../../theme";
import SetExpiryDate from "../../../view/components/SetExpiryDate";
import Chat from "../../../../../components/userChat/chat";
import ImageGallery from "../../common/Gallery";

const Render = ({isCertificates}) => {
    const [chatState, setChatState] = useState({
        open: false,
        ftm: null
    });

    const [ftmState, setFtmState] = useState({
        open: false,
        ftm: null,
        documentId: null
    });

    const { state } = useContext(Context);
    const { documents } = state;

    const handleOpenChat = (ftm) => setChatState({
        open: true,
        ftm: ftm
    });

    const handleCloseChat = () => setChatState({
        open: false,
        ftm: null
    });

    const handleOpenDocument = (id, ftm) => setFtmState({
        open: true,
        ftm: ftm,
        document: id
    });

    const handleCloseDocument = () => setFtmState({
        open: false,
        ftm: null,
        document: null
    });

    const Row = React.memo(({ row }) => {
        const [open, setOpen] = useState(false);
        const { ftm_document_id, ftm_document_comment, ftm_document_type, ftm_document_expiry_date, ftm, documents_type, ftm_document_days_until_expiry, new_ftm_document } = row;

        const daysUntilExpiry = (ftm_document_days_until_expiry) => (
            <Tooltip title={ftm_document_days_until_expiry} arrow placement="right">
                {ftm_document_days_until_expiry > 0 ? <AlarmIcon sx={{ color: 'green' }}/> : <SnoozeIcon sx={{ color: 'red' }}/>}
            </Tooltip>
        );
    
        const list = useMemo(() => [
            { subtitle: ftm_document_type },
            { subtitle: `${ftm?.ftm_forename} ${ftm?.ftm_surname}` },
            { subtitle: convertTimestampToFormat(ftm_document_expiry_date) },
            { subtitle: daysUntilExpiry(ftm_document_days_until_expiry)  },
            {
                subtitle: documents_type?.sub_skill?.sub_skill_name,
                caption: documents_type?.sub_skill?.skill?.skill_name,
            },
        ], [ftm_document_type, ftm_document_expiry_date, ftm, documents_type, ftm_document_days_until_expiry]);
    
        return (
            <>
                <TableRow className="remedial-table-row">
                    <TableCell>
                        {new_ftm_document && (
                            <IconButton
                                onClick={() => setOpen((prev) => !prev)}
                            >
                                <NotificationsActiveIcon color={theme.main} />
                            </IconButton>
                        )}
                    </TableCell>
                    {list.map((item, index) => (
                        <TableCell component="th" scope="row" key={index}>
                            <Typography variant="subtitle2">{item.subtitle}</Typography>
                            {item.caption && (
                                <Typography
                                    variant="caption"
                                    display="block"
                                    className="remedial-table-content"
                                    gutterBottom
                                >
                                    {item.caption}
                                </Typography>
                            )}
                        </TableCell>
                    ))}
                    <TableCell component="th" scope="row">
                        <SetExpiryDate
                            listItem={{
                                name: "EXPIRED",
                                icon: <ScheduleSendIcon />
                            }}
                            selectedDocument={{
                                document_id: ftm_document_id,
                                expiry_date: ftm_document_expiry_date,
                                comment: ftm_document_comment
                            }}
                            document_type={"personal"}
                        />
                        <IconButton
                            variant={theme.set_expired_color}
                            onClick={() => handleOpenChat(ftm)}
                        >
                            <ChatIcon />
                        </IconButton>
    
                        <IconButton
                            variant={theme.set_expired_color}
                            // onClick={() => window.location.href = `tel:${ftm?.ftm_contact_number}`}
                        >
                            <CallIcon />
                        </IconButton>  

                        <IconButton
                            onClick={() => handleOpenDocument(ftm_document_id, ftm)}
                            variant={theme.set_expired_color}
                        >
                            <VisibilityIcon />
                        </IconButton>  
                    </TableCell>
                </TableRow>
                {new_ftm_document && (
                    <TableRow className="remedial-table-row remedial-table-row--expanded" style={{ backgroundColor: "#ece7e7" }}>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                            <Collapse in={open} timeout="auto" unmountOnExit>
                                <NewTable ftm={ftm} newDocuments={new_ftm_document} />
                            </Collapse>
                        </TableCell>
                    </TableRow>
                )}
            </>
        );
    });

    return (
        <>
            {documents.map((document, index) => (
                <Row row={document} key={index} />
            ))}

            {chatState?.ftm && (
                <Chat
                    user_name={`${chatState.ftm.ftm_forename} ${chatState.ftm.ftm_surname}`}
                    user_firebase_document_id={chatState.ftm.ftm_firebase_document_id}
                    show={chatState.open}
                    handleClose={handleCloseChat}
                />
            )}

            <ImageGallery
                ftm_id={ftmState?.ftm?.ftm_id}
                documentId={ftmState?.document}
                show={ftmState.open}
                handleClose={handleCloseDocument}
                isCertificates={isCertificates}
            />
        </>
    );
};

export default React.memo(Render);