import React from "react";

import Html from "./Html";
import { Provider } from "./Provider";

const History = ({ ftm_id, document_type_id, document_type }) => {
    return(
        <Provider
            ftm_id = {ftm_id}
            document_type_id = {document_type_id}
            document_type = {document_type}
            children = {<Html/>}
        />
    )
} 

export default History;