import React, { useContext, useMemo, useState } from "react";
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";

import Profile from "./Profile";
import BasicInformation from "./BasicInformation";
import NicLicenceVehicleDetails from "./LicenceVehicleDetails";
import OtherContactInformation from "./OtherContactInformation";
import OffcanvasTemplate from "../../../../components/offcanvasTemplate";
import { theme } from "../../../../theme";
import BtnSpinner from "../../../../components/btnSpinner";
import { Context } from "../../Provider";
import { ftmReview } from "../../service";

const PreviousDetails = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const context = useContext(Context);

    const { state, dispatch } = context;
    const { is_open_previous_details, selected_ftm } = state;

    const handleClose = () => dispatch({ type: 'IS_OPEN_PREVIOUS_DETAILS', is_open_previous_details: false });

    const handleReview = async (ftm_id) => {
        setLoading(true);
        const response = await ftmReview(ftm_id);
        if(response.status === "Success"){
            setLoading(false);
            navigate(`/ftm/view/${ftm_id}`);
        }
    };

    const bodyContent = useMemo(() => (
        <>
            <Profile /><br/>
            <BasicInformation /><br/>
            <NicLicenceVehicleDetails /><br/>
            <OtherContactInformation />
        </>
    ), []);

    return (
        <OffcanvasTemplate
            open={is_open_previous_details}
            handleClose={handleClose}
            placement="end"
            title="PERSONAL DETAILS"
            body={bodyContent}
            footer={
                <div className="d-grid gap-2" style={{ padding: '10px' }}>
                    <Button
                        variant="contained"
                        color={theme.action_btn}
                        onClick={() => handleReview(selected_ftm?.ftm_id)}
                        disabled={loading}
                    >
                        {loading ? <BtnSpinner/> : 'REVIEW'}
                    </Button>
                </div>
            }
        />
    );
};

export default PreviousDetails;