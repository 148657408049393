import toast from 'react-hot-toast';
import Swal from 'sweetalert2';

import { GET } from "../../../API/get";
import { POST } from "../../../API/post";
import { DELETE } from '../../../API/delete';
import { PUT } from '../../../API/put';
import { getUserInfo } from "../../../storage";
import { commonSwal } from '../../../components/swalAlert/swalAlert';

export const getNotes = async (ftm_id, dispatch) => {
    dispatch({ type: "IS_LOADING" });
    let response = await GET(`/ftm/note/${ftm_id}`);

    if(response.status === "Success"){
        dispatch({
            type: 'SET_NOTE_LIST', 
            notes_list: response.list
        });
    }
}

export const saveNote = async (data, ftm_id, dispatch, reset) => {
    dispatch({ type: "IS_LOADING" });

    let user_info = getUserInfo();
    let response = await POST(`/ftm/note`, {
        ftm_id: ftm_id,
        admin_user_id: user_info.admin_id,
        ftm_note: data.note
    });

    if(response.status === "Success"){
        reset();
        getNotes(ftm_id, dispatch);
    }else{
        dispatch({ type: "IS_LOADED" });
    }
}

export const editNote = async (event, ftm_note_id, ftm_id, dispatch, reset) => {
    event.preventDefault();
    
    dispatch({ type: "BTN_LOADING", btn_loading: true });
    let response = await PUT("update/ftm/note", {
        ftm_note_id: ftm_note_id,
        ftm_note: event.target.elements.ftm_note.value
    });

    if(response.status === "Success"){
        reset();
        dispatch({ type: "RESET_EDIT_NOTE" });
        toast.success(response.message);
        getNotes(ftm_id, dispatch);
    }
}

export const deleteNote = async (ftm_note_id, ftm_id, dispatch) => {
    commonSwal({
        type: 'DELETE_NOTE',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: async () => {
            let response = await DELETE(`delete/ftm/note/${ftm_note_id}`);
            return response;
        },
        allowOutsideClick: () => !Swal.isLoading()
    }).then(async (result) => {
        if (result.isConfirmed) {
            console.log('result :', result);

            if(result.value.status === "Success"){
                toast.success(result.value.message)
                getNotes(ftm_id, dispatch);
            }
        }
    })
}