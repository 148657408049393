import React, { useContext } from "react";

import AttachFileIcon from '@mui/icons-material/AttachFile';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import ArticleIcon from '@mui/icons-material/Article';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PrintIcon from '@mui/icons-material/Print';

import Template from "../../components/template";
import Profile from "./components/Profile";
import Loader from "../../components/loading";
import { Context } from "./Provider";
import AddFtmSkills from "../components/addFtmSkills";
import EditFtm from "../components/editFtm";
import SpeedDialBtn from "../../components/speedDialBtn";
import Logs from "../logs/each";
import EachFtmNotes from "../note/each";
import Chat from "../../components/userChat/chat";
import FtmAttachments from "../components/attachments";
import HolidaysCalendar from "./components/HolidaysCalendar";
import { commonSwal } from "../../components/swalAlert/swalAlert";
import { fetchFtmLabel } from "./service";

const Html = () => {
    const context = useContext(Context);
    const { ftm_id, state, dispatch, closeSkillModal, updateRefreshCount } = context;
    const { 
        ftm, 
        is_loading, 
        is_show_skill_modal, 
        is_show_edit_ftm, 
        ftm_edit_count, 
        is_open_logs, 
        is_open_ftm_note, 
        is_show_chat, 
        is_show_ftm_attachments 
    } = state;

    const speedDialActions = [
        {
            icon: <AttachFileIcon />,
            name: 'ATTACHMENTS',
            onClickFn: () => dispatch({
                type: 'IS_SHOW_FTM_ATTACHMENTS',
                is_show_ftm_attachments: true
            })
        },
        {
            icon: <HolidayVillageIcon />,
            name: 'HOLIDAYS',
            onClickFn: () => dispatch({
                type: 'IS_OPEN_TRADESPERSON_HOLIDAYS',
                is_open_tradesperson_holidays: true
            })
        },
        {
            icon: <ArticleIcon />,
            name: 'LOGS',
            onClickFn: () => dispatch({
                type: 'IS_OPEN_LOGS',
                is_open_logs: true
            })
        },
        {
            icon: <DocumentScannerIcon />,
            name: 'NOTES',
            onClickFn: () => dispatch({
                type: 'IS_OPEN_FTM_NOTE',
                is_open_ftm_note: true
            })
        },
        {
            icon: <PrintIcon />,
            name: 'PRINT LABEL',
            onClickFn: () => printLabel()
        }
    ];

    const printLabel = () => {
        commonSwal({
            type: 'PRINT_LABEL',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => fetchFtmLabel(ftm_id)
        });
    }

    return (
        <>
            {is_loading ? <Loader /> : (
                <Template
                    children={
                        <>
                            <Profile />

                            <HolidaysCalendar 
                                ftm_id = {ftm_id} 
                            />

                            <AddFtmSkills
                                ftm_id={ftm_id}
                                updateRefreshCount={updateRefreshCount}
                                open={is_show_skill_modal}
                                handleClose={closeSkillModal}
                                ftmSkills={ftm.ftm_skills}
                            />

                            <EditFtm
                                ftm_id={ftm_id}
                                open={is_show_edit_ftm}
                                handleClose={() => dispatch({
                                    type: 'IS_SHOW_EDIT_FTM',
                                    is_show_edit_ftm: false
                                })}
                                handleSubmitClose={() => dispatch({
                                    type: 'SET_FTM_EDIT_COUNT',
                                    ftm_edit_count: ftm_edit_count + 1
                                })}
                            />

                            <Logs
                                ftm_id={ftm_id}
                                open={is_open_logs}
                                handleClose={() => dispatch({
                                    type: 'IS_OPEN_LOGS',
                                    is_open_logs: false
                                })}
                            />

                            <EachFtmNotes
                                ftm_id={ftm_id}
                                open={is_open_ftm_note}
                                handleClose={() => dispatch({
                                    type: 'IS_OPEN_FTM_NOTE',
                                    is_open_ftm_note: false
                                })}
                            />

                            <Chat
                                user_name={`${ftm.ftm_forename} ${ftm.ftm_surname}`}
                                user_firebase_document_id={ftm.ftm_firebase_document_id}
                                show={is_show_chat}
                                handleClose={() => dispatch({
                                    type: 'IS_SHOW_CHAT',
                                    is_show_chat: false
                                })}
                            />

                            <FtmAttachments
                                ftm_id={ftm_id}
                                open={is_show_ftm_attachments}
                                handleClose={() => dispatch({
                                    type: 'IS_SHOW_FTM_ATTACHMENTS',
                                    is_show_ftm_attachments: false
                                })}
                            />
                        </>
                    }
                    footer={
                        <SpeedDialBtn
                            actions={speedDialActions}
                            icon={<VisibilityIcon />}
                        />
                    }
                />
            )}
        </>
    );
}

export default Html;